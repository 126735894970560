import React,{useEffect,useState} from 'react'
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;



const IssueFeedback = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [chapters, setChapters] = useState([]);
	const [chapterid, setChapterid] = useState('');
	const [issueFeedbackData,setIssueFeedbackData] = useState([]);
	const [period, setPeriod] = useState(0);
	const [usertype, setUserType] = useState();
	const dateFormat = 'MM/DD/YYYY';

	const newCrumb = [<Link key={1} to="/issue-feedback" className="!text-black font-semibold">Issue Feedback </Link>]

	useEffect(()=>{
		getAllTheChapters();
		if (localStorage.getItem('userType')) {
			const user = localStorage.getItem('userType');
			setUserType(user);
		}
	},[])
	useEffect(() => {
		if (startdate && enddate && !chapterid) {
			getAllTheComments();
		} else if (startdate && enddate && chapterid){
			getChapterwiseComments();
		}
	}, [startdate, enddate,chapterid])

	const getAllTheComments = () => {
		fetchAdminApi(`/report-issue-list?all=${1}&start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, 'get').then((res) => {
			if (res?.data) {
				setIssueFeedbackData(res?.data.report_data);
			} else {
				setIssueFeedbackData([]);
			}
		})
	}

	const getChapterwiseComments = ()=>{
		fetchAdminApi(`/report-issue-list?chapter_id=${chapterid}&start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, 'get').then((res) => {
			if (res?.data) {
				setIssueFeedbackData(res?.data.report_data);
			} else {
				setIssueFeedbackData([]);
			}
		})
	}
	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				const chaptertypeOps = res?.data.map((each, index) => {
					return {
						value: index + 1,
						label: each.chapter
					}
				})
				let stringifiedChapters = JSON.stringify(chaptertypeOps);
				localStorage.setItem('chapterDetails', stringifiedChapters);
				const chapters = JSON.parse(localStorage.getItem('chapterDetails'));
				if (chapters && chapters != '') {
					setChapters(chapters);
				}
			} else {
				setChapters([]);
			}
		})
	}
	const getChapterIds = (value) => {
		setChapterid(value);
	}

	const getStartandEnddate = (value, dateString) => {
		setPeriod(null);
		setStartdate(dateString[0]);
		setEnddate(dateString[1]);
	}

	const submitData = (values) => {
		console.log(values);
	}

	const getIssueInfoAndRedirect = (record,index)=>{
		let studentIssuefeedbackDetails = {};
		studentIssuefeedbackDetails.studentId = record.stud_id;
		sessionStorage.setItem("studentFromIssueFeedbackpage", JSON.stringify(studentIssuefeedbackDetails));
		window.open('/view-student', '_blank');
	}

	const issueFeedbackTabData = [
		{
			title: 'Date',
			key: 'Date',
			dataIndex: 'dt_created',
			align: "center",
			width: 20,
		},
		{
			title: 'Name',
			key: 'Name',
			render: (text,record,index) => {
				return (
					usertype == 1 ?
						<>
							<span>{record.stud_firstname.charAt(0).toUpperCase() + '.'}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getIssueInfoAndRedirect(record, index)}><span>{record.stud_firstname.charAt(0).toUpperCase()+record.stud_firstname.slice(1)}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></div>
				)
			}
		},
		{
			title: 'Country',
			key: 'Country',
			dataIndex: 'country',
			align: "center",
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'state',
			align: "center",
		},
		{
			title: 'University',
			key: 'University',
			dataIndex: 'university',
		},
		{
			title: 'Chapter',
			key: 'Chapter',
			dataIndex: 'chapter_id',
			align: "center",
			width: 20,
		},
		{
			title: 'Area',
			key: 'Area',
			width: 30,
			render: (record) => {
				switch (Number(record.area)) {
					case 0:
						return <span>Flashcards</span>
					case 1:
						return <span>Images</span>
					case 2:
						return <span>Study Guides</span>
					case 3:
						return <span>Tests</span>
					case 4:
						return <span>Tutorials</span>
				}
			}
		},
		{
			title: 'Comments',
			key: 'overall_comments',
			dataIndex: 'overall_comments',
			width: 380,
		},
	];

	const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value)
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.feedbackPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card hoverable className="addQcard feedbacktypeselect mb-2" title="FEEDBACK DATA"
						>
						</Card>
						<Card hoverable className="mb-2">
							<Form form={form} onFinish={submitData} className='countriesByregistrationFilters'>
								<Row gutter={24}>
									<Col span={8}>
										<Form.Item label="From">
												<RangePicker
												defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
												format={dateFormat}
												className='w-full'
												onChange={getStartandEnddate}
												value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
												allowClear={false}
												/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Period">
											<Select className='w-full rounded-md'
											  	value={period}
												placeholder={constants.Period}
												onChange={onChangePeiod}
											>
												{constants.periodOptions.map((option, index) => (
													<Select.Option key={option} value={index}>
														{option}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								 
										<Col span={8}>
											<Form.Item label="Chapter">
											<Select className='w-full rounded-md' value={chapterid} onChange={getChapterIds}>
													<Select.Option value=""> --All Chapters-- </Select.Option>
													{chapters.map((option, index) => {
														return <Select.Option key={option.value} value={
															option.value.toString()
														}> {option.label} </Select.Option>
													})}
												</Select>
											</Form.Item>
										</Col>
									 
									
								</Row>
							</Form>
						</Card>
						<Card title='ISSUE FEEDBACK'
							className="addQcard"
							hoverable
						>
								<Table
									className='registeredStudsTable'
									bordered
									scroll={{ x: 768 }}
									columns={issueFeedbackTabData}
									dataSource={issueFeedbackData}
								 // rowKey={(record) => record.id}
									locale={{ emptyText: constants.emptyTableText }}
								/>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default IssueFeedback
