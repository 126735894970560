import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card, Image, Spin, Space, Tooltip, Divider, message, Radio, Modal, Form, Input, Select } from 'antd';
import { fetchApi } from '../../../services/api';
import { DownloadOutlined,WarningOutlined, ReadOutlined, MessageOutlined, ArrowLeftOutlined, ZoomOutOutlined, ZoomInOutlined, CloseCircleOutlined, StarFilled, IssuesCloseOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
import { getColorClass, success } from '../../../functions/functions';
import { COOKIE, getCookie } from '../../../services/cookie';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
const { Meta } = Card;
const { TextArea } = Input;
const ChapterResources = () => {
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const location = useLocation();
	const navigate = useNavigate();
	const stateData = location.state;
	const [ChapterResourcesValue, setChapterResourcesValue] = useState({});
	const [imageData, setImageData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [previewImg, setPreviewImg] = useState([]);
	const [visibleImage, setVisibleImage] = useState(false);
	const [imageIds, setImageIds] = useState([]);
	const [flashcardViewedCount, setflashcardViewedCount] = useState(0);
	const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);
	const [formFilled, setFormfilled] = useState();
	const [formError, setFormError] = useState(false);
	const [reportanIssueModalVisible, setReportanissueModalVisible] = useState(false);
	const [resource, setResource] = useState();
	const [currentPreview, setCurrentPreview] = useState(0);
	const [chapterfdbckfirstsubmit, setChapterfdbckFirstSubmit] = useState();
	const [displayFeedbackBtn, setDisplayFeedbackBtn] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const userId = getCookie(COOKIE.UserId);
	const [dateDownloaded,setDateDownloaded]=useState("");
	const [pausedTests,setPausedTests]=useState(false);
	const getChapterResources = async () => {
		setLoader(true);
		let payload = {
			stud_id: userId,
			chapter_id: stateData?.chapter_id
		}
		fetchApi(`/chapter-resource`, "post", payload).then((res) => {
			if (res?.code === 200) {
				setDisplayFeedbackBtn(res?.data?.display_feedback);
				setChapterfdbckFirstSubmit(res?.data?.is_chapter_submitted);
				setLoader(false);
				setChapterResourcesValue(res?.data);
				setDateDownloaded(res?.data?.date_downloaded);
				setPausedTests(res?.data?.is_test_paused);
				if (res?.data?.images?.length > 0) {
					let imageList = res?.data?.images;
					let flashcardRemainingCount = res?.data?.flashcards?.viewed?.count;
					if(flashcardRemainingCount){
						let viewedCount = res?.data?.flashcards?.viewed?.total_count - flashcardRemainingCount;
						if(res?.data?.flashcards?.viewed?.status === "4"){
							if(flashcardRemainingCount === 0){
								setflashcardViewedCount(res?.data?.flashcards?.viewed?.total_count);
							}else{
								setflashcardViewedCount(viewedCount);
							}
						}else{
							setflashcardViewedCount(viewedCount);
						}
					}else{
						if(res?.data?.flashcards?.viewed?.status === "4"){
							setflashcardViewedCount(res?.data?.flashcards?.viewed?.total_count);
						}
					};
					// Using slice to take only 5 objects(Images value)
					setImageData(imageList);
					setImageIds(res?.data?.image_ids);
				} else {
					setImageData([]);
				}
			} else {
				setLoader(false);
				setChapterResourcesValue({});
			}
		})
	};
	const getSimilerDiv = (span1, span2, span3,attempt=null) => {
		return <div className='font-normal grid gap-2'>
			<div>{span1}</div>
			<div>{span2}</div>
			<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>attempt?redirectToReviewTutorial(attempt):null}>{span3}</Button></div>
		</div>
	};
	const getTutorialsCard = (cardNo, status, date, count, mrkfrltr = false, anscorrect = false) => {
		// status 1 = available, 2 = In progress, 3 = Paused, 4 = Completed; disabled={mrkfrltr}
		if (cardNo === "1") {
			if (status == "1") {
				return <div className='font-normal grid gap-2'>
					<div>Take a tutorial with 10 questions to test yourself.</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToStartTutorial(cardNo)}>Click here to start.</Button></div>
				</div>
			}
			else if (status == "2") {
				return <div className='font-normal grid gap-2'>
					In Progress on {date}
				</div>
			}
			else if (status == "3") {
				return getSimilerDiv(
					`Paused on ${date}`,
					`${count}/${constants.TotalMarksTutorial} remaining`,
					"Click here to resume."
				);
			}
			else if (status == "4") {
				return getSimilerDiv(
					`Completed on ${date}`,
					`${count}/${constants.TotalMarksTutorial} answered correctly`,
					"Click here to review.",
					cardNo
				);
			}
		}
		if (cardNo === "2") {
			if (status == "0") {
				return <div className='font-normal grid gap-2'>
					This tutorial will be available after you complete Tutorial #1.
				</div>
			}
			else if (status == "1") {
				return <div className='font-normal grid gap-2'>
					<div>Take a tutorial with 10 questions to test yourself.</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToStartTutorial(cardNo)} >Click here to start.</Button></div>
				</div>
			}
			else if (status == "2") {
				return <div className='font-normal grid gap-2'>
					In Progress on {date}
				</div>
			}
			else if (status == "3") {
				return getSimilerDiv(
					`Paused on ${date}`,
					`${count}/${constants.TotalMarksTutorial} remaining`,
					"Click here to resume.",
				);
			}
			else if (status == "4") {
				return getSimilerDiv(
					`Completed on ${date}`,
					`${count}/${constants.TotalMarksTutorial} answered correctly`,
					"Click here to review.",
					cardNo
				);
			}
		}
		if (cardNo === "3") {
			return <div className='font-normal grid gap-2'>
				<div>Questions previously bookmarked & answered incorrectly will appear in the following tutorials.</div>
				<div><Button type="link" className='text-base font-normal !pl-0'  onClick={()=>redirectToMflIncorrecTutorial(cardNo,true,false,mrkfrltr)} >Marked for later tutorial</Button></div>
				<div><Button type="link" className='text-base font-normal !pl-0'  onClick={()=>redirectToMflIncorrecTutorial(cardNo,false,true,anscorrect)} >Incorrect answers tutorial</Button></div>
			</div>
		}
	};
	const getTestsCard = (attempt, status, date, count,scoreCardId) => {
		// return <div className='font-normal grid gap-2'>
		// 			<div>{constants.TestStatus1Text}</div>
		// 			<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToStartTest("1")}>Click here to start.</Button></div>
		// 		</div>
		// status 1 = available, 2 = In progress, 3 = Paused, 4 = Completed
		let question;
		if(count > 1){
			question = constants.Question +"s"
		}else{
			question = constants.Question
		}
		if (attempt === "1") {
			if (status == "1") {
				return <div className='font-normal grid gap-2'>
					<div>{constants.TestStatus1Text}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToStartTest(attempt)}>Click here to start.</Button></div>
				</div>
			}
			else if (status == "2") {
				return <div className='font-normal grid gap-2'>
					<div>In Progress on {date}</div>
					<div>{constants.CrashedPausedTest}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToResumeTest(attempt,scoreCardId)}>Click here to resume.</Button></div>
				</div>
			}
			else if (status == "3") {
				return (
				<div className='font-normal grid gap-2'>
				<div>Paused on {date}</div>
				<div>{count}/{constants.TotalMarksTest} {question} remaining</div>
				<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToResumeTest(attempt,scoreCardId)}>Click here to resume.</Button></div>
				</div>);
			}
			else if (status == "4") {//
				// return getSimilerDiv(
				// 	`Completed on ${date}`,
				// 	`${count}/${constants.TotalMarksTest} answered correctly`,
				// 	"Click here to review."
				// );
				return (
					<div className='font-normal grid gap-2'>
					<div>Completed on {date}</div>
					<div>You scored {count}/{constants.TotalMarksTestComplete}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToReviewTest(attempt,scoreCardId)}>Click here to review.</Button></div>
					</div>);
			}
		}
		if (attempt === "2") {
			if (status == "0") {
				return <div className='font-normal grid gap-2'>
					This test is available after you complete Attempt #1.
				</div>
			}
			else if (status == "1") {
				return <div className='font-normal grid gap-2'>
					<div>{constants.TestStatus1Text}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToStartTest(attempt)}>Click here to start.</Button></div>
				</div>
			}
			else if (status == "2") {
				return <div className='font-normal grid gap-2'>
					<div>In Progress on {date}</div>
					<div>{constants.CrashedPausedTest}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToResumeTest(attempt,scoreCardId)}>Click here to resume.</Button></div>				</div>
			}
			else if (status == "3") {
				return (
					<div className='font-normal grid gap-2'>
					<div>Paused on {date}</div>
					<div>{count}/{constants.TotalMarksTest} {question} remaining</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToResumeTest(attempt,scoreCardId)}>Click here to resume.</Button></div>
					</div>);
			}
			else if (status == "4") {
				return (
					<div className='font-normal grid gap-2'>
					<div>Completed on {date}</div>
					<div>You scored {count}/{constants.TotalMarksTestComplete}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToReviewTest(attempt,scoreCardId)}>Click here to review.</Button></div>
					</div>);
			}
		}
		if (attempt === "3") {
			if (status == "0") {
				return <div className='font-normal grid gap-2'>
					This test is available after you complete Attempts #1 and #2.
				</div>
			}
			else if (status == "1") {
				return <div className='font-normal grid gap-2'>
					<div>{constants.TestStatus1Text}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToStartTest(attempt)}>Click here to start.</Button></div>
				</div>
			}
			else if (status === "2") {
				return <div className='font-normal grid gap-2'>
					<div>In Progress on {date}</div>
					<div>{constants.CrashedPausedTest}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToResumeTest(attempt,scoreCardId)}>Click here to resume.</Button></div>				</div>
			}
			else if (status == "3") {
				return (
					<div className='font-normal grid gap-2'>
					<div>Paused on {date}</div>
					<div>{count}/{constants.TotalMarksTest} {question} remaining</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToResumeTest(attempt,scoreCardId)}>Click here to resume.</Button></div>
					</div>);
			}
			else if (status == "4") {
				return (
					<div className='font-normal grid gap-2'>
					<div>Completed on {date}</div>
					<div>You scored {count}/{constants.TotalMarksTestComplete}</div>
					<div><Button type="link" className='text-base font-normal !pl-0' onClick={()=>redirectToReviewTest(attempt,scoreCardId)}>Click here to review.</Button></div>
					</div>);
			}
		}
	};
	const routeToImages = () => {
		let state = stateData
		navigate('/image-resources', { state });
	};
	const getFlashcardsCard = (cardNo, status, date, count, score_card_id = null,total_count) => {
		if (cardNo === "1") {
			if (status == "1") {
				return <div className='font-normal grid gap-2'>
					<div>You have {total_count} flashcards in this set.</div>
					<div><Button onClick={() => routeToFlashcards("start")} type="link" className='text-base font-normal !pl-0'>Click here to start.</Button></div>
				</div>
			}
			else if (status == "3") {
				let flashcardObj = {
					score_card_id: score_card_id,
					// reviewedCount: constants.TotalFlashCards - Number(count)
				}
				return <div className='font-normal grid gap-2'>
					<div>{`Paused on ${date}`}</div>
					<div>{`${count}/${total_count} ${count > 1 ? "Flashcards" : "Flashcard"} remaining`}</div>
					<div><Button onClick={() => routeToFlashcards("resume", flashcardObj)} type="link" className='text-base font-normal !pl-0'>{"Click here to resume."}</Button></div>
				</div>
			}
			else if (status == "4") {
				let linkText = "Click here to view again.";
				let text;
				let flashcardObj = {
					score_card_id: score_card_id
				}
				if(count >0){
					text = `Paused on ${date}`;
					flashcardObj.reviewedCount = total_count - count;
					linkText = "Click here to resume."
				}else{
					text = `Completed on ${date}`;
					flashcardObj.reviewedCount = 0;
				}
				return <div className='font-normal grid gap-2'>
					<div>{text}</div>
					<div><Button onClick={() => routeToFlashcards("Completed", flashcardObj)} type="link" className='text-base font-normal !pl-0'>{linkText}</Button></div>
				</div>
			}
		}
		if (cardNo === "2") {
			if (status == "0") {
				return <div className='font-normal grid gap-2'>There are no flashcards bookmarked.</div>
			}
			else if (status == "1") {
				let flashcardObj = {
					score_card_id: score_card_id
				}
				return <div className='font-normal grid gap-2'>
					<div>{`You have bookmarked ${count} ${Number(count) > 1 ? "flashcards" : "flashcard"}.`}</div>
					<div><Button type="link" onClick={() => routeToFlashcards("Marked", flashcardObj)} className='text-base font-normal !pl-0'>Click here to review.</Button></div>
				</div>
			}
		}
	};
	const previwImage = (resourse_id,index) => {
		setCurrentPreview(index);
		setVisibleImage(false);
		setLoader(true);
		setPreviewImg([]);
		fetchApi(`/preview-image/${resourse_id}/${userId}/${stateData?.chapter_id}`, 'get').then((res) => {
			if (res?.code === 200) {
				setVisibleImage(true);
				setPreviewImg(res?.data?.[0]);
				setLoader(false);
			} else {
				setVisibleImage(true);
				setPreviewImg([]);
				setLoader(false);
			}
		})
	};
	const disableContextMenu = (e) => {
		e.preventDefault();
	};
	const downloadStudyGuide = () => {
		window.open(constants.ApiUrl + `/download-pdf/?chapter_id=${stateData?.chapter_id}&resource_type=0&stud_id=${userId}`, "_self");
	};
	const routeToFlashcards = (progress, flashcardObj = null) => {
		if (progress === "start") {
			let payload = {
				stud_id: userId,
				chapter_id: stateData?.chapter_id,
				flashcard_details: { "viewed": [] }
			};
			fetchApi(`/flashcards `, "post", payload).then((res) => {
				if (res.code === 200) {
					let state = {
						score_card_id: res?.score_card_id,
						chapter_id: stateData?.chapter_id,
						start_with: "All"
					};
					if (res?.score_card_id) {
						navigate('/flashcards', { state });
					}
				} else {
					message.error("Failed to view flashcards");
				}
			});
		} 
		else {
			let state = {
				score_card_id: flashcardObj?.score_card_id,
				chapter_id: stateData?.chapter_id,
			};
			if (progress === "resume") {
				state.reviewedCount = flashcardViewedCount;
				state.start_with = "All";
				navigate('/flashcards', { state });
			} 
			else if (progress === "Marked") {
				let payload = {
					stud_id: userId,
					chapter_id: stateData?.chapter_id
				}
				fetchApi(`/flashcards-mark-to-study`, "post", payload).then((res) => {
					if (res?.data) {
						state.start_with = "Marked";
						state.score_card_id = flashcardObj?.score_card_id,
						state.mark_for_later_score_card_id = res?.score_card_id;
						navigate('/flashcards', { state });
					}else{
						message.error(res?.error);
					}
				});
			}
			else if (progress === "Completed") {
				state.start_with = "All";
				state.reviewedCount = flashcardObj.reviewedCount
				navigate('/flashcards', { state });
			}
			// navigate('/flashcards', { state });
		}
	};
	//Redirect to start tutorial
	const redirectToStartTutorial=(attempt,isMFL=false,isReviewIncorrect=false)=>{
		let state={
			chapter_id:stateData?.chapter_id,
			attempt:attempt,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name
		}
		if(isMFL===true){
			state.is_marked_later=true
		}
		if(isReviewIncorrect===true){
			state.is_ans_incorrect=true
		}
		navigate('/tutorial-instructions',{state});
	}
	//Redirect to review tutorial 
	const redirectToReviewTutorial=(attempt)=>{
		let state={
			chapter_id:stateData?.chapter_id,
			attempt:attempt,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name
		}
		navigate('/tutorial-review',{state});
	}
	//Redirect to markForLater
	const redirectToMflIncorrecTutorial=(attempt,isMFL=false,isReviewIncorrect=false,isAvailable)=>{
		let state={
			chapter_id:stateData?.chapter_id,
			attempt:attempt,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name,
			isAvailable:!isAvailable
		}
		if(isMFL===true){
			state.is_marked_later=true
			state.isSingle=ChapterResourcesValue?.tutorials.mark_for_later_single
			state.isImage=ChapterResourcesValue?.tutorials.mark_for_later_img
		}
		if(isReviewIncorrect===true){
			state.is_ans_incorrect=true
			state.isSingle=ChapterResourcesValue?.tutorials.incorrect_single
			state.isImage=ChapterResourcesValue?.tutorials.incorrect_img
		}
		navigate('/marked-incorrect-instructions',{state});
	}
	//Redirect to start test
	const redirectToStartTest=(attempt)=>{
		let state={
			chapter_id:stateData?.chapter_id,
			attempt:attempt,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name
		}
		navigate('/test-instructions',{state});
	}
//Redirect to resume test
	const redirectToResumeTest=(attempt,score_card_id)=>{
		let state={
			chapter_id:stateData?.chapter_id,
			attempt:attempt,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name,
			score_card_id:score_card_id
		}
		navigate('/take-test',{state});
	}
	//RedirectToReviewTest
	const redirectToReviewTest=(attempt,score_card_id)=>{
		let state={
			chapter_id:stateData?.chapter_id,
			attempt:attempt,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name,
			score_card_id:score_card_id,
			show_result:"t"
		}
		navigate('/review-test',{state});
	}
	//redirect to notes
	const redirectToNotes=()=>{
		let state={
			chapter_id:stateData?.chapter_id,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name
		}
		navigate('/notes',{state});
	}
	const onChangeImage = (current, prev) => {
		setLoader(true);
		setCurrentPreview(current);
		let resource_file_id = imageIds[current]?.resource_file_id;
		if(current === 5){
			setLoader(false);
			let temp = {
				resource_name:  '' ,
			}
			setPreviewImg(temp);
		}else{
			fetchApi(`/preview-image/${resource_file_id}/${userId}/${stateData?.chapter_id}`, 'get').then((res) => {
				if (res?.code === 200) {
					setLoader(false);
					setPreviewImg(res?.data?.[0]);
				} else {
					setLoader(false);
					setPreviewImg([]);
				}
			});
		}
	};
	const redirectToFlashcardTYS = () =>{
		let state={
			chapter_id:stateData?.chapter_id,
			chapter_name:ChapterResourcesValue?.images?.[0]?.chapter_name,
			start_with : "test-yourself"
		}
		navigate('/flashcard-instructions',{state});
	};
	const moveToTestSection=()=>{
		document.getElementById('test').scrollIntoView({ behavior: "smooth" });
	}
	useEffect(() => {
		getChapterResources();
		sessionStorage.clear();
		sessionStorage.setItem("UserIdSession",getCookie(COOKIE.UserId));
		if(location?.state?.isScrollToTest==="t"){
			moveToTestSection();
		}
		document.addEventListener('contextmenu', disableContextMenu);
	}, []);
	const showFeedbackModal = ()=>{
		setFeedbackFormVisible(true);
	}

	const closeFeedbackForm = ()=>{
		setFeedbackFormVisible(false);
		form.resetFields();
	}

	const showReportanIssueModal = ()=>{
		setReportanissueModalVisible(true);
	}

	const closeReportanIssueModal = () => {
		setReportanissueModalVisible(false);
		form.resetFields();
	}

	const getResourceValue = (value)=>{
		setResource(value);
	}
	const handleOtherFeedBackValidation = (rule, value, callback) => {
		try {
			if (value) {
				if (value.trim() === "") {
					throw new Error(constants.mandatoryField);
				}
			} else {
				throw new Error(constants.mandatoryField);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const displayReportAndIssueModal = ()=>{
		return(
			<Modal footer={false} title={<span className="font-bold text-xl">{constants.ReportanIssue}<span className="pl-1"></span></span>} open={reportanIssueModalVisible} onCancel={closeReportanIssueModal} centered width={600}>
				<div className="cursorChange">
					<Card hoverable>
						<div className='mb-4'><span className='font-bold text-lg'>{`Chapter ${stateData?.chapter_id} :`}</span><span className='font-bold text-lg ml-3' style={{ color: getColorClass(stateData?.chapter_id, "colorcode") }}>{ChapterResourcesValue?.images?.[0]?.chapter_name}</span></div>
						<Form form={form} onFinish={submitIssueInfo} layout="vertical" className='mt-3 reportIssueForm'>
							<Form.Item label={constants.Area} name="resourceCode"
								rules={
									[{
										required: true,
										message: constants.mandatoryField,
									},
									]
								}
							>
								<Select className='w-full rounded-md'
									onChange={getResourceValue}
									value={resource}
									placeholder={constants.AreaPlaceholder}
								>
									{constants.ResourceOptions.map((resource, index) => (
										<Select.Option key={resource} value={index}>
											{resource}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label={constants.Comment} name="issue"
								rules={
									[{
										required: true,
										message: '',
									},
									{
										validator: handleOtherFeedBackValidation
									},
									]
								}

							>
								<TextArea placeholder="Your comments..." allowClear />
							</Form.Item>
							<Form.Item className="relative">
								<div className="absolute right-0">
									<Button
										type="primary"
										htmlType="submit"
										className='font-semibold'
									// disabled={formError}
									>Submit</Button>
									<Button className='ml-2 font-semibold bg-blue-600 text-white' onClick={closeReportanIssueModal}>Cancel</Button>
								</div>
							</Form.Item>

						</Form>
					</Card>
				</div>
			</Modal>
		)
	}

	const submitIssueInfo = (values)=>{
		let issueInfoPayload = null;
		issueInfoPayload = {
			stud_id: userId,
			chapter_id: stateData?.chapter_id,
			overall_comments: values.issue,
			area: values.resourceCode
		}

		fetchApi(`/report-issue`, 'post', issueInfoPayload).then((res) => {
			if (res.code === 200) {
				closeReportanIssueModal();
				form.resetFields();
				success('success', constants.issueInfoSentSucess, 'afterQaddsuccess', messageApi);
			} else {
				form.resetFields();
			}
		})
	}

	useEffect(()=>{
		const currentValue = form.getFieldsValue();
	},[])

	useEffect(()=>{
		const currentValue = form.getFieldsValue();
		const isUndefined = (eachValue) => eachValue == undefined;
		let formFieldValues = Object.values(currentValue);
		const checkformFilledstatus = formFieldValues.every(isUndefined);
		if (checkformFilledstatus == true){
			setFormfilled(false);
		}else{
			setFormfilled(true);
		}
	}, [feedbackFormVisible])

	const handleChangeForm = (changedValues, allValues) => {
		setFormfilled(true);
		setFormError(false);
	}

	const submitFeedbackForm = (values)=>{
		let user = getCookie(COOKIE.UserId);
		// setSubmitstate(true);
		let feedbackFormPayload = null;
		let chapterwiseCommentFeedbackFormPayload = null;
		feedbackFormPayload = {
			stud_id: userId,
			chapter_id: stateData?.chapter_id,
			studyguide_rating: values.studyGuideQRating,
			images_rating: values.imageQRating,
			flashcards_rating: values.flashcardQRating,
			tutorials_rating: values.tutorialQRating,
			tests_rating: values.testQRating,
			overall_comments: values.extrainfo,
			first_submit: true,
		}
		chapterwiseCommentFeedbackFormPayload = {
			stud_id: user,
			chapter_id: stateData?.chapter_id,
			overall_comments: values.commentInfo,
			first_submit: false,
		}

		const payloadType = chapterfdbckfirstsubmit ? chapterwiseCommentFeedbackFormPayload : feedbackFormPayload;
		if (!formFilled){
			success('error', constants.atleastoneFieldRequiredError, 'afterQaddsuccess', messageApi);
		}else{
			setIsSubmitting(true);
			fetchApi(`/feedback`, 'post', payloadType).then((res) => {
				if (res.code === 200) {
					closeFeedbackForm();
					form.resetFields();
					success('success', constants.feedbackSentSuccess, 'afterQaddsuccess', messageApi);
				} else {
					form.resetFields();
				}
				if (res.is_chapter_submitted === true) {
					setChapterfdbckFirstSubmit(true);
				}
			})
				.finally(() => {
					setIsSubmitting(false);
				});
		}
	}

	const displayFeedbackForm = ()=>{
		return(
			<Modal footer={false} title={<span>{constants.FeedbackFormHeader}<span className="pl-1">{stateData?.chapter_id}</span></span>} open={feedbackFormVisible} onCancel={closeFeedbackForm} centered>
				{
					!chapterfdbckfirstsubmit ?
						<Form form={form} onFinish={submitFeedbackForm} layout="vertical" onValuesChange={handleChangeForm}>
							<Form.Item label={constants.FeedbackQuestion1} name="studyGuideQRating">
								<Radio.Group
								>
									<Radio value={0}>{constants.RatingOpt1}</Radio>
									<Radio value={1}>{constants.RatingOpt3}</Radio>
									<Radio value={2}>{constants.RatingOption3}</Radio>
									<Radio value={3}>{constants.RatingOption4}</Radio>
									<Radio value={4}>{constants.ChalTestOpt2}</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item label={constants.FeedbackQuestion2} name="imageQRating">
								<Radio.Group
								>
									<Radio value={0}>{constants.RatingOpt1}</Radio>
									<Radio value={1}>{constants.RatingOpt3}</Radio>
									<Radio value={2}>{constants.RatingOption3}</Radio>
									<Radio value={3}>{constants.RatingOption4}</Radio>
									<Radio value={4}>{constants.ChalTestOpt2}</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item label={constants.FeedbackQuestion3} name="flashcardQRating">
								<Radio.Group
								>
									<Radio value={0}>{constants.RatingOpt1}</Radio>
									<Radio value={1}>{constants.RatingOpt3}</Radio>
									<Radio value={2}>{constants.RatingOption3}</Radio>
									<Radio value={3}>{constants.RatingOption4}</Radio>
									<Radio value={4}>{constants.ChalTestOpt2}</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item label={constants.FeedbackQuestion4} name="tutorialQRating">
								<Radio.Group
								>
									<Radio value={0}>{constants.RatingOpt1}</Radio>
									<Radio value={1}>{constants.RatingOpt3}</Radio>
									<Radio value={2}>{constants.RatingOption3}</Radio>
									<Radio value={3}>{constants.RatingOption4}</Radio>
									<Radio value={4}>{constants.ChalTestOpt2}</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item label={constants.FeedbackQuestion5} name="testQRating">
								<Radio.Group
								>
									<Radio value={0}>{constants.RatingOpt1}</Radio>
									<Radio value={1}>{constants.RatingOpt3}</Radio>
									<Radio value={2}>{constants.RatingOption3}</Radio>
									<Radio value={3}>{constants.RatingOption4}</Radio>
									<Radio value={4}>{constants.ChalTestOpt2}</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item name="extrainfo">
								<TextArea placeholder="Comments..." allowClear />
							</Form.Item>
							<Form.Item className="relative">
								<div className="absolute right-0">
									<Button
										type="primary"
										htmlType="submit"
										className='font-semibold'
										disabled={formError || isSubmitting}
									>Submit</Button>
									<Button className='ml-2 font-semibold bg-blue-600 text-white' onClick={closeFeedbackForm}>Cancel</Button>
								</div>
							</Form.Item>
						</Form>
						:
						<Form form={form} onFinish={submitFeedbackForm} onValuesChange={handleChangeForm}>
							<Form.Item name="commentInfo"
								rules={
									[{
										required: true,
										message: constants.EmptyCommentError
									},
									{
										validator: (_, value) =>
											value != '' ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
									},
									]
								}
							>
								<TextArea placeholder="Comments..." allowClear
								/>
							</Form.Item>
							<Form.Item className="relative">
								<div className="absolute right-0">
									<Button
										type="primary"
										htmlType="submit"
										className='font-semibold'
									// disabled={formError}
									>Submit</Button>
									<Button className='ml-2 font-semibold bg-blue-600 text-white' onClick={closeFeedbackForm}>Cancel</Button>
								</div>
							</Form.Item>
						</Form>
				}

			</Modal>
		)
		}

	return (
		<Spin spinning={loader} size="large" className='z-30'>
			<div
				style={{ backgroundColor: getColorClass(stateData?.chapter_id, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-[-103px] h-36'
			>
				{stateData?.chapter_id}
			</div>
			<div className='container mx-auto'>
				<div className='flex justify-between items-center'>
					<div style={{ color: getColorClass(stateData?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{ChapterResourcesValue?.images?.[0]?.chapter_name}</div>
				</div>
				<Divider className='my-2' />
				<div className='flex flex-wrap justify-between items-center mt-3 gap-2'>
					<Space>
					<Button onClick={downloadStudyGuide} icon={<DownloadOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.DownldStdyGuide}</Button>
					{dateDownloaded?
					<span className='italic text-sm'>(downloaded on {dateDownloaded})</span>
					:null}
					</Space>
					<Space wrap>
						{pausedTests?
						<Button type="text" danger  onClick={()=>moveToTestSection()}><WarningOutlined /> Test Paused!</Button>
						:null}
						<Button onClick={() => navigate('/dashboard')} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.BckDashbd}</Button>
						<Button onClick={()=>redirectToNotes()} icon={<ReadOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.MyNotes}</Button>
						{displayFeedbackBtn == 0 ? 
						<div></div>
						:
							<Button icon={<MessageOutlined />} className='rounded-lg bg-blue-700 text-white' onClick={showFeedbackModal}>{constants.Feedback}</Button>
						}
						{/* <Button icon={<MessageOutlined />} className='rounded-lg bg-blue-700 text-white' onClick={showFeedbackModal}>{constants.Feedback}</Button> */}
						<Button icon={<IssuesCloseOutlined />} className='rounded-lg bg-blue-700 text-white' onClick={showReportanIssueModal}>{constants.ReportanIssue}</Button>
					</Space>
				</div>
				{/* images section */}
				<div className=''>
					<div style={{ backgroundColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`flex justify-between items-center mt-3 py-2.5 px-5`}>
						<div className='text-white font-bold text-xl'>{constants.Images}</div>
						<div onClick={routeToImages} className='text-white font-bold text-xl underline-offset-2 underline cursor-pointer'>{"View all Images"} {" "} ({imageIds.length})</div>
					</div>
					<Card style={{ borderColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`rounded-none border`}>
						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3'>
							<Image.PreviewGroup
								preview={{
									closeIcon: <CloseCircleOutlined className='hidden' />,
									rootClassName:"img-pre",
									visible: visibleImage,
									src: previewImg?.resource_name,
									onVisibleChange: (value) => {
										if (value === false) {
											setVisibleImage(false);
											getChapterResources();
										}
									},
									onChange: (current, prev) => onChangeImage(current, prev),
									current:currentPreview,
									toolbarRender: (
										_,
										{ transform: { scale }, actions: { onZoomOut, onZoomIn } }
									) => (
										<div className="w-full lg:w-3/5 toolbar-wrapper text-base flex items-center justify-between top-20 translate-x-[-50%] left-1/2 px-6 text-black rounded-full bg-white">
											<div className='my-1.5'>
												<div>{previewImg?.caption}</div>
												{previewImg?.details && (<div>{previewImg?.details}</div>)}
											</div>
											<Space size={12} className='my-2'>
												<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
												<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
												<CloseCircleOutlined className='text-red-600' onClick={() => { setVisibleImage(!visibleImage) }} />
												{currentPreview === 5 ? 
													<div className='text-center absolute top-[540%] right-0 w-full p-5 bg-slate-100'>
													<p className='text-base lg:text-2xl pb-5'>{constants.viewallimgsdesc}</p>
													<Button type="primary" size='large' onClick={routeToImages}>{constants.viewallimgs}</Button>
												</div>
												 : null}
											</Space>
										</div>
									)
								}}
							>
								{imageData?.map((image_data,index) => {
									return <Card
										className='border-black border rounded-none p-1 grid place-content-center
										items-center overflow-hidden'
										key={index}
										cover={<Image
											onClick={() => previwImage(image_data?.resource_file_id,index)}
											alt={image_data?.caption}
											src={image_data?.resource_name}
										// effect="blur"
										/>}
									>
										<Tooltip title={image_data?.caption}><Meta className="bg-gray-300 p-1 absolute bottom-1 left-0 w-full" title={image_data?.caption} /></Tooltip>
									</Card>
								})
								}
								{
									imageIds.length > 5 ?
										<Card
											className='hidden'
											key={5}
											cover={<Image
												src={""}
											/>}
										>
										</Card> : null
								}
							</Image.PreviewGroup>
						</div>
						{/* <div className='text-right'><Button onClick={routeToImages} type='link'>Showing 5 of {imageIds.length} images</Button></div>  */}
					</Card>
				</div>
				{/* flashcard section */}
				<div className=''>
					<div style={{ backgroundColor: getColorClass(stateData?.chapter_id, "colorcode") }} className='mt-2 py-2.5 px-5'>
						<div className='text-white font-bold text-xl'>{constants.Flashcards}</div>
					</div>
					<Card style={{ borderColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`rounded-none border`}>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>View Flashcards {ChapterResourcesValue?.flashcards?.viewed?.status === "4"  ?<Tooltip title={`${flashcardViewedCount}/${ChapterResourcesValue?.flashcards?.viewed?.total_count}`}><StarFilled className='text-yellow-400'/></Tooltip>:null}</span>} className='min-h-[208px] bg-[#DFF0FF] text-base' bodyStyle={{ paddingTop: 0 }}>
									{getFlashcardsCard("1",
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.viewed?.status : null,
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.viewed?.date : null,
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.viewed?.count : null,
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.viewed?.score_card_id : null,
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.viewed?.total_count : null,
									)}
								</Card>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Marked to Study Later</span>} className='min-h-[208px] bg-[#CBF3E6] text-base' bodyStyle={{ paddingTop: 0 }}>
									{getFlashcardsCard("2",
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.mark_for_later?.status : null,
										null,
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.mark_for_later?.count : null,
										ChapterResourcesValue?.flashcards ? ChapterResourcesValue?.flashcards?.viewed?.score_card_id : null,
									)}
								</Card>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Test Yourself</span>} className='min-h-[208px] bg-amber-100 text-base' bodyStyle={{ paddingTop: 0 }}>
									<div>
										<div>Do you know the answers to these 10 flashcards ?</div>
										<div><Button type='link' onClick={redirectToFlashcardTYS} className='text-base font-normal !pl-0'>Click here to start.</Button></div>
									</div>
								</Card>
							</Col>
						</Row>
					</Card>
				</div>
				{/* Tutorials section */}
				<div className=''>
					<div style={{ backgroundColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`flex justify-between items-center mt-2 py-2.5 px-5`}>
						<div className='text-white font-bold text-xl'>{constants.Tutorials}</div>
					</div>
					<Card style={{ borderColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`rounded-none border`}>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Tutorial #1</span>} className='min-h-[208px] bg-[#DFF0FF] text-base' bodyStyle={{ paddingTop: 0 }}>
									{getTutorialsCard("1",
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.attempts?.attempt1?.status : null,
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.attempts?.attempt1?.date : null,
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.attempts?.attempt1?.count : null,
									)}
								</Card>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Tutorial #2</span>} className='min-h-[208px] bg-[#CBF3E6] text-base' bodyStyle={{ paddingTop: 0 }}>
									{getTutorialsCard("2",
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.attempts?.attempt2?.status : null,
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.attempts?.attempt2?.date : null,
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.attempts?.attempt2?.count : null,
									)}
								</Card>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Review</span>} className='min-h-[208px] bg-amber-100 text-base' bodyStyle={{ paddingTop: 0 }}>
									{getTutorialsCard("3", null, null, null,
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.mark_for_later===1?false:true: false,
										ChapterResourcesValue?.tutorials ? ChapterResourcesValue?.tutorials?.incorrect===1?false:true: false,
									)}
								</Card>
							</Col>
						</Row>
					</Card>
				</div>
				{/* Tests section */}
				<div className='' id='test'>
					<div style={{ backgroundColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`flex justify-between items-center mt-2 py-2.5 px-5`}>
						<div className='text-white font-bold text-xl'>{constants.Tests}</div>
					</div>
					<Card style={{ borderColor: getColorClass(stateData?.chapter_id, "colorcode") }} className={`rounded-none border`}>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Attempt #1</span>} className='min-h-[208px] bg-[#DFF0FF] text-base' bodyStyle={{ paddingTop: 0 }}>
									{getTestsCard("1",
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt1?.status : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt1?.date : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt1?.count : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt1?.score_card_id : null,
									)}
								</Card>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Attempt #2</span>} className='min-h-[208px] bg-[#CBF3E6] text-base' bodyStyle={{ paddingTop: 0 }}>
									{getTestsCard("2",
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt2?.status : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt2?.date : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt2?.count : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt2?.score_card_id : null,
									)}
								</Card>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<Card title={<span className='font-bold text-lg'>Attempt #3</span>} className='min-h-[208px] bg-amber-100 text-base' bodyStyle={{ paddingTop: 0 }}>
									{getTestsCard("3",
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt3?.status : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt3?.date : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt3?.count : null,
										ChapterResourcesValue?.tests ? ChapterResourcesValue?.tests?.attempt3?.score_card_id : null,
									)}
								</Card>
							</Col>
						</Row>
					</Card>
					{contextHolder}
				</div>
			</div>
			{feedbackFormVisible && displayFeedbackForm()}
			{reportanIssueModalVisible && displayReportAndIssueModal()}
		</Spin>
	)
}

export default ChapterResources;