import React,{useState,useEffect} from 'react';
import { getCustomisedBreadcrumbs, setStartandEnddate, changeDateFormat } from '../../../../functions/functions';
import constants from '../../../../constants/constants';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Button,
	Tooltip,
	Badge,
	Input,
	FloatButton,
	Spin
} from 'antd';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchAdminApi } from '../../../../services/api/index';
const { RangePicker } = DatePicker;
const { Search } = Input;
import { MinusOutlined } from '@ant-design/icons';

const ResourceResult = () => {
	const [form] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();
	const dateFormat = 'MM/DD/YYYY';
	const { chapterwisetestObject, chapterwisetutorialObject, attemptwisetestObject, attemptwisetutorialObject, totalFlashcardObject, videowiseObject, imagewiseObject, studyGuidewiseObject} = location.state || {};
	const [loader, setLoader] = useState(false);
	const useToggle = (initialState) => {
		const [toggleValue, setToggleValue] = useState(initialState);
		const toggler = () => { setToggleValue(!toggleValue) };
		return [toggleValue, toggler]
	};
	const [toggle, setToggle] = useToggle();
	const resourcetype = sessionStorage.getItem("selectedSection");
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formvalues,setFormvalues] = useState({});
	const [formdisplay, setFormDisplayed] = useState();
	const [usertype, setUserType] = useState();
	const [newCrumb, setNewCrumb] = useState();

	//Test related states
	const [startdatefortest, setStartDateForTest] = useState(chapterwisetestObject ? chapterwisetestObject?.startdate : attemptwisetestObject ? attemptwisetestObject?.startdate:dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortest, setEndDateForTest] = useState(chapterwisetestObject ? chapterwisetestObject?.enddate : attemptwisetestObject ? attemptwisetestObject?.enddate :dayjs().endOf('year').format('MM/DD/YYYY'));
	const [testresultcount, setTotalTestResultCount] = useState();
	const [testdata, setTestData] = useState([]);

	//Tutorial related states
	const [startdatefortutorial, setStartDateForTutorial] = useState(chapterwisetutorialObject ? chapterwisetutorialObject?.startdate : attemptwisetutorialObject ? attemptwisetutorialObject?.startdate:dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortutorial, setEndDateForTutorial] = useState(chapterwisetutorialObject ? chapterwisetutorialObject?.enddate : attemptwisetutorialObject?attemptwisetutorialObject?.enddate:dayjs().endOf('year').format('MM/DD/YYYY'));
	const [tutorialresultcount, setTotalTutorialResultCount] = useState();
	const [tutorialdata, setTutorialData] = useState([]);

	//Flashcard related states
	const [startdateforflashcard, setStartDateForFlashcard] = useState(totalFlashcardObject?.startdate);
	const [enddateforflashcard, setEndDateForFlashcard] = useState(totalFlashcardObject?.enddate);
	const [flashcardresultcount, setTotalFlashcardResultCount] = useState();
	const [flashcarddata, setFlashcardData] = useState([]);

	
	//Video related states
	const [videoResultCount, setVideoResultCount] = useState();
	const [videoData, setVideoData] = useState([]);
	const [startDateForVideo, setStartDateForVideo] = useState( videowiseObject?.sd );
	const [endDateforVideo, setEndDateForVideo] = useState( videowiseObject?.ed );

	//Image related states
	const [imageResultCount, setImageResultCount] = useState(0);
	const [imageData, setImageData] = useState([]);
	const [startDateForImage, setStartDateForImage] = useState( imagewiseObject?.sd );
	const [endDateforImage, setEndDateForImage] = useState( imagewiseObject?.ed );

	//Study Guide related states
	const [studyGuideResultCount, setStudyGuideResultCount] = useState(0);
	const [studyGuideData, setStudyGuideData] = useState([]);
	const [startDateForstudyGuide, setStartDateForStudyGuide] = useState( studyGuidewiseObject?.sd );
	const [endDateforstudyGuide, setEndDateForStudyGuide] = useState( studyGuidewiseObject?.ed );

	//Filter Related States
	const [chapters,setChapters] = useState([]);
	const [countries,setCountries] = useState([]);
	const [universities, setUniversities] = useState([]);
	const [changeduniversitydetails, setChangeduniversityDetails] = useState([]);
	const [optionalblocks,setOptionalBlocks] = useState(false);
	const [roles] = useState([
		{
			status:'Student',
			value:'0',
		},
		{
			status: 'Professional',
			value: '1',
		},
		{
			status: 'Faculty',
			value: '2',
		},
		{
			status: 'Other',
			value: '3',
		},
		{
			status: 'Unspecified',
			value: '4',
		}
	]);
	const [states, setStates] = useState([]);
	const [codes, setCodes] = useState({country: "",state: "",university: ""});
	const [rolecode,setRoleCode] = useState('');
	const [name,setName] = useState('');
	
	useEffect(() => {
		getDataFromLocationState();
	}, [location.state])

	useEffect(() => {
		const formValues = form.getFieldValue();
		setFormvalues(formValues);
		setFormDisplayed(true);
		if (localStorage.getItem('userType')) {
			const user = localStorage.getItem('userType');
			setUserType(user);
		}

		if (chapterwisetestObject || attemptwisetestObject) {
			setNewCrumb([<Link key={0} to="/total-tests-taken" className="font-semibold">
				Total Tests Taken</Link>, <Link key={0} to="/test-scores" onClick={() => sessionStorage.setItem('fromTestsTaken', 'newCrumb')} className="font-semibold">
					Tests Taken</Link>, <a key={1} to='' className="!text-black font-semibold">Test Result</a>])
		} else if (chapterwisetutorialObject || attemptwisetutorialObject) {
			setNewCrumb([<Link key={0} to="/total-tutorials-taken" className="font-semibold">
				Total Tutorials Taken</Link>, <Link key={0} to="/tutorial-scores" onClick={() => sessionStorage.setItem('fromTutorialsTaken', 'newCrumb')} className="font-semibold">
				Tutorials Taken</Link>, <a key={1} to='' className="!text-black font-semibold">Tutorial Result</a>])
		} else if (totalFlashcardObject) {
			setNewCrumb([<Link key={0} to="/total-flashcards-taken" className="font-semibold">
				Total Flashcards Taken</Link>,<a key={1} to='' className="!text-black font-semibold">Flashcard Result</a>])
		}else if (videowiseObject) {
			setNewCrumb([<Link key={1} to="/total-video-views" className="font-semibold">{constants.TotalVideoViews}</Link>,<span key={2} to='' className="!text-black font-semibold">{constants.VideoResultTableTitle}</span>])
		}
		else if (imagewiseObject) {
			setNewCrumb([<Link key={1} to="/total-image-views" className="font-semibold">{constants.TotalImageViews}</Link>,<span key={2} to='' className="!text-black font-semibold">{constants.ImageResultTableTitle}</span>])
		}
		else if (studyGuidewiseObject) {
			let block = sessionStorage.getItem('block')
			if(block==1){
				setNewCrumb([<Link key={1} to="/study-guide-downloads" className="font-semibold">{constants.StudyGuideDownload}</Link>,<span key={2} to='' className="!text-black font-semibold">{constants.StudyGuideDownloadResults}</span>])
			}else{
				let date = {
					startdate: studyGuidewiseObject?.sd,
					enddate: studyGuidewiseObject?.ed
				}
				setNewCrumb([<Link key={1} to="/total-study-guide-downloads" className="font-semibold">{constants.TotalStudyGuideDownload}</Link>,<Link key={2} to="/study-guide-downloads" state={{studyGuideObject:date}} className="font-semibold">{constants.StudyGuideDownload}</Link>,<span key={2} to='' className="!text-black font-semibold">{constants.StudyGuideDownloadResults}</span>])
			}
		}
		else {
			setNewCrumb([<Link key={1} className="!text-black font-semibold">{resourcetype == 0 ? 'Test Result' : resourcetype == 6 ?'Flashcard Result': 'Tutorial Result'}</Link>])
		}
	}, [])


	const getDataFromLocationState = ()=>{
		let url = resourcetype == 2 || resourcetype == 3 || resourcetype == 4 ?'/get-student-resource-list' : resourcetype != 6 ? `/test-results?type=${resourcetype}` : `/flashcard-results?` ;
		if (chapterwisetestObject) {
			url += `&start_date=${changeDateFormat(chapterwisetestObject?.startdate)}&end_date=${changeDateFormat(chapterwisetestObject?.enddate)}&chapter_id=${chapterwisetestObject?.chapterId}`
			form.setFieldsValue({ period: [dayjs(chapterwisetestObject?.startdate, dateFormat), dayjs(chapterwisetestObject?.enddate, dateFormat)], chapter:chapterwisetestObject?.chapterId})
		} else if (chapterwisetutorialObject) {
			url += `&start_date=${changeDateFormat(chapterwisetutorialObject?.startdate)}&end_date=${changeDateFormat(chapterwisetutorialObject?.enddate)}&chapter_id=${chapterwisetutorialObject?.chapterId}`
			form.setFieldsValue({ period: [dayjs(chapterwisetutorialObject?.startdate, dateFormat), dayjs(chapterwisetutorialObject?.enddate, dateFormat)], chapter: chapterwisetutorialObject?.chapterId})
		} else if (attemptwisetestObject){
			url += `&start_date=${changeDateFormat(attemptwisetestObject?.startdate)}&end_date=${changeDateFormat(attemptwisetestObject?.enddate)}&chapter_id=${attemptwisetestObject?.chapterId}&attempt=${attemptwisetestObject?.attempt}`
			form.setFieldsValue({ period: [dayjs(attemptwisetestObject?.startdate, dateFormat), dayjs(attemptwisetestObject?.enddate, dateFormat)], chapter: attemptwisetestObject?.chapterId})
		} else if (attemptwisetutorialObject) {
			url += `&start_date=${changeDateFormat(attemptwisetutorialObject?.startdate)}&end_date=${changeDateFormat(attemptwisetutorialObject?.enddate)}&chapter_id=${attemptwisetutorialObject?.chapterId}&attempt=${attemptwisetutorialObject?.attempt}`
			form.setFieldsValue({ period: [dayjs(attemptwisetutorialObject?.startdate, dateFormat), dayjs(attemptwisetutorialObject?.enddate, dateFormat)], chapter: attemptwisetutorialObject?.chapterId })
		} else if (totalFlashcardObject) {
			url += `start_date=${changeDateFormat(totalFlashcardObject?.startdate)}&end_date=${changeDateFormat(totalFlashcardObject?.enddate)}&test_status=${4}`
			form.setFieldsValue({ period: [dayjs(totalFlashcardObject?.startdate, dateFormat), dayjs(totalFlashcardObject?.enddate, dateFormat)], resourceStatus: (totalFlashcardObject?.status).toString()})
		}else if (videowiseObject) {
			form.setFieldsValue({ period: [dayjs(videowiseObject?.sd, dateFormat), dayjs(videowiseObject?.ed, dateFormat)] });
			url += `?resource_file_id=${videowiseObject?.resource_file_id}&start_date=${changeDateFormat(videowiseObject?.sd)}&end_date=${changeDateFormat(videowiseObject?.ed)}`
		}else if (imagewiseObject) {
			form.setFieldsValue({ chapter: imagewiseObject?.chapter_id, period: [dayjs(imagewiseObject?.sd, dateFormat), dayjs(imagewiseObject?.ed, dateFormat)] });
			url += `?start_date=${changeDateFormat(imagewiseObject?.sd)}&end_date=${changeDateFormat(imagewiseObject?.ed)}&resource_file_id=${imagewiseObject?.resource_file_id}`
		}else if (studyGuidewiseObject) {
			form.setFieldsValue({ chapter: studyGuidewiseObject?.chapter_id, period: [dayjs(studyGuidewiseObject?.sd, dateFormat), dayjs(studyGuidewiseObject?.ed, dateFormat)] });
			url += `?start_date=${changeDateFormat(studyGuidewiseObject?.sd)}&end_date=${changeDateFormat(studyGuidewiseObject?.ed)}`
			if(studyGuidewiseObject?.isTotal){
				url += `&total_study_guide=0`
			}else{
				url += `&resource_file_id=${studyGuidewiseObject?.resource_file_id}`;
			}
		}
		getResourceResult(url);
	}

	useEffect(()=>{
	getAllCountries();
	getAllStates();
	getAllUniversities();
	getAllTheChapters();
	},[])

	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				//setCountries(res?.data);
				res?.data.forEach(function (country, i) {
					if (country.country_id == "238") {
						res?.data.splice(i, 1);
						res?.data.unshift(country);
						return res?.data;
					}
				});
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}
	const getAllStates = () => {
		fetchAdminApi(`/admin-states?country_code=US`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setStates(res?.data);
			} else {
				setStates([]);
			}
		})
	}
	const getAllUniversities = () => {
		fetchAdminApi(`/admin-universities?country_code=US`, "get").then(
			(res) => {
				if (res?.data) {
					setUniversities(res?.data);
					Object.entries(res.data).forEach(([key, value]) => {
						if (key == "Alaska") {
							for (let i = 0; i < value?.universities.length; i++) {
								if (value?.universities?.region_code === undefined) {
									value?.universities?.splice(i, 1);
								}
							}
						}
					});
				} else {
					setUniversities(null);
				}
			}
		);
	};
	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data) {
				setChapters(res?.data);
			} else {
				setChapters([]);
			}
		})
	}
	const getTestInfoAndRedirect = (record, index) => {
		let studentDetails = {};
		studentDetails.studentId = record.stud_id;
		sessionStorage.setItem("studentFromTestResultPage", JSON.stringify(studentDetails));
		window.open(constants.BaseUrl+'/view-student');
	}
	const testResultTableColumns = [
		{
			title: <Tooltip title='Chapter Id'>Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex: 'chapter_id',
			align: 'center',
			width: 10,
		},
		{
			title: constants.studentName,
			key: constants.studentName,
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					usertype == 1 ?
						<div><span>{record?.name?.charAt(0)?.toUpperCase() + '.' + ' ' + record?.name?.slice(-1) + '.'}</span><span className='ml-2'></span></div>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getTestInfoAndRedirect(record, index)}>{record.name}</div>
				)
			},
		},
		{
			title: <Tooltip title={constants.countryTitle}>{constants.countryAbbr}</Tooltip>,
			key: constants.countryTitle,
			dataIndex: 'country',
			align: 'center',
			width: 10,
		},
		{
			title: <Tooltip title={constants.stateTitle}>{constants.stateAbbr}</Tooltip>,
			key: constants.stateTitle,
			dataIndex: 'state',
			align: 'center',
			width: 10,
		},
		{
			title: constants.universityTitle,
			key: constants.universityTitle,
			dataIndex: 'university',
			align: 'center',
			width: 10,
		},
		{
			title: <Tooltip title={constants.roleTitle}>{constants.roleAbbr}</Tooltip>,
			key: constants.roleTitle,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<span>{record.occupation
						== 'S' ? <Tooltip title={constants.studentTitle}><span>S</span></Tooltip> : record.occupation
							== 'P' ? <Tooltip title={constants.professionalTitle}><span>P</span></Tooltip> : record.occupation
								== 'F' ? <Tooltip title={constants.facultyTitle}><span>F</span></Tooltip> : record.occupation
									== '0' ? <Tooltip title={constants.otherTitle}><span>O</span></Tooltip> : record.occupation
										== 'U' ? <Tooltip title={constants.undecidedTitle}><span>U</span></Tooltip> : ''}</span>
				)
			}
		},
		{
			title: <Tooltip title='Attempt'>{constants.attemptAbbr}</Tooltip>,
			key: 'Attempt',
			dataIndex: 'attempt',
			align: 'center',
			width: 10,
		},
		{
			title: constants.progress,
			key: constants.progress,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer'>
							<Badge
							className={record.test_status == 3 ? 'pausedBadge' : 'completedBadge'}
							count={record.test_status == 3 ? constants.paused : constants.completed}
							/>
					</div>
				)
			}
		},
		{
			title: 'Date',
			key: 'Date',
			align: 'center',
			dataIndex:'attempt_date',
			width: 10,
		},
		{
			title: <Tooltip title={constants.questions}>{constants.questionsAbbr}</Tooltip>,
			key: constants.questionsAbbr,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<div>{record.total_corr_ans == null ? 0 : record.total_corr_ans}/{record.total_questions}
					</div>
				)
			}
		},
		{
			title: constants.score,
			key: constants.score,
			align: 'center',
			width: 10,
			dataIndex:'points',
		}
	]

	const getTutorialInfoAndRedirect = (record, index)=>{
		let studentDetails = {};
		studentDetails.studentId = record.stud_id;
		sessionStorage.setItem("studentFromTutorialResultPage", JSON.stringify(studentDetails));
		window.open(constants.BaseUrl+'/view-student');
	}
	const tutorialResultTableColumns = [
		{
			title: <Tooltip title='Chapter Id'>Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex:'chapter_id',
			align: 'center',
			width: 10,
		},
		{
			title: constants.studentName,
			key: constants.studentName,
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					usertype == 1 ?
						<div><span>{record?.name?.charAt(0)?.toUpperCase() + '.' + ' ' + record?.name?.slice(-1) + '.'}</span><span className='ml-2'></span></div>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getTutorialInfoAndRedirect(record, index)}>{record.name}</div>
				)
			},
		},
		{
			title: <Tooltip title={constants.countryTitle}>{constants.countryAbbr}</Tooltip>,
			key: constants.countryTitle,
			align: 'center',
			dataIndex: 'country',
			width: 10,
		},
		{
			title: <Tooltip title={constants.stateTitle}>{constants.stateAbbr}</Tooltip>,
			key: constants.stateTitle,
			align: 'center',
			dataIndex: 'state',
			width: 10,
		},
		{
			title: constants.universityTitle,
			key: constants.universityTitle,
			align: 'center',
			width: 10,
			dataIndex: 'university',
		},
		{
			title: <Tooltip title={constants.roleTitle}>{constants.roleAbbr}</Tooltip>,
			key: constants.roleTitle,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<span>{record.occupation
						== 'S' ? <Tooltip title={constants.studentTitle}><span>S</span></Tooltip> : record.occupation
							== 'P' ? <Tooltip title={constants.professionalTitle}><span>P</span></Tooltip> : record.occupation
								== 'F' ? <Tooltip title={constants.facultyTitle}><span>F</span></Tooltip> : record.occupation
									== '0' ? <Tooltip title={constants.otherTitle}><span>O</span></Tooltip> : record.occupation
										== 'U' ? <Tooltip title={constants.undecidedTitle}><span>U</span></Tooltip>:''}</span>
				)
			}
		},
		{
			title: <Tooltip title='Attempt'>{constants.attemptAbbr}</Tooltip>,
			key: 'Attempt',
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<span>{record.scorecard_info
						!= 'tutorial' ? '-' : record.attempt }</span>
				)
			}
		},
		{
			title: constants.progress,
			key: constants.progress,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<div className='font-semibold text-sm cursor-pointer'>
						<Badge
							className={record.test_status == 3 ? 'pausedBadge' : 'completedBadge'}
							count={record.test_status == 3 ? constants.paused : constants.completed}
						/>
					</div>
				)
			}
		},
		{
			title: 'Date',
			key: 'Date',
			align: 'center',
			dataIndex: 'attempt_date',
			width: 10,
		},
		{
			title: <Tooltip title={constants.questions}>{constants.questionsAbbr}</Tooltip>,
			key: constants.questionsAbbr,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<div>{record.total_corr_ans == null ? 0 : record.total_corr_ans}/{record.total_questions}
					</div>
				)
			}
		},
		{
			title: constants.score,
			key: constants.score,
			align: 'center',
			width: 10,
			dataIndex: 'points',
		}
	]

	const getFlashcardInfoAndRedirect = (record, index) => {
		let studentDetails = {};
		studentDetails.studentId = record.stud_id;
		sessionStorage.setItem("studentFromFlashcardResultPage", JSON.stringify(studentDetails));
		window.open(constants.BaseUrl+'/view-student');
	}

	const flashcardResultTableColumns = [
		{
			title: <Tooltip title='Chapter Id' className='cursor-default'>Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex:'chapter_id',
			align: 'center',
			width: 10,
		},
		{
			title: constants.studentName,
			key: constants.studentName,
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					usertype == 1 ?
						<div><span>{record?.name?.charAt(0)?.toUpperCase() + '.' + ' ' + record?.name?.slice(-1) + '.'}</span><span className='ml-2'></span></div>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getFlashcardInfoAndRedirect(record, index)}>{record.name}</div>
				)
			},
		},
		{
			title: <Tooltip title={constants.countryTitle} className='cursor-default'>{constants.countryAbbr}</Tooltip>,
			key: constants.countryTitle,
			align: 'center',
			dataIndex: 'country',
			width: 10,
		},
		{
			title: <Tooltip title={constants.stateTitle} className='cursor-default'>{constants.stateAbbr}</Tooltip>,
			key: constants.stateTitle,
			align: 'center',
			dataIndex: 'state',
			width: 10,
		},
		{
			title: constants.universityTitle,
			key: constants.universityTitle,
			align: 'center',
			width: 10,
			dataIndex:'university',
		},
		{
			title: <Tooltip title={constants.roleTitle} className='cursor-default'>{constants.roleAbbr}</Tooltip>,
			key: constants.roleTitle,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<span>{record.occupation
						== 'S' ? <Tooltip title={constants.studentTitle}><span>S</span></Tooltip> : record.occupation
							== 'P' ? <Tooltip title={constants.professionalTitle}><span>P</span></Tooltip> : record.occupation
								== 'F' ? <Tooltip title={constants.facultyTitle}><span>F</span></Tooltip> : record.occupation
									== '0' ? <Tooltip title={constants.otherTitle}><span>O</span></Tooltip> : record.occupation
										== 'U' ? <Tooltip title={constants.undecidedTitle}><span>U</span></Tooltip> : ''}</span>
				)
			}
		},
		{
			title: constants.progress,
			key: constants.progress,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					<div className='font-semibold text-sm cursor-pointer'>
						<Badge
							className={record.test_status == 3 ? 'pausedBadge' : 'completedBadge'}
							count={record.test_status == 3 ? constants.paused : constants.completed}
						/>
					</div>
				)
			}
		},
		{
			title: <Tooltip title='Flashcard Completed Date' className='cursor-default'>Date</Tooltip>,
			key: 'Date',
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					record.completed_date ? <div>{record.completed_date}</div> : <MinusOutlined />
				)
			}
		},
	]

	const videoImageResultTableColumns = [
		{
			title: constants.studentName,
			key: constants.studentName,
			render: (text, record) => {
				return (
					<div className='linkColor font-bold cursor-pointer' onClick={() => getFlashcardInfoAndRedirect(record, null)}><span>{record?.stud_firstname + ' ' + record?.stud_lastname?.charAt(0) + '.'}</span><span className='ml-2'></span></div>
				)
			},
		},
		{
			title: <span className='cursor-default'>{constants.countryTitle}</span>,
			key: constants.countryTitle,
			align: 'center',
			dataIndex: 'country_name',
		},
		{
			title: <span className='cursor-default'>{constants.stateTitle}</span>,
			key: constants.stateTitle,
			align: 'center',
			dataIndex: 'region_name',
		},
		{
			title: constants.universityTitle,
			key: constants.universityTitle,
			align: 'center',
			dataIndex:'university_name',
		},
		{
			title: <span className='cursor-default'>{constants.roleTitle}</span>,
			key: constants.roleTitle,
			align: 'center',
			dataIndex:'role'
		},
		{
			title: "Date",
			key: "dt_created",
			align: 'center',
			dataIndex:'dt_created',
			render:(dt_created)=> {
				return <span>{ dt_created ? dayjs(dt_created).format(constants.DateFormat): null }</span>
			}
		}
	];
	//Onchange set values of form fields
	const getCountryValue = (option,value) => {
		setCodes({ ...codes, country: value?.key})
		if (value?.value == '238'){
			setOptionalBlocks(true);
		}else{
			setOptionalBlocks(false);
		}
	}
	const getStatevalue = (option,values,e)=>{
		setCodes({ ...codes, state: values?.key})
		Object.entries(universities).forEach(([key, value]) => {
			if (key == values?.children) {
				let obj = {};
				obj[e] = value;
				setChangeduniversityDetails(obj);
			} else if (value === null) {
				setChangeduniversityDetails(null);
			}
		});
	}
	const getUniversityvalue = (option, value)=>{
		setCodes({ ...codes, university:value?.children})
	}
	const getRolevalue = (option, value)=>{
		setRoleCode(value?.key);
	}
	const getName = (e)=>{
		setName(e.target.value);
	}
	const getStartandEnddateForTest = (dates,dateString)=>{
		setStartDateForTest(dateString[0]);
		setEndDateForTest(dateString[1]);
	}
	const getStartandEnddateForTutorial = (dates, dateString) => {
		setStartDateForTutorial(dateString[0]);
		setEndDateForTutorial(dateString[1]);
	}
	const getStartandEnddateForFlashcard = (dates, dateString) => {
		setStartDateForFlashcard(dateString[0]);
		setEndDateForFlashcard(dateString[1]);
	}
	const getStartandEnddateForVideo = (dates, dateString) => {
		setStartDateForVideo(dateString[0]);
		setEndDateForVideo(dateString[1]);
	}
	const getStartandEnddateForImage = (dates, dateString) => {
		setStartDateForImage(dateString[0]);
		setEndDateForImage(dateString[1]);
	}
	const getStartandEnddateForStudyGuide = (dates, dateString) => {
		setStartDateForStudyGuide(dateString[0]);
		setEndDateForStudyGuide(dateString[1]);
	}
	const getResourceStatus = (option, value)=>{
		// console.log(option, value);
	}
	const getChaptervalue = (option, value)=>{
		// console.log(option, value);
	}

	const filterOption = (input, option) =>
		(option?.children.toLowerCase().includes(input.toLowerCase()));

	const getResourceResult = (url) => {
		setLoader(true);
		fetchAdminApi(url, "get").then(
			(res) => {
				if (res?.data) {
					setLoader(false);
					resourcetype == 0 ? setTestData(res?.data) : resourcetype == 6 ? setFlashcardData(res?.data) : resourcetype == 3 ? setImageData(res?.data) : resourcetype == 2 ? setVideoData(res?.data): resourcetype == 4 ? setStudyGuideData(res?.data) : setTutorialData(res?.data);
					resourcetype == 0 ? setTotalTestResultCount(res?.TOTAL_COUNT[0].count) : resourcetype == 3 ? setImageResultCount(res?.data?.length) :  resourcetype == 2 ? setVideoResultCount(res?.data?.length) : resourcetype == 4 ? setStudyGuideResultCount(res?.data?.length) : resourcetype == 6 ? setTotalFlashcardResultCount(res?.TOTAL_COUNT[0].count):setTotalTutorialResultCount(res?.TOTAL_COUNT[0].count);
				} else {
					setLoader(false);
					resourcetype == 0 ? setTestData([]) : resourcetype == 6 ? setFlashcardData([]): resourcetype == 4 ? setStudyGuideData([]): resourcetype == 2 ? setVideoData([]) : setTutorialData([]);
					resourcetype == 0 ? setTotalTestResultCount(0) : resourcetype == 6 ? setTotalFlashcardResultCount(0): resourcetype == 4 ? setStudyGuideResultCount(0) : resourcetype == 2 ? setVideoResultCount(0) :setTotalTutorialResultCount(0);
				}
			})
	}

	useEffect(()=>{
		getUnitedStatesAtTheTop();
	}, [countries])

	const getUnitedStatesAtTheTop = ()=>{
		countries.forEach(function (country, i) {
			if (country.country_id== "238") {
				countries.splice(i, 1);
				countries.unshift(country);
				return countries;
			}
		});
	}
	const displaySearchCriteria = (values) => {
		
		let searchCriteriaArr = [];
		if (values) {
			if (values.role) {
				let roleName = "";
				if (values.role === "0") {
					roleName = 'Student'
				} else if (values.role === "1") {
					roleName = 'Professional'
				} else if (values.role === "2") {
					roleName = 'Faculty'
				} else if (values.role === "3") {
					roleName = 'Other'
				} else if (values.role === "4") {
					roleName = 'Unspecified'
				}
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>{constants.roleTitle} : <span className="pr-2 text-green-700">{roleName}</span></span></>)
			}
			if (values.university) {
				let universtyName = '';
				const selectUni = Object.entries(universities).map(([key, value]) => (
					value &&
					value.universities &&
					value.universities.length > 0 &&
					value.universities.filter((universe) => (universe.university_id == values.university))
				)
				)
				if (selectUni.length > 0) {
					const selectedUniversity = selectUni.filter((obj) => (obj[0]))[0];
					universtyName = selectedUniversity[0].university_name;
				}
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>{constants.universityTitle} : <span className="pr-2 text-green-700">{universtyName}</span></span></>)
			}
			if (values.country) {
				let countryName = "";
				countries.map((country) => {
					if (country?.country_id === values.country) {
						countryName = country.country_name;
					}
				});
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>{constants.countryTitle} : <span className="pr-2 text-green-700">{countryName}</span></span></>)
			}
			if (values.state) {
				let regionName = "";
				states.map((state) => {
					if (state?.region_id === values.state) {
						regionName = state.region_name;
					}
				});
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>{constants.stateTitle} : <span className="pr-2 text-green-700">{regionName}</span></span></>)
			}
			if (values.name) {
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>{constants.studentName} : <span className="pr-2 text-green-700">{values.name}</span></span></>)
			}
			if (values.resourceStatus) {
				let resourceStatus = "";
				values.resourceStatus == '3' ? resourceStatus = constants.paused : resourceStatus = constants.completed;
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Status : <span className="pr-2 text-green-700">{resourceStatus}</span></span></>)
			}
			if (values.period) {
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Period : <span className="pr-2 text-green-700">{`${resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : resourcetype == 2 ? startDateForVideo : resourcetype == 3 ? startDateForImage : resourcetype == 4 ? startDateForstudyGuide: startdatefortutorial} - ${resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : resourcetype == 2 ? endDateforVideo : resourcetype == 3 ? endDateforImage : resourcetype == 4 ? endDateforstudyGuide :enddatefortutorial}`}</span></span></>)
			}
			if (values.chapter) {
				let chapterName = "";
				let modifiedChaptername = '';
				chapters.map((chap) => {
					if (chap?.chapter_id === values.chapter) {
						chapterName = chap.chapter;
						modifiedChaptername = chapterName.substr(chapterName.indexOf(" ") + 1);
						// console.log(modifiedChaptername);
					}
				});
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Chapter : <span className="pr-2 text-green-700">{modifiedChaptername}</span></span></>)
			}
			if (videowiseObject) {
				searchCriteriaArr.push(<>
					<span className='text-red-700 pl-2'>{constants.Video} : <span className="pr-2 text-green-700">{videowiseObject?.title}</span></span>
					<span className='text-red-700 pl-2'>{constants.Category} : <span className="pr-2 text-green-700">{videowiseObject?.category}</span></span>
				</>)
			}
			if (imagewiseObject) {
				searchCriteriaArr.push(<>
					<span className='text-red-700 pl-2'>{constants.ImageSelect} : <span className="pr-2 text-green-700">{imagewiseObject?.title}</span></span>
				</>)
			}
			return searchCriteriaArr;
		}
	};
	const submitFormValues = (values)=>{
		let url = resourcetype == 2 ? `/get-student-resource-list?resource_file_id=${videowiseObject?.resource_file_id}`: resourcetype == 3 ? `/get-student-resource-list?resource_file_id=${imagewiseObject?.resource_file_id}` : resourcetype == 4 ? `/get-student-resource-list?${studyGuidewiseObject?.isTotal ? `total_study_guide=0`:`resource_file_id=${studyGuidewiseObject?.resource_file_id}`}` : resourcetype != 6 ? `/test-results?type=${resourcetype}` : `/flashcard-results?`;
		if(values){
			if(values.country){
				url +=`&country_id=${values.country}`;
			}
			if (values.state) {
				url += `&state_id=${values.state}`;
			}
			if (values.name) {
				url += `&name=${values.name}`;
			}
			if (values.role) {
				url += `&occupation=${values.role}`;
			}
			if (values.chapter) {
				url += `&chapter_id=${values.chapter}`;
			}
			if (values.university) {
				url += `&university_id=${isNaN(values.university) == true ? null : values.university}`;
			}
			if (values.resourceStatus) {
				url += `&test_status=${values.resourceStatus}`;
			}
			if (values.period) {
				url += `&start_date=${changeDateFormat(resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : resourcetype == 2 ? startDateForVideo : resourcetype == 3 ? startDateForImage: resourcetype == 4 ? startDateForstudyGuide: startdatefortutorial)}&end_date=${changeDateFormat(resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : resourcetype == 2 ? endDateforVideo : resourcetype == 3 ? endDateforImage :resourcetype == 4 ? endDateforstudyGuide : enddatefortutorial)}`;
			}
		}
		getResourceResult(url);
		setFormSubmitted(true);
		setFormvalues(values);
	}
	const resetFormFields = () => {
		form.resetFields();
	}

	return (
		<div className='cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title={resourcetype == 0 ? constants.testData : resourcetype == 2 ? constants.VideoData : resourcetype == 3 ? constants.ImageData : resourcetype == 4 ? constants.StudyGuideData : resourcetype == 6 ? constants.flashcardData : constants.tutorialData} className="addQcard" hoverable 
							extra={
								<FloatButton
									type="primary"
									className='float-searchBtn'
									onClick={setToggle}
									icon={<SearchOutlined />}
									size="small"
									tooltip={<div>{constants.floatBtnTooltipTitle}</div>}
									style={{
										top: 180,
										right: 50,
									}}
								> Toggle State
								</FloatButton>
							}
						>
							{toggle && (
								<Card className='mb-4 neomorphicShadow' hoverable>
									<Form className='filterForm countriesByregistrationFilters' onFinish={submitFormValues} form={form} layout={'vertical'}>
										<Row gutter={24}>
											<Col span={8}>
												<Form.Item label='Country' name='country'>
													<Select className='rounded-md' placeholder="Country" showSearch filterOption={filterOption} allowClear onChange={getCountryValue}>
														{countries.map((country, index) => (
															<Select.Option key={country.country_code} value={country.country_id}>
																{country.country_name}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											{optionalblocks && 
											(
												<>
											<Col span={8}>
												<Form.Item label='State' name='state'>
													<Select className='rounded-md mr-2' placeholder="State" onChange={getStatevalue} showSearch filterOption={filterOption} allowClear>
														{states.map((state) => (
															<Select.Option key={state.region_code} value={state.region_id}>
																{state.region_name}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label='University' name='university'>
													<Select onChange={getUniversityvalue} placeholder="University" showSearch filterOption={filterOption} allowClear>
														{changeduniversitydetails
															?
															Object.entries(changeduniversitydetails).map(([key, value]) => (
																<>
																	{value &&
																		value.universities &&
																		value.universities.length > 0 &&
																		value.universities.map((universe) => (
																			<Option
																				key={universe.university_id}
																				value={universe.university_id}
																				universityN={universe.university_name}
																				region={universe.region_id}
																			>
																				{universe.university_name + (universe.city ? "," + universe.city : "")}
																			</Option>
																		))}
																</>
															))
															:
															universities &&
															Object.entries(universities).map(([key, value]) => (
																<>
																	{value &&
																		value.universities &&
																		value.universities.length > 0 &&
																		value.universities.map((universe) => (
																			<Option
																				key={universe.university_id}
																				value={universe.university_id}
																				universityN={universe.university_name}
																				region={universe.region_id}
																			>
																				{universe.university_name + (universe.city ? "," + universe.city : "")}
																			</Option>
																		))}
																</>
															))
														}
													</Select>
												</Form.Item>
											</Col>
												</>
											)
											}
											<Col span={8}>
												<Form.Item label='Name' name='name'>
													<Search placeholder="Name" onChange={getName} />
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label='Role' name='role'>
													<Select className='rounded-md mr-2' placeholder="Role" onChange={getRolevalue}>
														{roles.map((role) => (
															<Select.Option key={role.status} value={role.value}>
																{role.status}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label='Period' name='period'>
													<RangePicker
														className='w-full'
														format={dateFormat}
														//value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														onChange={resourcetype == 0 ? getStartandEnddateForTest : resourcetype == 6 ? getStartandEnddateForFlashcard: resourcetype ==2 ? getStartandEnddateForVideo: resourcetype ==3 ? getStartandEnddateForImage : resourcetype ==4 ? getStartandEnddateForStudyGuide: getStartandEnddateForTutorial}
														changeOnBlur
														allowClear={false}
													/>
												</Form.Item>
											</Col>
											{
												resourcetype != 2 && resourcetype != 3 && resourcetype != 4 && (
													<>
														<Col span={8}>
															<Form.Item label='Result' name='resourceStatus'>
																<Select onChange={getResourceStatus} placeholder="Result">
																	<Select.Option key='3' value={'3'}>{constants.paused}</Select.Option>
																	<Select.Option key='4' value={'4'}>{constants.completed}</Select.Option>
																</Select>
															</Form.Item>
														</Col>

														<Col span={8}>
															<Form.Item label='Chapter' name='chapter'>
																<Select onChange={getChaptervalue} placeholder="Chapter">
																	{chapters.map((chapter) => (
																		<Select.Option key={chapter.chapter} value={chapter.chapter_id}>
																			{chapter.chapter}
																		</Select.Option>
																	))}
																</Select>
															</Form.Item>
														</Col>
													</>
												)
											}
											<Col span={24}>
												<Form.Item className='relative'>
													<div style={{ position: 'absolute', right: '0px' }}>
														<Button htmlType='submit' type='primary' className='font-semibold text-sm'>{constants.searchBtnTitle}</Button>
														<Button type='primary' className='font-semibold text-sm ml-3' onClick={resetFormFields}>{constants.resetBtnTitle}</Button>
													</div>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								</Card>
							)}
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card className="mb-3">
										<div className="font-bold text-base">
											<span className="font-bold text-base">{constants.searchCriteriaTitle}</span>
											{(formSubmitted || formdisplay) && formvalues
												?
												displaySearchCriteria(formvalues)
												:
												''
											}
										</div>
									</Card>
									<div className='font-bold mt-1 mb-3 text-base pl-4'>{resourcetype == 0 ? testresultcount : resourcetype == 2 ? videoResultCount : resourcetype == 3 ? imageResultCount : resourcetype == 4 ? studyGuideResultCount  : resourcetype == 6 ? flashcardresultcount :  tutorialresultcount} <span className="pl-1">{constants.recordCount}</span></div>
									<Spin spinning={loader} size="large" className='z-30'>
										<Table
											bordered
											dataSource={resourcetype == 0 ? testdata : resourcetype == 2 ? videoData : resourcetype == 3 ? imageData : resourcetype == 4 ? studyGuideData : resourcetype == 6 ? flashcarddata :   tutorialdata}
											columns={resourcetype == 0 ? testResultTableColumns : resourcetype == 2 || resourcetype == 3 || resourcetype == 4? videoImageResultTableColumns : resourcetype == 6 ? flashcardResultTableColumns  : tutorialResultTableColumns}
											className='registrationTable'
											locale={{ emptyText: constants.emptyTableText }}
											rowKey={(record) => record.month_in_words}
											title={() => { return <div className="font-bold text-base text-white uppercase">{resourcetype == 0 ? constants.testResultTableTitle : resourcetype == 6 ? constants.flashcardResultTableTitle : resourcetype == 2 ? constants.VideoResultTableTitle : resourcetype == 3 ? constants.ImageResultTableTitle : resourcetype == 4 ? constants.StudyGuideDownloadResults: constants.tutorialResultTableTitle}</div> }}
										>
										</Table>
									</Spin>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default ResourceResult
