import React, { useState, useEffect } from 'react';
import constants from '../../../../constants/constants';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Spin,
	Tooltip
} from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getCustomisedBreadcrumbs, getStartDateAndEndDate } from '../../../../functions/functions';
import { fetchAdminApi } from '../../../../services/api';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const TotalMediaResourcesTaken = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const [loader, setLoader] = useState(false);
	const resourceType = location.state?.resourceType || sessionStorage.getItem("selectedSection");
	const [tableData, setTableData] = useState([]);
	const [startDate, setStartDate] = useState(dayjs().startOf('year'));
	const [endDate, setEndDate] = useState(dayjs().endOf('year'));
	const [period, setPeriod] = useState(0);
	const [category, setCategory] = useState(null);
	const [chapter, setChapter] = useState(null)
	const [chapters,setChapters] = useState([]);
	const [totalViews, setTotalViews] = useState(0);
	const videoColumn = [
		
		{
			title: <span className='ml-2'>{constants.Video}</span>,
			key: 'video',
			dataIndex: 'title',
			ellipse: true,
			width: "50%",
			sorter: (a, b) => a?.title?.localeCompare(b?.title),
			render: (title) => {
				return (
					<span className='ml-2'>{title}</span>
				)
			},
		},
		{
			title: <span className='ml-2'>{constants.Category}</span>,
			key: "category",
			dataIndex: "category",
			width: "40%",
			sorter: (a, b) => a?.category?.localeCompare(b?.category),
			render: (category) => {
				return (
					<span className='ml-2'>{category}</span>
				)
			},
		},
		{
			title: <span className='pr-4'>{constants.Views}</span>,
			key: "views",
			dataIndex: "views",
			width: "10%",	
			align:"right",
			sorter: (a, b) => a.views - b.views,
			defaultSortOrder: 'descend',
			render: (views, record) => <div className='linkColor font-semibold text-sm cursor-pointer text-right pr-4' onClick={() => redirectToResouseResult(record)}>
				{views}
			</div>
		},
	];

	const imageColumn = [
		{
			title: <Tooltip title='Chapter Id'><span className='mr-4'>Ch#</span></Tooltip>,
			key: 'Chapter',
			dataIndex:'chapter_id',
			width: "5%",
			align:"right",
			sorter: (a, b) => a.chapter_id - b.chapter_id,
			render: (chapter_id) => <div className='text-right pr-8'>{chapter_id}</div>
		},
		{
			title: <span className='ml-2'>{constants.ChapterName}</span>,
			key: "chapter_title",
			dataIndex: "chapter_title",
			width: "40%",
			sorter: (a, b) => a?.chapter_title?.localeCompare(b?.chapter_title),
			render: (chapter_title) => {
				return (
					<span className='ml-2'>{chapter_title}</span>
				)
			},
		},
		{
			title: <span className='ml-2'>{constants.ImageSelect}</span>,
			key: "title",
			dataIndex: "title",
			width: "40%",
			sorter: (a, b) => a?.title?.localeCompare(b?.title),
			render: (title) => {
				return (
					<span className='ml-2'>{title}</span>
				)
			},
		},
		{
			title: <span className='pr-4'>{constants.Views}</span>,
			key: "views",
			dataIndex: "views",
			width: "15%",
			align:"right",
			sorter: (a, b) => a.views - b.views,
			defaultSortOrder: 'descend',
			render: (views, record) => <div className='linkColor font-semibold text-sm cursor-pointer text-right pr-4' onClick={() => redirectToResouseResult(record)}>
				{views}
			</div>
		},
	];

	const getResouseTypeLayoutData = (type) => {
		if(type == 3){
			let imageData = {
				newCrumb : [<Link key={2} to="/total-image-views" className="!text-black font-semibold">{constants.TotalImageViews}</Link>],
				cardTitle : constants.TotalImageViews,
				tableTitle: constants.ImageViews,
				filterItem: <>
					<Form.Item label='Chapter' name='chapter'>
						<Select
							onChange={(e) => getChaptervalue(e)}
							placeholder="Chapter"
							className='w-full rounded-md'
							allowClear
							value={chapter}
						>
							{chapters.map((chapter) => (
								<Select.Option key={chapter.chapter} value={chapter.chapter_id}>
									{chapter.chapter}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</>,
				columns:imageColumn
			}
			return imageData;
		}else{
			let videoData = {
				newCrumb : [<Link key={2} to="/total-video-views" className="!text-black font-semibold">{constants.TotalVideoViews}</Link>],
				cardTitle : constants.TotalVideoViews,
				tableTitle: constants.VideoViews,
				filterItem: <>
					<Form.Item label={constants.Category}>
						<Select
							placeholder={constants.Category}
							className='w-full rounded-md'
							onChange={(e)=>onChangeVideoCategory(e)}
							allowClear
							value={category}
						>
							{constants.VideoCategoryList.map((option) => (
								<Select.Option key={option.id} value={option.id}>
									{option.title}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</>,
				columns:videoColumn
			}
			return videoData;
		}
	};

	const resourceData = getResouseTypeLayoutData(resourceType);

	const getChaptervalue = (value)=>{
		setChapter(value);
	};

	const redirectToResouseResult = (recordData) => {
		let startD = dayjs(startDate).format(constants.DateFormat);
		let endD = dayjs(endDate).format(constants.DateFormat);
		recordData.sd = startD;
		recordData.ed = endD;
		if(resourceType==2){
			navigate('/video-result', { state: { videowiseObject: recordData } });
		}else{
			navigate('/image-result', { state: { imagewiseObject: recordData } });
		}
	};

	const getTotalImageVideoViews = (start_date,end_date,resourceType) => {
		setLoader(true);
		let url = `/get-resource-list/${resourceType}?start_date=${dayjs(start_date).format("YYYY-MM-DD")}&end_date=${dayjs(end_date).format("YYYY-MM-DD")}`;
		if(category){
			url += `&category=${category}`;
		}
		if(chapter){
			url += `&chapter_id=${chapter}`;
		}
		fetchAdminApi(url, 'get').then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				setTableData(res.data);
				const totalViews = res?.data?.reduce((total, item) => total + Number(item.views), 0);
				setTotalViews(totalViews);
			} else {
				setLoader(false);
				setTableData([]);
			}
		})
	};

	const onChangeFromAndTo = (date) => {
		setPeriod(null);
		setStartDate(date[0]);
		setEndDate(date[1]);
	};

	const onChangePeiod = (value) => {
		setPeriod(value);
		let sted = getStartDateAndEndDate(value);
		setStartDate(sted?.startDate);
		setEndDate(sted?.endDate);
	};

	const onChangeVideoCategory = (category) => {
		setCategory(category);
	};

	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data) {
				setChapters(res?.data);
			} else {
				setChapters([]);
			}
		})
	};

	useEffect(() => {
		if(resourceType){
			sessionStorage.setItem("selectedSection", resourceType);
		}
		if(resourceType == 3){
			getAllTheChapters();
		}
	}, []);
	

	useEffect(() => {
		getTotalImageVideoViews(startDate,endDate,resourceType == 3 ? "1":"2");
	}, [startDate,endDate,category,chapter]);

	return (
		<Spin spinning={loader} size="large" className='z-30'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, resourceData?.newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title={resourceData?.cardTitle} className="addQcard" hoverable>
							<div>
								<Form form={form} className='countriesByregistrationFilters'>
									<Row gutter={12}>
										<Col span={8}>
											<Form.Item label="From">
												<RangePicker
													className='w-full'
													format={constants.DateFormat}
													onChange={onChangeFromAndTo}
													value={[startDate , endDate]}
													allowClear={false}
												/>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item label={constants.Period}>
												<Select
													placeholder={constants.Period}
													className='w-full rounded-md'
													value={period}
													onChange={onChangePeiod}
												>
													{constants.periodOptions.map((option, index) => (
														<Select.Option key={option} value={index}>
															{option}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col span={8}>
											{resourceData?.filterItem}
										</Col>
									</Row>
								</Form>
							</div>
							<Row>
							<div className='font-bold mt-1 mb-3 text-base pl-4'> <span className="pl-1">{constants.TotalViews}: {totalViews}</span></div>
								<Col span={24}>
									<Table
										bordered
										dataSource={tableData}
										columns={resourceData?.columns}
										className='registrationTable'
										title={() => { return <div className="font-bold text-base text-white uppercase">{resourceData?.tableTitle}</div> }}
										size="small"
										locale={{ emptyText: constants.emptyTableText }}
										pagination={false}
									/>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</Spin>
	)
}

export default TotalMediaResourcesTaken