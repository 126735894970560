import { HistoryOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
const constants = {
	emptyTableText:'No Records Found',
	null_chapter_question:'Select chapter and question type',
	null_data_msg_flashcard:'Please select chapter',
	question_added_success:'Question added successfully',
	question_edited_success: 'Question edited successfully',
	flashcard_added_success: 'Flashcard added successfully',
	flashcard_edited_success: 'Flashcard edited successfully',
	image_added_success:'Image added successfully',
	image_edited_success: 'Image edited successfully',
	studyGuide_upload_success: 'Study Guide uploaded successfully',
	studyGuide_upload_failure: 'Study Guide could not be uploaded.Please try again.',
	studyGuide_select_success: 'Study Guide selected successfully',
	question_added_failure:'Question could not be added! Please fill all the required fields.',
	question_added_image_failure: 'Question could not be added! Please check the format of uploaded image file.',
	flashcard_added_failure: 'Flashcard could not be added! Please fill all the required fields.',
	image_added_failure: 'Image could not be added! Please fill all the required fields.',
	dataRequired: 'This field is mandatory',
	image_loading_error: 'Image updation failed!(only png,jpg,jpeg files are allowed to be uploaded)',
	recordCount: 'record(s) found.',
	incorrect_label_format:'Incorrect format!Only four numbers separated by comma can be entered.',
	mandatoryField: 'This field is mandatory',
	selectOneoptionText: 'Options (select only one answer)',
	selectcorrectOption: 'Please select the correct answer!',
	chooseImage: 'Click or drag image to this area to upload',
	imageTypeSelection: 'You can only upload JPG/PNG file!',
	feedbackSentSuccess: 'Thank you for your valuable feedback.',
	issueInfoSentSucess:'Thank you for informing us.',
	periodOptions:['Current Year','Last Year','Current Month','Last Month','Last 3 Months','Current Month (Last Year)','Last 3 months (Last Year)','Previous Years'],
	roleOptions: ['Student','Professional','Faculty','Other'],
	roleadminOptions: ['Student', 'Professional', 'Faculty', 'Other','Unspecified'],
	atleastoneFieldRequiredError:'Please answer at least one question',
	//Change Password
	passwordChangeSucess:'Password changed sucessfully!',
	duplicatedPassword:'New password is same as old password',
	duplicatedPasswordError:'New password cannot be same as your current password!',
	confirmedPasswordError:'Password should be same as above password',
	invalidPassword:'Invalid password!',

	//Registrations
	top10UsStates:'US state registrations from',
	//API environment constants
	ApiUrl: process.env.REACT_APP_API_URL,
	AdminApiUrl: process.env.REACT_APP_API_ADMIN_URL,
	BaseUrl: process.env.REACT_APP_URLS_FOR_BASE,
	EnviromentName: process.env.REACT_APP_ENV,
	StagingWebsiteURL: "https://plural.qapreview.com/publications/fundamentals-of-anatomy-and-physiology-of-speech-language-and-hearing",
	LiveWebsiteURL: "https://www.pluralpublishing.com/publications/fundamentals-of-anatomy-and-physiology-of-speech-language-and-hearing",
	projectName: 'FAPSLH',
	ReactQuillModules: {
		toolbar: [
			['bold', 'italic', 'underline'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' },],
		],
		 keyboard: {
		 	bindings: {
		 		tab: {
		 			key: 9,
		 			handler: function (range, context) {
		 				return true;
		 			},
		 		},
		 	},
		 }
	},
	ReactQuillFormats: [
		'header',
		'bold', 'italic', 'underline', 
		'list', 'bullet'
	],
	questionPagecrumbs: [<Link key={0} to= '/' className="font-semibold">Home</Link>, <Link key={1} to= '/master-menus'className="font-semibold">Masters</Link>, <Link key={2} to='/question-list' className="font-semibold">Questions</Link>],
	flashCardpageCrumbs: [<Link key={0} to= '/' className="font-semibold">Home</Link>, <Link key={1} to= '/master-menus'className="font-semibold">Masters</Link>],
	analysisPageCrumbs: [<Link key={0} to="/" className="font-semibold">Home</Link>, <Link key={1} to="/analysis-menus" className="font-semibold">Analysis</Link>],
	feedbackPageCrumbs: [<Link key={0} to="/" className="font-semibold">Home</Link>, <Link key={1} to='/feedback-menus' className="font-semibold">Feedbacks</Link>],
	homePageCrumbs: [<Link key={0} to= '/' className="font-semibold">Home</Link>],
	MyProgressNullMsg: [
		<span key={0}>You have not attempted any tests, tutorials or flashcards. </span>,
		<span key={1}>View the resources in each Chapter below.</span>
	],
	DashboardChaptHeader: "Click to view Chapter Resources",
	alternateEmailText:'Provide an alternate email address. If you do not receive any emails from pluralpublishing.com, your alternate email address will be used to contact you.',
	passwordValidationInfo:'Password length should be minimum 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, a special character and no spaces. Special characters to be used  should be any of these (#?!@$%&*-) .',
	//label
	MyNotes: "My Notes",
	Feedback: "Feedback",
	BckDashbd:"Back to Dashboard",
	BckVideos:"Back to Videos",
	DownldStdyGuide:"Download Study Guide",
	Shuffle:"Shuffle",
	Bookmark:"Bookmark",
	Flip:"Flip",
	Previous:"Previous",
	Next:"Next",
	Close:"Close",
	AutoPlay:"Auto play",
	Stop:"Stop",
	Save:"Save",
	Submit:"Submit",
	Images:"Images",
	Flashcards:"Flashcards",
	MarkForLater:"Mark To Study Later",
	TestYourSelf:"Test Yourself",
	Tutorials:"Tutorials",
	Tests:"Tests",
	Instructions:"Instructions",
	TakeTutorial:"Take a tutorial",
	Result:"Result",
	SubmitTest:"Submit Test",
	Correct:"Correct",
	Incorrect:"Incorrect",
	ReportanIssue:'Report an Issue',
	Area:'Area',
	chapters:'Chapters',
	ResourceOptions: ['Flashcards', 'Images', 'Study Guides', 'Tests', 'Tutorials'],
	feedbackVerticalAxisLabel:'How did the students hear about the book?',
	feedbackHorizontalAxisLabel: 'No. of Feedbacks',
	dashboardChartTitle:'Feedback Chart (Current year)',
	dashboardCardSectionTitle:'STATISTICS',
	dashboardChartSectionTitle: 'FEEDBACK CHART',
	loadingChartMessage:'Loading Chart...',
	emptyChartDataMessage:'NO DATA TO DISPLAY',
	monthAndYear:'Month / Year',
	numberOfTests:'No. of Tests',
	numberOfFlashcards:'No. of Flashcards',
	numberOfTutorials:'No. of Tutorials',
	searchCriteriaTitle:'SEARCH CRITERIA - ',
	totalTestsTaken:'TOTAL TESTS TAKEN',
	testsTaken:'TESTS TAKEN',
	totalTutorialsTaken:'TOTAL TUTORIALS TAKEN',
	tutorialsTaken:'TUTORIALS TAKEN',
	totalFlashcardsTaken:'TOTAL FLASHCARDS TAKEN',
	totalTestsTakenBreadcrumbLink:'Total Tests Taken',
	totalTutorialsTakenBreadcrumbLink: 'Total Tutorials Taken',
	testsTakenBreadcrumbLink: 'Tests Taken',
	tutorialsTakenBreadcrumbLink: 'Tutorials Taken',
	totalFlashcardsTakenBreadcrumbLink: 'Total Flashcards Taken',
	markedForlaterAbbr:'MFL',
	incorrectAnswerAbbr:'I/C',
	attempt1Abbr:'A#1',
	attempt2Abbr: 'A#2',
	attempt3Abbr: 'A#3',
	chapterTitle:'Title',
	testsTakenChartColorArr: ['#00B4D8', '#90E0EF', '#CAF0F8'],
	tutorialsTakenChartColorArr: ['#780000', '#A72231', '#BF334A', '#B6666F'],
	attemptOne:'Attempt 1',
	attemptTwo:'Attempt 2',
	attemptThree: 'Attempt 3',
	total:'Total',
	countryTitle:'Country',
	stateTitle:'State',
	roleTitle:'Role',
	universityTitle:'University',
	countryAbbr:'C',
	stateAbbr:'S',
	roleAbbr:'R',
	attemptAbbr:'A',
	progress:'Progress',
	score:'Score',
	studentName:'Name',
	questions:'Questions',
	questionsAbbr:'Qns',
	searchBtnTitle:'SEARCH',
	resetBtnTitle:'RESET',
	testResultTableTitle:'TEST RESULTS',
	flashcardResultTableTitle:'FLASHCARD RESULTS',
	tutorialResultTableTitle:'TUTORIAL RESULTS',
	VideoResultTableTitle:'Video Results',
	ImageResultTableTitle:'Image Results',
	floatBtnTooltipTitle:'Click to search registered students using various parameters',
	testData:'TEST DATA',
	tutorialData:'TUTORIAL DATA',
	flashcardData:'FLASHCARD DATA',
	VideoData:'VIDEO DATA',
	StudyGuideData:'STUDY GUIDE DATA',
	ImageData:'IMAGE DATA',
	paused:'Paused',
	completed:'Completed',
	studentTitle:'Student',
	professionalTitle:'Professional',
	facultyTitle:'Faculty',
	otherTitle:'Other',
	undecidedTitle:'Undecided',
	studyGuide: 'Study Guide',
	totalImgCount:'Total Images Count',
	viewedImgCount:'Viewed Images Count',
	viewed:'Viewed',
	flashcardCompletedDate:'Flashcard Completed Date',
	pausedFlashcardCount:'Paused Flashcard Count',
	markedForLater: 'Marked For Later',
	incorrectAnswers:'Incorrect Answers',
	viewStudent:'View Student',
	positiveColor:'positiveColor',
	negativeColor:'negativeColor',
	imageAbbr:'Img',
	flashcardAbbr:'Flash',
	viewStudentUppercase:'VIEW STUDENT',
	EditStudent:'Edit Student',
	firstNameUppercase:'FIRST NAME',
	lastNameUppercase: 'LAST NAME',
	roleUppercase:'ROLE',
	countryUppercase:'COUNTRY',
	stateUppercase:'STATE',
	universityUppercase:'UNIVERSITY',
	primaryEmailUppercase:'PRIMARY EMAIL',
	alternateEmailUppercase:'ALTERNATE EMAIL',
	contactUppercase:'CONTACT NUMBER',
	registeredOn:'REGISTERED ON',
	//Flashcard
	SecondsPerSlide:10000,
	MoveSlidesBy:10,
	SecondsPerSlideNum:10,	
	// Feedback
	FeedbackMessageError: "Enter a message",
	FeedbackHeader: "SEND US FEEDBACK",
	FeedbackTitle: "We value your feedback",
	FeedbackMessage: "to provide feedback to help us improve the product.",
	FeedbackQ1: "Q1) Is this book",
	FeedbackQ2: "Q2) How did you hear about the book?",
	FeedbackQ3: "Q3) The tests were challenging",
	FeedbackQ4: "Q4) Overall rating of the product",
	FeedbackSucess: "Thank you for your feedback.",
	FeedbackOptionError: "Choose an option",
	BookReq1: "Required for a course",
	BookReq2: "Recommended for a course",
	HearAbtBookOption1: "Through a friend/colleague",
	HearAbtBookOption2: "Social media",
	HearAbtBookOption3: "ASHA convention",
	HearAbtBookOption4: "State convention",
	HearAbtBookOption5: "Amazon",
	HearAbtBookOption6: "Internet",
	HearAbtBookOption7: "Other",
	HearAbtBookOption8: "Instructor",
	ChalTestOpt1:"Agree",
	ChalTestOpt2:"Undecided",
	ChalTestOpt3:"Disagree",
	RatingOpt1:"Excellent",
	RatingOpt2:"Very Good",
	RatingOpt3:"Good",
	RatingOpt4:"Fair",
	FeedbackFormHeader: 'YOUR FEEDBACK ABOUT THE RESOURCES FOR CHAPTER',
	FeedbackQuestion1:'Q1) The Study Guide was',
	FeedbackQuestion2:'Q2) The Images were',
	FeedbackQuestion3:'Q3) The Flashcards were',
	FeedbackQuestion4:'Q4) The Tutorials were',
	FeedbackQuestion5:'Q5) The Tests were',
	RatingOption3:'Helpful',
	RatingOption4:'Unhelpful',
	EmptyCommentError:'Please enter comment',
	Comment:'Comments',
	AreaPlaceholder:'Select an area',
	//Update profile
	requiredContactNumber:'Please enter your contact number',
	requiredCountry:'Please enter your country',
	requiredRole: 'Please enter your role',
	requiredUniversity:'Please enter your university',
	requiredState:'Please enter your state',
	requiredOccupation:'Please enter your occupation',
	sucessfulProfileupdation:'Profile updated successfully!',
	profileUpdationError:'Profile could not be updated!Please fill all the fields.',
	duplicateIdError: 'Alternate email cannot be same as primary email!',
	//end feedback
	ColorScore1:"#2F8548",
	ColorScore2:"#0F455D",
	ColorScore3:"#723E85",
	TotalMarksTutorial:10,
	TotalMarksTest:50,
	TotalMarksTestComplete:100,
	TotalFlashCards:50,
	mimimumCharactersPopup:30,
	BookName:"Fundamentals of Anatomy and Physiology of Speech, Language, and Hearing",
	Author:"Glen M. Tellis, M. Hunter Manasco",
	TestStatus1Text:"This test contains 50 questions and generally takes around 1 hour.",
	TutorailStatus1Text:"Take a tutorial with 10 questions to test yourself.",
	//gradient & color code for chapter 1, 5 and 9 
	GradientColorClassChp159 :'from-[#7F1D1D] to-rose-50',
	ColorCodeChp159 :'#7F1D1D',
	//color code for chapter 2, 6 and 10 
	GradientColorClass2610 :'from-[#155E75] to-sky-50',
	ColorCodeChp2610 :'#155E75',
	//color code for chapter 3, 7 and 11 
	GradientColorClass3711 :'from-[#6B21A8] to-green-50',
	ColorCodeChp3711 :'#6B21A8',
	//color code for chapter 4, 8 and 12
	GradientColorClass4812 :'from-[#15803D] to-green-50', 
	ColorCodeChp4812 :'#15803D',
	footerText:"Fundamentals of Anatomy and Physiology of Speech, Language, and Hearing: First Edition © 2023 Plural Publishing, Inc. All rights reserved.",
	//Graph colors
	CompareAttempt1Color:"#3366CC",
	CompareAttempt2Color:"#DC3912",
	CompareAttempt3Color:"#FF9900",
	// Labels
	Chapter:"Chapter",
	Username: "Username",
	LoginUserNameValid:"Enter Username",
	LoginPasswordValid:"Enter Password",
	Password: "Password", 
	LoginBTN: "Login",
	ResetBTN:'Reset Password',
	ChangePswBTN: 'Change Password',
	Logout:"Logout",
	LoginHeader: "Admin Login",
	ResetPasswordCardHeader:'Reset Password',
	Dashboard:"Dashboard",
	BackToChapter:"Back to Chapter",
	ReviewAnswers:"Review Answers",
	ReviewIncorrect:"Review Incorrect",
	ReviewTest:"Review All",
	ScoreComparison:"Test Score Comparison",
	NewPassword:'New password',
	ConfirmNewPassword:'Confirm new password',
	//Messages
	SelectOptionOnly:"Select an option.",
	SelectAllOptions:"Select answer for all labels.",
	SelectOptionOnlyTest:"Select an option or Mark the Question for later review.",
	SelectAllOptionsTest:"Select answer for all labels or Mark the Question for later review.",
	NoNotesCreated:"You have no notes created.",
	NoNotesCreatedSub:"Notes can be made during tests.",
	NoTestsAnswered:"You have not answered any tests.",
	PauseTestText: [
		<HistoryOutlined/>
	],
	PauseTestText2: [
		" now",
	],
	PauseTestText3:[
		"You may resume the Test at any time.",
	],
	CompleteTestText: [
		"Would you like to ",
	],
	CompleteTestText3:[
		" your answers once again?",
		<br/>,
		"You will be able to change your answers, if necessary",
	],
	CompleteTestText2: [
		" to view your results.",
		<br/>,
		"An analysis of your performance will also be displayed.",
	],
	TutorialBackNavigationMessage:"If you click the OK button, the tutorial will be automatically completed. You will not be able to resume it later. Unanswered questions will be marked as incorrect. Click Cancel if you wish to continue answering the tutorial.",
	TestBackNavigationMessage:"If you click the OK button, the test will be automatically paused. You will be able to resume it later. Click Cancel if you wish to continue answering the test.",
	LoggedInAnotherBrowser:"You have been automatically logged out. It appears that you may be logged in another browser.",
		//Mark for later
	PopupHeader:"Advanced Review Practice : Mark for later",
	PopupText:"You have marked question(s) for review.",
	PopupTextDescription:"Unanswered questions will be scored as incorrect.",
	PopupTextDescription2:"Click Ok to answer these question(s), or Cancel to submit the Test and display your Results.",
	unSavedChangesFullLengthMessage:"If you click the OK button, the test will be automatically completed. You will not be able to resume it later. Unanswered questions will be marked as incorrect. Click Cancel if you wish to continue answering the test.",
	FlashcardTYSEM1:"Please check your answer.",
	FlashcardTYSEM2:"Please flip the card and check your answer.",
	Question:"question",
	CrashedPausedTest:"If the test was abruptly terminated,",
	viewallimgs:"View all Images",
	viewallimgsdesc: ["You have viewed 5 images from this chapter.", < br />, "More images are available.", < br />, "Click the button below to view more images."],
	loggedOut:"Logged Out",
	logoutdesc:"You have been logged out of the Companion Website.",
	previewImg:"Zoom image",
	startTest:"Start Test",
	startTutorial:"Start Tutorial",
	WonderfulEffort:"Wonderful effort!",
	OptionPercentChapterTest:{
		25:[
			"You have completed 25% of the chapter tests."
		],
		50:[
			"You have completed 50% of the chapter tests."
		],
		75:[
			"You have completed 75% of the chapter tests."
		],
		100:[
			"You have completed 100% of the chapter tests."
		],
	},
	CardTitle:{
		25:[
			"Great Effort!"
		],
		50:[
			"Good Going!"
		],
		75:[
			"Almost There!"
		],
		100:[
			"Wonderful Effort!"
		],
	},
	ImageSelect:"Image",
	SingleSelect:"Single Select",
	markedTutorialDesc:"Choose the question type you would like to answer",
	markedTutorialHeader:"Tutorial Questions",
	pausedTests:"Paused Tests",
	pausedCard1:"paused.",
	pausedCard2:"to view them.",
	pausedCard3:"to view it.",
  QuestionCount:50,
	TutorialCount:10,
	Videos:"Videos",
	Universities:"Universities",
	Location:"Location",
	UniversityListing:"University Listing",
	AddUniversity:"Add University",
	EditUniversity:"Edit University",
	Cancel:"Cancel",
	TotalVideoViews:"Total Video Views",
	TotalImageViews:"Total Image Views",
	DateFormat :'MM/DD/YYYY',
	VideoViews: "Video Views",
	ImageViews: "Image Views",
	Video:"Video",
	Category:"Category",
	VideoCategoryList: [
		{id:1,title:"Brain"},
		{id:2,title:"Spinal Cord"},
		{id:3,title:"Larynx"},
		{id:4,title:"Oral Cavity, Oropharynx, Nasopharynx"},
		{id:5,title:"Thorax"},
	],
	Period:"Period",
	ChapterName: "Chapter Name",
	Views:"Views",
	TotalViews: 'Total Views',
	TotalStudyGuideDownload:"Total Study Guide Downloads",
	StudyGuideDownload:"Study Guide Downloads",
	StudyGuideDownloadResults:"Study Guide Download Results",
}
export default constants;
