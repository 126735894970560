/*
File Name: ReviewTest.js
Author: Mevito Gonsalves
Modified On: 28/08/2023
Description: This page includes the code to review a test.
API's used:
1) /student/review-test
2) 
*/
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space, Tooltip, Divider, Input, Modal, message, Spin, Form, Badge, Select, Progress, Alert, FloatButton, Image } from 'antd';
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, ZoomInOutlined, ZoomOutOutlined, FileSearchOutlined , ArrowLeftOutlined, RightOutlined, SaveOutlined, MessageOutlined, ArrowRightOutlined,PictureOutlined } from '@ant-design/icons';
import $ from "jquery";
import { displayQuestionDetails, featuredImageUrl, testModalPopup } from "../../../globalFunctions/GlobalFunctions";
import constants from "../../../constants/constants";
import { COOKIE, getCookie } from "../../../services/cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";
import { fetchApi } from "../../../services/api";
import Draggables from 'react-draggable';
import { Chart } from "react-google-charts";

const { Option } = Select;
const { TextArea } = Input;
const ReviewTest = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	// eye icon toggle
	const [viewTimer, setViewTimer] = useState(false);
	const toggleTimer = () => {
		setViewTimer(!viewTimer);
	};
	// notes modal
	const [notesVisible, setnotesVisible] = useState(false);
	const [correctAnswers, setCorrectAnswers] = useState(0);
	const [totalPoints, setTotalPoints] = useState(0);
	const showModal = () => {
		setnotesVisible(true);
		let itemTemp = listQuestions[questionIndex];
		if (itemTemp.notes !== undefined && itemTemp.notes !== null) {
			setNoteValue(itemTemp.notes);
		}
	};
	const hideModal = () => {
		noteForm.resetFields();
		setnotesVisible(false);
	};
	//To set note form
	const setNoteValue = (valueText) => {
		noteForm.setFieldsValue({ note: valueText });
	};
	const [disabled, setIsDisabled] = React.useState(true);
	const [bounds, setBounds] = React.useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0
	});

	const [listQuestions, setQuestions] = useState([]);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [timerDisplay, setTimerDisplay] = useState(null);
	const [time, setTime] = useState(null);
	const [completeTest, setCompleteTest] = useState(false);
	const [pauseTest, setPauseTest] = useState(false);
	const [scoreCardId, setScoreCardId] = useState(null);
	const [nextAndPreviousLoading, setNextAndPreviousLoading] = useState(false);
	const [completeTestLoading, setCompleteTestLoading] = useState(false);
	const [pauseTestLoading, setPauseTestLoading] = useState(false);
	const [selectedSequenceOption, setSelectedSequenceOption] = useState("");
	const [attemptedQuestions, setAttemptedQuestions] = useState(0);
	const draggleRef = React.createRef();
	const [noteForm] = Form.useForm();
	const [notelist,setNotesList] = useState([]);
	const [categoryDetails, setCategoryDetails] = useState({});
	const [topicDetails, setTopicDetails] = useState({});
	const [lastTenMinutes, setLastTenMinutes] = useState(false);
	const [checkAnswer, setCheckAnswer] = useState("false");
	const [markedLaterVisible, setMarkedLaterVisible] = useState(false);
	const [checkMarkLater, setCheckMarkLater] = useState("false");
	const [markedForLaterQuestions, setMarkedForLaterQuestions] = useState([]);
	const [mouseUp, setMouseUp] = useState(0);
	const [mouseDown, setMouseDown] = useState(0);
	const [selectedId, setSelectedId] = useState(null);
	const [visibleTimeOut, setVisibileTimeOut] = useState(false);
	const [searchList, setSearchList] = useState([]);
	let [searchValue, setSearchValue] = useState(null);
	const [isSearch, setIsSearch] = useState("false");
	const [displaySearch, setDisplaySearch] = useState("false");
	const [questionIndexList, setQuestionIndexList] = useState([]);//Used for Question Index drawer.
	const [isQuestionIndexOpened, setIsQuestionIndexOpened] = useState("false");
	const [onMouseDownId, setonMouseDownId] = useState(null);
	const [browserTime, setbrowserTime] = useState("");
	const [serverTime, setServerTime] = useState("");
	const [visible, setImgPreviewVisible] = useState(false);
	const [showResullt, setShowResult] = useState(location?.state?.show_result==="t"?true:false);
	const [globalPreview,setGlobalPreview]=useState(false);
	const setVisiblePreview=(val)=>{
		setGlobalPreview(val);
	}

	const current = new Date();
	const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
	const graphOption = {
		colors: ["#2F8548", "#B91919"],
		backgroundColor: { fill: 'transparent' },
		chartArea: { width: "70%" },
	};

	var myTimer;
	var timerUp;
	var totalSeconds = 0;
	const clock = (timer) => {
		myTimer = setInterval(myClock, 1000);
		var c = timer; //Initially set to 1 hour
		timerUp = setInterval(countTimer, 1000);
		function myClock() {
			sessionStorage.setItem("clock", --c);
			var seconds = c % 60; // Seconds that cannot be written in minutes
			var minutes = (c - seconds) / 60; // Gives the seconds that COULD be given in minutes
			var minutesLeft = minutes % 60; // Minutes that cannot be written in hours
			var hours = (minutes - minutesLeft) / 60;
			if (hours < 10)
				hours = "0" + hours;
			if (minutesLeft < 10) {
				minutesLeft = "0" + minutesLeft;
			}
			if (seconds < 10)
				seconds = "0" + seconds;
			// Now in hours, minutes and seconds, you have the time you need.
			sessionStorage.setItem("timerDisplay", hours + ":" + minutesLeft + ":" + seconds);
			setTimerDisplay(hours + ":" + minutesLeft + ":" + seconds);
			if (c == 300) {
				setLastTenMinutes(true);
			}
			if (c <= 0) {
				saveNotes();
				clearInterval(myTimer);
				clearInterval(timerUp);
				timeOut();
			}
		}
		function countTimer() {
			sessionStorage.setItem("totalSeconds", ++totalSeconds);
			var hour = Math.floor(totalSeconds / 3600);
			var minute = Math.floor((totalSeconds - hour * 3600) / 60);
			var seconds = totalSeconds - (hour * 3600 + minute * 60);
			if (hour < 10)
				hour = "0" + hour;
			if (minute < 10)
				minute = "0" + minute;
			if (seconds < 10)
				seconds = "0" + seconds;
			sessionStorage.setItem("timeTaken", hour + ":" + minute + ":" + seconds);
			setTime(hour + ":" + minute + ":" + seconds);
		}
	};
	useEffect(() => {
		sessionStorage.setItem("quitTest", "false");
		sessionStorage.setItem("isFullLengthTestvalue", "false");
		$(document).keydown(function (event) {
			if (event.keyCode === 123 || event.ctrlKey && event.shiftKey && event.keyCode === 73 || event.ctrlKey && event.keyCode === 67) {
				return false;
			}
		});
		document.addEventListener('copy', disableCopy);
		document.addEventListener('contextmenu', disableContextMenu);
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0) {
			fetchFromLocalStorage();
		} else {
			fetctReviewTestData();
		}
	}, []);
	useEffect(() => {
		return () => {
			clearInterval(myTimer);// Anything in here is fired on component unmount.
			clearInterval(timerUp);
			document.removeEventListener('contextmenu', disableContextMenu);
			document.removeEventListener('copy', disableCopy);
			$(document).off('keydown');
			let testCount = sessionStorage.getItem("totalTestsTaken");
			let viewedQod = sessionStorage.getItem("viewedQOD");
			let crashTest = sessionStorage.getItem("crash");
			let feedbackpopupshow = sessionStorage.getItem("feedbackShown");
			sessionStorage.clear();
			sessionStorage.setItem("UserIdSession",getCookie(COOKIE.UserId));
			sessionStorage.setItem("crash", crashTest);
			sessionStorage.setItem("totalTestsTaken", testCount);
			sessionStorage.setItem("viewedQOD", viewedQod);
			sessionStorage.setItem("feedbackShown", feedbackpopupshow);
		};
	}, []);
	function disableCopy(e) {
		e.preventDefault();
	}
	function disableContextMenu(e) {
		e.preventDefault();
	}
	//notes modal
	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement;
		const targetRect = draggleRef?.current?.getBoundingClientRect();
		let boundsForNotesModal = {
			left: -targetRect?.left + uiData?.x,
			right: clientWidth - (targetRect?.right - uiData?.x),
			top: -targetRect?.top + uiData?.y,
			bottom: clientHeight - (targetRect?.bottom - uiData?.y),
		};
		setBounds(boundsForNotesModal);
	};
	//TO fetch on resume test
	const fetctReviewTestData = () => {
		if (!location?.state?.chapter_id) {
			navigate('/dashboard', { replace: true });
		} else {
			setNextAndPreviousLoading(true);
			let userId = getCookie(COOKIE.UserId);
			let payload = {
				stud_id: userId,
				chapter_id: location?.state?.chapter_id,
				attempt: location?.state?.attempt,
				score_card_id:location?.state?.score_card_id
			};
			fetchApi(`/review-test`, "post", payload).then((res) => {
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					if (res.data && res.data.test_data && res.data.test_data.length > 0) {
						let markedForLaterQuestionsList = [];
						var attemptedQuestionsCount = 0;
						setCorrectAnswers(res.data.total_corr_ans);
						sessionStorage.setItem("correctCount", res.data.total_corr_ans);
						setTotalPoints(res.data.total_score)
						sessionStorage.setItem("totalPoints", res.data.total_score);
						for (let i = 0; i < res.data.test_data.length; i++) {
							res.data.test_data[i].matchAns = false;
							if (res.data.test_data[i].corr_ans && res.data.test_data[i].user_ans) {
								res.data.test_data[i].matchAns = areEqual(res.data.test_data[i].corr_ans,res.data.test_data[i].user_ans);
							}
							res.data.test_data[i].selectedAnswer = [];
							if (res.data.test_data[i].is_marked && res.data.test_data[i].is_marked === 1) {
								markedForLaterQuestionsList.push(res.data.test_data[i].question_id);
								res.data.test_data[i].markedForLater = true;
							} else {
								res.data.test_data[i].markedForLater = false;
							}
							if (res.data.test_data[i].q_type === "2" && res.data.test_data[i]?.user_ans?.length > 0) {
								res.data.test_data[i].answers.map((item, index) => {
									let ans = [];
									ans.push(res.data.test_data[i].user_ans[index]);
									item.selectedOption = ans;
								})
							}
							if (res.data.test_data[i].user_ans && res.data.test_data[i].user_ans.length > 0) {
								res.data.test_data[i].selectedAnswer = res.data.test_data[i].user_ans;
							}
							if (res.data.test_data[i].user_ans && res.data.test_data[i].user_ans.length > 0 || res.data.test_data[i].markedForLater === true) {
								res.data.test_data[i].isUpdatedToApi = true;
								setQuestionIndexList(questionIndexList);
								sessionStorage.setItem("questionIndexList", JSON.stringify(questionIndexList));
							} else {
								res.data.test_data[i].isUpdatedToApi = false;
							}
							sessionStorage.setItem("searchList", JSON.stringify(searchList));
							if (res.data.test_data[i].selectedAnswer && res.data.test_data[i].selectedAnswer.length > 0 || res.data.test_data[i].markedForLater === true) {
								attemptedQuestionsCount = attemptedQuestionsCount + 1;
							}
						}
						if (res.data && res.data.score_card_id) {
							sessionStorage.setItem('scoreCardId', res.data.score_card_id);
							setScoreCardId(res.data.score_card_id);
						}
						sessionStorage.setItem("listQuestions", JSON.stringify(res.data.test_data));
						let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
						sessionStorage.setItem("checkAnswer", "false");
						sessionStorage.setItem("checkMarkLater", "false");
						sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
						sessionStorage.setItem("displaySearch", "false");
						sessionStorage.setItem("isSearch", "false");
						sessionStorage.setItem("isQuestionIndexOpened", "false");
						sessionStorage.setItem("testType", "shortLength");
						setMarkedForLaterQuestions(markedForLaterQuestions);
						setCheckAnswer("false");
						setQuestions(listQuestionsFromSession);
						sessionStorage.setItem("attemptedQuestions", attemptedQuestionsCount);
						setAttemptedQuestions(attemptedQuestionsCount);
						setRefresh(!refresh);
					}
				} else {
					message.error(res.message);
					const state = {
						chapter_id: location?.state?.chapter_id
					};
					navigate('/chapter-resources', { state });;
				}
			});
		}
	}
	const fetchFromLocalStorage = () => {
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession = sessionStorage.getItem("questionIndex");
		let categoryDetailsFromSession = JSON.parse(sessionStorage.getItem("categoryDetails") || "null");
		let topicDetailsFromSession = JSON.parse(sessionStorage.getItem("topicDetails") || "null");
		let checkAnswerFromSession = sessionStorage.getItem("checkAnswer");
		let checkMarkLaterFromSession = sessionStorage.getItem("checkMarkLater");
		let markedForLaterQuestionsFromSession = JSON.parse(sessionStorage.getItem("markedForLaterQuestions") || "[]");
		let isSearchFromSession = sessionStorage.getItem("isSearch");
		let searchValueFromSession = sessionStorage.getItem("searchValue");
		let searchListFromSession = JSON.parse(sessionStorage.getItem("searchList") || "[]");
		let questionIndexListFromSession = JSON.parse(sessionStorage.getItem("questionIndexList") || "[]");
		let isQuestionIndexOpenedFromSession = sessionStorage.getItem("isQuestionIndexOpened");
		let displaySearchFromSession = sessionStorage.getItem("displaySearch");
		let attemptedQuestionsFromSession = sessionStorage.getItem("attemptedQuestions");
		let CorrectCountValue = Number(sessionStorage.getItem("correctCount"))
		setCorrectAnswers(CorrectCountValue);
		let totalPoints=Number(sessionStorage.getItem("totalPoints"))
		setTotalPoints(totalPoints);
		sessionStorage.setItem("correctCount", CorrectCountValue);
		setAttemptedQuestions(Number(attemptedQuestionsFromSession));
		// setShowResult(sessionStorage.getItem("showResult") === "t" ? true : false);
		setDisplaySearch(displaySearchFromSession);
		setIsQuestionIndexOpened(isQuestionIndexOpenedFromSession);
		setQuestionIndexList(questionIndexListFromSession);
		setSearchList(searchListFromSession);
		setSearchValue(searchValueFromSession);
		setIsSearch(isSearchFromSession);
		setMarkedForLaterQuestions(markedForLaterQuestionsFromSession);
		setCheckMarkLater(checkMarkLaterFromSession);
		setCheckAnswer(checkAnswerFromSession);
		setCategoryDetails(categoryDetailsFromSession);
		setTopicDetails(topicDetailsFromSession);
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0) {
			if (categoryDetailsFromSession) {
				if (categoryDetailsFromSession.isTimed === true) {
					setViewTimer(true);
					let clocks = sessionStorage.getItem("clock");
					clock(Number(clocks));
					totalSeconds = sessionStorage.getItem("totalSeconds");
					setTime(sessionStorage.getItem("timeTaken"));
					if (Number(clocks) === 0) {
						clearInterval(myTimer);
						clearInterval(timerUp);
						setVisibileTimeOut(true);
						sessionStorage.setItem("quitTest", "true");
						let timerDisplayFromSession = sessionStorage.getItem("timerDisplay");
						setTimerDisplay(timerDisplayFromSession);
					}
				} else if (categoryDetailsFromSession.isTimed === false) {
					setViewTimer(false);
				}
			}
			else if (topicDetailsFromSession) {
				if (topicDetailsFromSession.isTimed === true) {
					setViewTimer(true);
					let clocks = sessionStorage.getItem("clock");
					clock(Number(clocks));
					totalSeconds = sessionStorage.getItem("totalSeconds");
					setTime(sessionStorage.getItem("timeTaken"));
					if (Number(clocks) === 0) {
						clearInterval(myTimer);
						clearInterval(timerUp);
						setVisibileTimeOut(true);
						sessionStorage.setItem("quitTest", "true");
						let timerDisplayFromSession = sessionStorage.getItem("timerDisplay");
						setTimerDisplay(timerDisplayFromSession);
					}
				} else if (topicDetailsFromSession.isTimed === false) {
					setViewTimer(false);
				}
			}
			setQuestions(listQuestionsFromSession);
			setScoreCardId(Number(sessionStorage.getItem("scoreCardId")));
			setQuestionIndex(Number(questionIndexFromSession));
		} else {
			sessionStorage.setItem("quitTest", "true");
		}
	};
	//Executes on click of Next button.
	const handleNextQuestion = (btnClickType) => {
		var match = true;
		if (match === true) {
			let payload = {};
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();
			today = yyyy + '-' + mm + '-' + dd;
			if (listQuestions[questionIndex].q_type === "1" || listQuestions[questionIndex].q_type === "2") {
				var sequence = questionIndex + 1;
				payload.test_data = {
					seq: sequence,
					q_id: Number(listQuestions[questionIndex].question_id),
					user_ans: listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [],
					corr_ans: listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [],
					marked_for_later: listQuestions[questionIndex].markedForLater ? true : false,
				};
			}
			payload.test_status = 2;
			if (listQuestions[questionIndex].markedForLater === true) {
				payload.marked_for_later = {
					q_id: Number(listQuestions[questionIndex].question_id),
					marked_on: today
				}
			}
			let strikedOptions = [];
			for (let index = 0; index < listQuestions[questionIndex]?.answers.length; index++) {
				if (listQuestions[questionIndex].answers[index].option_text.includes(`<strike>`)) {
					strikedOptions.push(listQuestions[questionIndex].answers[index].answer_option_id);
				}
			}
			payload.strike_data = {
				q_id: Number(listQuestions[questionIndex].question_id),
				strike: strikedOptions,
			};
			let url = ``;
			url += `/update-test/${scoreCardId}`;
			nextQuestionApi(payload, url, btnClickType);
		} else {
			if (listQuestions[questionIndex].q_type === "1") {
				message.error(constants.SelectOptionOnly);
			} else {
				message.error(constants.SelectAllOptions);
			}
		}
	};
	const nextQuestionApi = (payload, url, btnClickType) => {
		if (btnClickType === "PREVIOUS") {
			sessionStorage.setItem("questionIndex", questionIndex - 1);
			setQuestionIndex(questionIndex - 1);
		} else {
			sessionStorage.setItem("questionIndex", questionIndex + 1);
			setQuestionIndex(questionIndex + 1);
		}
	};
	//Function to calculate the count of attempted questions to show on Pause test popup
	const calculateAttemptedQuestions = () => {
		if (listQuestions && listQuestions.length > 0) {
			var attemptedQuestionsCount = 0;
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions[index].selectedAnswer && listQuestions[index].selectedAnswer.length > 0 || listQuestions[index].markedForLater === true) {
					attemptedQuestionsCount = attemptedQuestionsCount + 1;
				}
			}
			setAttemptedQuestions(attemptedQuestionsCount);
			sessionStorage.setItem("attemptedQuestions", attemptedQuestionsCount);
			setRefresh(!refresh);
		}
	};
	const calculatePercentage = (count, total) => {
		return (count / total) * 100;
	}
	//Executes on click of Previous button.
	const handlePreviousQuestion = () => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 || listQuestions[questionIndex].markedForLater === true) {
			handleNextQuestion("PREVIOUS");
		} else {
			setNextAndPreviousLoading(true);
			sessionStorage.setItem("questionIndex", questionIndex - 1);
			setQuestionIndex(questionIndex - 1);
			setNextAndPreviousLoading(false);
		}
	};
	const completeTestApi = () => {
		let payload = {};
		payload.test_status = 4;
		const date = new Date();
		payload.dt_completed = date;
		if (markedForLaterQuestions && markedForLaterQuestions.length > 0) {
			payload.marked_for_later = markedForLaterQuestions;
		} else {
			payload.marked_for_later = [];
		}
		setCompleteTestLoading(true);
		fetchApi(`/update-test/${scoreCardId}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					let testCount = sessionStorage.getItem("totalTestsTaken");
					let totalTestCount = parseInt(testCount) + 1;
					sessionStorage.setItem("totalTestsTaken", totalTestCount);
					listQuestions[questionIndex].isUpdatedToApi = true;
					sessionStorage.setItem("quitTest", "true");
					setCompleteTest(false);
					clearInterval(myTimer);
					clearInterval(timerUp);
					calculateAttemptedQuestions();
					setCompleteTestLoading(false);
					setShowResult(true);
					sessionStorage.setItem("showResult", "t");
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest", "true");
					setShowResult(false);
					sessionStorage.setItem("showResult", "f");
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	//Executes score_card PUT method API to pause the test
	const pauseTestAnswer = () => {
		setPauseTestLoading(true);
		let payload = {};
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + '-' + mm + '-' + dd;
		if (listQuestions[questionIndex].q_type === "1" || listQuestions[questionIndex].q_type === "2") {
			var sequence = questionIndex + 1;
			payload.test_data = {
				seq: sequence,
				q_id: Number(listQuestions[questionIndex].question_id),
				user_ans: listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [],
				corr_ans: listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [],
				marked_for_later: listQuestions[questionIndex].markedForLater ? true : false,
			};
		}
		payload.test_status = 3;
		if (markedForLaterQuestions && markedForLaterQuestions.length > 0) {
			payload.marked_for_later = markedForLaterQuestions;
		} else {
			payload.marked_for_later = [];
		}
		fetchApi(`/update-test/${scoreCardId}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					listQuestions[questionIndex].isUpdatedToApi = true;
					sessionStorage.setItem("quitTest", "true");
					setCompleteTest(false);
					clearInterval(myTimer);
					clearInterval(timerUp);
					setPauseTestLoading(false);
					const state = {
						chapter_id: location?.state?.chapter_id
					};
					navigate('/chapter-resources', { state, replace: true });
				} else {
					setPauseTestLoading(false);
					sessionStorage.setItem("quitTest", "true");
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
				setPauseTestLoading(false);
			});
	};
	//Executes score_card PUT method API when time runs out
	const timeOut = () => {
		let scoreCardIdFromSession = Number(sessionStorage.getItem("scoreCardId"));
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession = Number(sessionStorage.getItem("questionIndex"));
		let categoryDetailsFromSession = JSON.parse(sessionStorage.getItem("categoryDetails") || "null");
		let topicDetailsFromSession = JSON.parse(sessionStorage.getItem("topicDetails") || "null");
		setCompleteTestLoading(true);
		let payload = {};
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + '-' + mm + '-' + dd;
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0 && listQuestionsFromSession[questionIndexFromSession].selectedAnswer && listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length > 0 ||
			listQuestionsFromSession[questionIndexFromSession].markedForLater === true ||
			listQuestionsFromSession[questionIndexFromSession].markedForLater === false) {
			if (listQuestionsFromSession[questionIndexFromSession].q_type === "1" || listQuestionsFromSession[questionIndexFromSession].q_type === "2") {
				var sequence = questionIndexFromSession + 1;
				payload.test_data = {
					seq: sequence,
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans: listQuestionsFromSession[questionIndexFromSession].selectedAnswer && listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length > 0 ? listQuestionsFromSession[questionIndexFromSession].selectedAnswer : [],
					corr_ans: listQuestionsFromSession[questionIndexFromSession].correct_option && listQuestionsFromSession[questionIndexFromSession].correct_option.length > 0 ? listQuestionsFromSession[questionIndexFromSession].correct_option : [],
					marked_for_later: listQuestionsFromSession[questionIndexFromSession].markedForLater ? true : false,
					// answered_on:today,
				};
			} else if (listQuestionsFromSession[questionIndexFromSession].q_type === "4") {
				let answer = [];
				for (let i = 0; i < listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length; i++) {
					answer.push(listQuestionsFromSession[questionIndexFromSession].selectedAnswer[i].index);
				}
				var seq = questionIndexFromSession + 1;
				payload.test_data = {
					seq: seq,
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans: answer && answer.length > 0 ? answer : [],
					corr_ans: listQuestionsFromSession[questionIndexFromSession].correct_option && listQuestionsFromSession[questionIndexFromSession].correct_option.length > 0 ? listQuestionsFromSession[questionIndexFromSession].correct_option : [],
					marked_for_later: listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			} else if (listQuestionsFromSession[questionIndexFromSession].q_type === "5") {
				let answer = [];
				for (let i = 0; i < listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length; i++) {
					answer.push(Number(listQuestionsFromSession[questionIndexFromSession].selectedAnswer[i].id));
				}
				var sequ = questionIndexFromSession + 1;
				payload.test_data = {
					seq: sequ,
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans: answer && answer.length > 0 ? answer : [],
					corr_ans: listQuestionsFromSession[questionIndexFromSession].correct_option && listQuestionsFromSession[questionIndexFromSession].correct_option.length > 0 ? listQuestionsFromSession[questionIndexFromSession].correct_option : [],
					marked_for_later: listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			}
			if ((categoryDetailsFromSession && categoryDetailsFromSession.isTimed !== null && categoryDetailsFromSession.isTimed === true) ||
				(topicDetailsFromSession && topicDetailsFromSession.isTimed !== null && topicDetailsFromSession.isTimed === true)) {
				payload.total_time_taken = sessionStorage.getItem("timeTaken");
			}
			let totalOptions = null;
			let optionNumver = {};
			if (listQuestionsFromSession[questionIndexFromSession].q_type === "1" || listQuestionsFromSession[questionIndexFromSession].q_type === "2" || listQuestionsFromSession[questionIndexFromSession].q_type === "4") {
				totalOptions = listQuestionsFromSession[questionIndexFromSession].opt_cnt ? Number(listQuestionsFromSession[questionIndexFromSession].opt_cnt) : 0;
				optionNumver = constants.OptionNumbers;
			} else if (listQuestionsFromSession[questionIndexFromSession].q_type === "5") {
				optionNumver = constants.OptionNumberDragAndDrop;
				totalOptions = listQuestionsFromSession[questionIndexFromSession].opt_cnt ? Number(listQuestionsFromSession[questionIndexFromSession].opt_cnt.charAt(2)) : 0;
			}
			let strikedOptions = [];
			let highlightedOptions = [];
			for (let j = 0; j < totalOptions; j++) {
				let op = optionNumver[j + 1];
				if (listQuestionsFromSession[questionIndexFromSession][op].includes("<strike>")) {
					strikedOptions.push(j + 1);
				}
				if (listQuestionsFromSession[questionIndexFromSession][op].includes("highlighted")) {
					highlightedOptions.push({
						opt: j + 1,
						data: listQuestionsFromSession[questionIndexFromSession][op]
					});
				}
			}
			if (listQuestionsFromSession[questionIndexFromSession].question_text && listQuestionsFromSession[questionIndexFromSession].question_text.includes("highlighted")) {
				highlightedOptions.push({
					opt: 99,
					data: listQuestionsFromSession[questionIndexFromSession].question_text,
				});
			}
			if (strikedOptions && strikedOptions.length > 0 || highlightedOptions && highlightedOptions.length > 0) {
				payload.highlight_strike_data = {
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					strike: strikedOptions,
				};
			}
		} else {
			if ((categoryDetailsFromSession && categoryDetailsFromSession.isTimed !== null && categoryDetailsFromSession.isTimed === true) ||
				(topicDetailsFromSession && topicDetailsFromSession.isTimed !== null && topicDetailsFromSession.isTimed === true)) {
				payload.total_time_taken = sessionStorage.getItem("timeTaken");
			}
		}
		const date = new Date();
		payload.dt_completed = date;
		payload.test_status = 1;
		fetchApi(`/score_card/${scoreCardIdFromSession}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					listQuestionsFromSession[questionIndexFromSession].isUpdatedToApi = true;
					sessionStorage.setItem("quitTest", "true");
					// setPristine();
					setCompleteTest(false);
					// clearInterval(myTimer);
					// clearInterval(timerUp);
					setCompleteTestLoading(false);
					setVisibileTimeOut(true);
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest", "true");
					// setPristine();
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	const handleTimeOutOk = () => {
		let testCategory = null;
		let resultTypeFull = null;
		let isTopic = null;
		sessionStorage.setItem("quitTest", "true");
		if (categoryDetails && categoryDetails.type === "normal") {
			if (categoryDetails.catType && categoryDetails.catType === "MIXED") {
				resultTypeFull = false;
				testCategory = categoryDetails.catType;
				isTopic = false;
			} else {
				resultTypeFull = true;
				testCategory = categoryDetails.catType;
				isTopic = false;
			}
		}
		if (topicDetails && topicDetails.type === "normal") {
			resultTypeFull = false;
			testCategory = null;
			isTopic = true;
		}
	};
	//On check function for single select question options.
	const handleSingleSelectAnswer = e => {
		if (e.target.value) {
			if (listQuestions && listQuestions.length > 0) {
				if (isSearch === "true" || isQuestionIndexOpened === "true") {
					listQuestions[questionIndex].selectedAnswer = [e.target.value];
				} else {
					listQuestions[questionIndex].selectedAnswer = [e.target.value];
					listQuestions[questionIndex].oldSelectedAnswer = [e.target.value];
				}
				let tempCorrectOption = listQuestions[questionIndex].answers.filter((item) => { return item.corr_opt === "t" });
				listQuestions[questionIndex].correct_option = [tempCorrectOption[0].answer_option_id];
				let getUserAnswerIndex = listQuestions[questionIndex]?.answers.findIndex((item) => item.answer_option_id === e.target.value);
				if (listQuestions[questionIndex].answers[getUserAnswerIndex].option_text.includes("<strike>")) {
					listQuestions[questionIndex].answers[getUserAnswerIndex].option_text = listQuestions[questionIndex].answers[getUserAnswerIndex].option_text.replace("<strike>", "");
					listQuestions[questionIndex].answers[getUserAnswerIndex].option_text = listQuestions[questionIndex].answers[getUserAnswerIndex].option_text.replace("</strike>", "");
				}
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	//Function to check answers on click of check button on complete test popup
	const checkAnswers = () => {
		sessionStorage.setItem("checkAnswer", "true");
		sessionStorage.setItem("checkMarkLater", "false");
		setCheckMarkLater("false");
		setCheckAnswer("true");
		setCompleteTest(false);
		setQuestionIndex(0);
		sessionStorage.setItem("questionIndex", 0);
	};
	//Function to hide popup of Mark for later on click of Ok button.
	const handleOkMarkForLaterModal = () => {
		sessionStorage.setItem("checkMarkLater", "true");
		//Display marked for later questions on clic of OK.
		if (listQuestions && listQuestions.length > 0) {
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions[index].markedForLater === true) {
					setQuestionIndex(index);
					listQuestions[index].markedForLater = false;
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
					setQuestions(listQuestions);
					sessionStorage.setItem("questionIndex", index);
					break;
				}
			}
		}
		setCheckMarkLater("true");
		setMarkedLaterVisible(false);
	};
	//Function to set the coordinate of selected text on Mouse down for highlighting the text
	const handleMouseDown = (e, id) => {
		setonMouseDownId(Number(id));
		setSelectedSequenceOption("");
		if (e.changedTouches && e.changedTouches[0] && e.changedTouches[0].pageX) {
			setMouseDown(e.changedTouches[0].pageX);
		} else {
			setMouseDown(e.pageX);
		}
		setRefresh(!refresh);
	};
	//Function to set the coordinate of selected text on Mouse up for highlighting the text
	const handleMouseUp = (e, id) => {
		setSelectedId(Number(id));
		setSelectedSequenceOption("");
		if (e.changedTouches && e.changedTouches[0] && e.changedTouches[0].pageX) {
			setMouseUp(e.changedTouches[0].pageX);
		} else {
			setMouseUp(e.pageX);
		}
		setRefresh(!refresh);
	};
	const onSelectOptionChange = (e, index) => {
		if (e) {
			if (listQuestions && listQuestions.length > 0) {
				listQuestions[questionIndex].answers[index].selectedOption = [e];
				let tempSelectedAnswers = [];
				let tempCorrectAnsweres = [];
				listQuestions[questionIndex].answers.map((item) => {
					if (item.selectedOption && item.selectedOption.length > 0) {
						tempSelectedAnswers.push(item.selectedOption[0])
					}
					if (item.corr_opt === "t") {
						tempCorrectAnsweres.push(item.answer_option_id)
					}
				})
				listQuestions[questionIndex].selectedAnswer = tempSelectedAnswers;
				listQuestions[questionIndex].correct_option = tempCorrectAnsweres;
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	}
	// Function to append alphabets to the selected option
	const appendAlphabet = (option) => {
		const alphabet = String.fromCharCode(65 + option);
		return alphabet;
	};
	const getCorrectIncorrectClass = (value) => {
		if (listQuestions && listQuestions.length > 0) {
			if (Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].corr_ans[0])) {
				return 'border border-green-600'
			} else if (Number(value) !== Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].corr_ans[0]) && Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].user_ans[0])) {
				return 'border border-red-600'
			} else {
				return ''
			}
		}
	}
	const getCorrectIncorrectIcon = (value) => {
		if (listQuestions && listQuestions.length > 0) {
			if (Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].corr_ans[0])) {
				return <CheckOutlined className="text-green-600 pr-2" />
			} else if (Number(value) !== Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].corr_ans[0]) && Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].user_ans[0])) {
				return <CloseOutlined className="text-red-600 pr-2" />
			} else {
				return ''
			}
		}
	}
	const getCorrectIncorrectDropdownClass = (value, index) => {
		if (listQuestions && listQuestions.length > 0) {
			if (
				Number(listQuestions
					&& listQuestions.length > 0
					&& listQuestions[questionIndex].user_ans[index]) ===
				Number(listQuestions
					&& listQuestions.length > 0
					&& listQuestions[questionIndex].corr_ans[index])
			) {
				return 'border border-green-600'
			}
			else {
				return 'border border-red-600'
			}
		}
	}
	const getCorrectIncorrectImageIcon = (value, index) => {
		if (listQuestions && listQuestions.length > 0) {
			if (
				Number(listQuestions
					&& listQuestions.length > 0
					&& listQuestions[questionIndex].user_ans[index]) ===
				Number(listQuestions
					&& listQuestions.length > 0
					&& listQuestions[questionIndex].corr_ans[index])
			) {
				return <CheckOutlined className="text-green-600 pr-2" />
			}
			else {
				return <CloseOutlined className="text-red-600 pr-2" />
			}
		}
	}
	const singleSelect = (
		<div className="grid gap-2">
			<div className="font-normal text-xl">
				{listQuestions && listQuestions.length > 0 ?
				<Space>
					<span>Q: {questionIndex + 1}/{listQuestions && listQuestions.length}</span>
					<span className="text-base font-semibold">({listQuestions && listQuestions.length>0?listQuestions[questionIndex]?.cal_points:0} {Number(listQuestions[questionIndex].cal_points)===1?"point scored":"points scored"})</span>
				</Space>
					: ""}
			</div>
			<div className="w-full lg:w-3/4 mx-auto grid gap-3">
				<div>
					<p className="text-2xl font-normal mb-2" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.question_text ? listQuestions[questionIndex].question_text : "" }}></p>
					<div className="text-center">{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].image_name && (featuredImageUrl(listQuestions[questionIndex].image_name, listQuestions[questionIndex].orig_img,globalPreview,setVisiblePreview,listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : ""))}</div>
				</div>
				<Radio.Group className="w-full radio-test"
					onChange={handleSingleSelectAnswer}
					disabled
					value={listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.selectedAnswer && listQuestions[questionIndex]?.selectedAnswer.length > 0 && listQuestions[questionIndex].selectedAnswer[0]}
				>
					(<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
						{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.answers.map((option, index) => {
							return <Radio
								value={option.answer_option_id}
								id={option.answer_option_id}
								className={getCorrectIncorrectClass(option.answer_option_id) + " bg-slate-200 p-2 rounded-lg !text-black pl-10 relative items-center"}
							>
								<span className="absolute left-0 font-semibold border border-transparent border-r-slate-300 w-8 text-center grid place-content-center h-full top-0">{appendAlphabet(index)}</span>
								<Space>
									<span>{getCorrectIncorrectIcon(option.answer_option_id)}</span>
									<p id={option.option_id} dangerouslySetInnerHTML={{ __html: option.option_text }}></p>
								</Space>
							</Radio>
						})
						}
					</div>)
				</Radio.Group>
				{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.user_ans?.length > 0 ? null :
					<Alert message="Question not answered." type="error" showIcon />
				}
				{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].matchAns !== null &&
					listQuestions[questionIndex].matchAns === false  && listQuestions[questionIndex].explanation ?
						<div>
						<Card title="Explanation" size="small" className="border-slate-200">
							{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].explanation ? <div dangerouslySetInnerHTML={{ __html: listQuestions[questionIndex].explanation }}></div> : ""}
						</Card>
						</div> : ""
				}
			</div>
		</div>
	);
	const getPreviewImageButton=(
			<>
					<FloatButton
						icon={<img src={listQuestions[questionIndex]?.image_name} alt="thumbnail" />}
						tooltip={<div>Preview Image</div>}
						onClick={() => setImgPreviewVisible(true)}
						shape="square"
						className="preview-img right-14"
						description={<span className="uppercase">{constants.previewImg}</span>}
					/>
					<Image
						width={200}
						style={{
							display: "none"
						}}
						src={listQuestions[questionIndex]?.image_name}
						rootClassName="img-prev-2"
						preview={{
							visible: visible,
							src:listQuestions[questionIndex]?.orig_img,
							onVisibleChange: (value) => {
								setImgPreviewVisible(value);
							},
							closeIcon: <CloseCircleOutlined className='hidden' />,
							toolbarRender: (
								_,
								{ transform: { scale }, actions: { onZoomOut, onZoomIn } }
							) => (
								<div className="w-full lg:w-3/5 toolbar-wrapper text-base flex items-center justify-between top-20 translate-x-[-50%] left-1/2 px-6 text-black rounded-full bg-white">
									<div className='my-1.5'>
										<p className="mb-0 q-preview" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : "" }}></p>
									</div>
									<Space size={12} className='my-2'>
										<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
										<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
										<CloseCircleOutlined className='text-red-600' onClick={() => { setImgPreviewVisible(!visible) }} />
									</Space>
								</div>
							)
						}}
					/>
				</>
	);
	const imageSelect = (
		<div className="grid gap-2">
			<div className="font-normal text-xl">{listQuestions && listQuestions.length > 0 ?
			<Space>
				<span>Q: {questionIndex + 1}/{listQuestions && listQuestions.length}</span>
				<span className="text-base font-semibold">({listQuestions && listQuestions.length>0?listQuestions[questionIndex]?.cal_points:0} {Number(listQuestions[questionIndex].cal_points)===1?"point scored":"points scored"})</span>
			</Space>
				: ""}</div>
			<div className="w-full lg:w-3/4 mx-auto grid gap-3">
				<div>
					<p className="text-2xl font-normal mb-2" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : "" }}></p>
					<div className="text-center">{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].image_name && (featuredImageUrl(listQuestions[questionIndex].image_name, listQuestions[questionIndex].orig_img,globalPreview,setVisiblePreview,listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : ""))}</div>
				</div>
				<div>
					<div className="grid grid-cols-2 gap-5 font-bold mb-3">
						<span className="ml-12 text-base">You answered</span>
						{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex]?.matchAns?null:
						<span className="text-base">Correct answer</span>
						}
					</div>
					{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.answers?.map((option, index) => {
						return <Row gutter={[24, 0]} className="mb-3">
							<Col span={12}>
								<div className="flex items-center">
									<span className=" font-semibold w-8 text-center">{appendAlphabet(index)}</span>
									<span>{getCorrectIncorrectImageIcon(option.answer_option_id, index)}</span>
									<Select className={getCorrectIncorrectDropdownClass(option.answer_option_id, index) + " !w-full rounded-md"} value={option?.selectedOption ? option.selectedOption[0] : null} onChange={(e) => onSelectOptionChange(e, index)} disabled>
										{option?.other_opt1?.map((optionInner) => {
											return <Option value={optionInner.answer_option_id}><p className="!text-black" id={optionInner.answer_option_id} dangerouslySetInnerHTML={{ __html: optionInner.option_text }}></p></Option>
										})}
									</Select>
								</div>
							</Col>
							{
								listQuestions && listQuestions.length > 0 ?
									Number(listQuestions
										&& listQuestions.length > 0
										&& listQuestions[questionIndex].user_ans[index]) ===
										Number(listQuestions
											&& listQuestions.length > 0
											&& listQuestions[questionIndex].corr_ans[index])
										?
										null :
										<Col span={12}>
											<div className="flex items-center hide-dropdown-arrow">
												<Select className={"border border-green-600 !w-full rounded-md"} value={option?.answer_option_id ? option.answer_option_id : null} disabled>
													{listQuestions[questionIndex]?.answers?.map((optionIn) => {
														return <Option value={optionIn.answer_option_id}><p className="!text-black" id={optionIn.answer_option_id} dangerouslySetInnerHTML={{ __html: optionIn.option_text }}></p></Option>
													})}
												</Select>
											</div>
										</Col>
									: ""
							}
							{/* {getCorrectDropdown(option.answer_option_id,index)} */}
						</Row>
					})
					}
				</div>
				{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.user_ans?.length > 0 ? null :
					<Alert message="Question not answered." type="error" showIcon />
				}
				{/* {listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].matchAns !== null &&
					listQuestions[questionIndex].matchAns === false ?
						<div>
						<Card title="Explanation" size="small" className="border-slate-200">
							{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].explanation ? <div dangerouslySetInnerHTML={{ __html: listQuestions[questionIndex].explanation }}></div> : ""}
						</Card>
						</div> : ""
				} */}
				{getPreviewImageButton}
			</div>
		</div>
	);
	//function to chech array values
	const areEqual=(array1, array2)=> {
		if (array1.length === array2.length) {
			return array1.every((element, index) => {
				if (element === array2[index]) {
					return true;
				}
	
				return false;
			});
		}
	
		return false;
	}
	//function to create a note
	const createNote = (values) => {
		if (values.note !== undefined && values.note !== null) {
			let tempValueNote = values.note.trim();
			let tempNote = tempValueNote.replace(/^\s*[\r\n]/gm, "\n");
			let tempNoteID = listQuestions[questionIndex].note_id;
			let indexTemp = notelist.findIndex((item) => item.question_id === listQuestions[questionIndex].question_id);
			if (tempNote !== "" && listQuestions[questionIndex].note_id === null) {
				listQuestions[questionIndex].notes = tempNote;
				let tempObj = {
					note: tempNote,
					question_id: listQuestions[questionIndex].question_id,
					is_review:1
				};
				if (indexTemp === -1) {
					notelist.push(tempObj);
				} else {
					notelist[indexTemp].note = tempNote;
				}
				saveNotes();
			} else {
				if (indexTemp !== -1 && tempNote === "") {
					notelist.splice(indexTemp, 1);
				}
				listQuestions[questionIndex].notes = null;
			}
			if (tempNote !== "" && listQuestions[questionIndex].note_id !== null) {
				listQuestions[questionIndex].notes = tempNote;
				let userId = getCookie(COOKIE.UserId);
				let payload = null;
				payload = {
					stud_id: userId,
					note: tempNote,
					note_id: tempNoteID,
					score_card_id: scoreCardId,
					question_id: listQuestions[questionIndex].question_id,
					is_review:1
				};
				fetchApi(`/update-note`, "put", payload).then((res) => {
					if (res && res.code === 200) {
						// message.success(res.message);
					} else {
						message.error(res.message);
					}
				});
			}
			if (tempNote === "" && listQuestions[questionIndex].note_id !== null) {
				fetchApi(`/delete-note/${tempNoteID}`, "delete").then((res) => {
					if (res && res.code === 200) {
						//	message.success(res.message);
						listQuestions[questionIndex].notes = null;
						listQuestions[questionIndex].note_id = null;
					} else {
						message.error(res.message);
					}
				});
			}
			hideModal();
		}
	};
	const saveNotes = () => {
		if (notelist.length > 0) {
			let userId = getCookie(COOKIE.UserId);
			let payload = null;
			payload = {
				stud_id: userId,
				notes: notelist,
				score_card_id: scoreCardId,
			};
			fetchApi(`/save-notes`, "post", payload).then((res) => {
				if (res && res.code === 200) {
					// message.success(res.message);
					listQuestions[questionIndex].note_id = res.notes_id;
					setNotesList([]);
				} else {
					message.error(res.message);
				}
			});
		}
	};
	return (
		<div >
			<div
				style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
			>
				{location?.state?.chapter_id}
			</div>
			<div className='container mx-auto'>
				<div className='flex justify-between items-center'>
					<div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{location?.state?.chapter_name}</div>
				</div>
				<Divider className="my-1" />
				{showResullt === false ?
				<>
				<div className={`flex flex-wrap justify-between items-center my-3 px-5`}>
					<div className='font-bold text-xl'>Review Test - Attempt #{location?.state?.attempt}</div>
					<div>
						<p className=' text-xl '>Score : {correctAnswers}/{totalPoints}</p>
					</div>
				</div>
				<div>
					<div>
						<Space size={'large'} className="flex-vertical-middle">
						</Space>
					</div>
					<Modal
						title=
						{
							<div
								style={{
									width: '100%',
									cursor: 'move',
								}}
								onMouseOver={() => {
									if (disabled) {
										setIsDisabled(false);
									}
								}}
								onMouseOut={() => {
									setIsDisabled(true);
								}} onFocus={() => { }}
								onBlur={() => { }}
							>
								Notes
							</div>
						}
						visible={notesVisible}
						onOk={hideModal}
						onCancel={hideModal}
						className="notes-modal"
						footer={null}
						modalRender={modal => (
							<Draggables
								disabled={disabled}
								bounds={bounds}
								onStart={(event, uiData) => onStart(event, uiData)}
							>
								<div ref={draggleRef}>{modal}</div>
							</Draggables>
						)}
					>
						<Form form={noteForm} onFinish={createNote}>
							<Form.Item name="note">
								<TextArea
									rows={4}
									placeholder=" Write a note"
									className="p-0"
									onPaste={(e) => {
										e.preventDefault();
										return false;
									}} onCopy={(e) => {
										e.preventDefault();
										return false;
									}}
								/>
							</Form.Item>
							<Form.Item className="mb-0">
								<Button htmlType="submit" icon={<SaveOutlined />} className="bg-green-700 hover:bg-green-600 !text-white">{constants.Save}</Button>
							</Form.Item>
						</Form>
					</Modal>
					<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
						<Spin spinning={nextAndPreviousLoading} size="large" >
							{
								listQuestions &&
									listQuestions.length > 0 &&
									listQuestions[questionIndex].q_type &&
									listQuestions[questionIndex].q_type === "1" ? singleSelect :
									listQuestions &&
										listQuestions.length > 0 &&
										listQuestions[questionIndex].q_type &&
										listQuestions[questionIndex].q_type === "2" ? imageSelect : ""
							}
						<div className="lg:w-3/4 mx-auto mt-3">
							<Space size={'large'} wrap>
								<Space wrap>
									{questionIndex > 0 && checkMarkLater === "false" && displaySearch === "false" && isSearch === "false" && isQuestionIndexOpened === "false" &&
										<Button
											className="bg-green-700 hover:bg-green-600 !text-white rounded-lg"
											onClick={() => { handlePreviousQuestion(); }}
											disabled={nextAndPreviousLoading}
											icon={<ArrowLeftOutlined />}
										>
											{constants.Previous}
										</Button>
									}
									{listQuestions && listQuestions.length > 0 && questionIndex < listQuestions.length - 1 && displaySearch === "false" && isSearch === "false" && isQuestionIndexOpened === "false" && checkMarkLater === "false" ?
										(
											<Button
												className="bg-green-700 hover:bg-green-600 !text-white rounded-lg"
												onClick={() => { handleNextQuestion(); }}
												disabled={nextAndPreviousLoading}
												icon={<ArrowRightOutlined />}
											>
												{constants.Next}
											</Button>
										) : null}
									{displaySearch === "false" && isSearch === "true" || isQuestionIndexOpened === "true" ? (
													<Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<SaveOutlined />} onClick={() => { handleNextQuestion("SAVE"); }}>
											{constants.Save}
										</Button>
									) : ""}
									{listQuestions && listQuestions.length > 0 && displaySearch === "false" ?
										checkAnswer === "false" && checkMarkLater === "false" ?
											listQuestions && questionIndex === listQuestions.length - 1 && (<></>)
											// (<Button className="bg-green-700 hover:bg-green-600 rounded-lg" onClick={() => { checkComplete(); }} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
											: checkMarkLater === "true" ?
												// listQuestions&&questionIndex === listQuestions.length - 1&&(<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion("SUBMIT");}} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
												listQuestions &&
													markedForLaterQuestions && markedForLaterQuestions.length > 0 && listQuestions[questionIndex].question_id !== markedForLaterQuestions[markedForLaterQuestions.length - 1] ?
													(<Button className="bg-green-700 hover:bg-green-600 rounded-lg" onClick={() => { handleNextQuestion(); }} disabled={nextAndPreviousLoading} icon={<RightOutlined />}>
														{constants.Next}
													</Button>) : ""
												: ""
										: ""
									}
									<Button onClick={() => {
										const state = {
											chapter_id: location?.state?.chapter_id
										};
										navigate('/chapter-resources', { state, replace: true });
									}}
										className="rounded-lg"
										type="primary" danger icon={<CloseOutlined />}>{constants.Close}</Button>
								</Space>
								{listQuestions && listQuestions.length > 0 && displaySearch === "false" ? <Space>
									{/* <Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} onTouchEnd={()=>handleHighlightText()} onClick={()=>{handleHighlightText();}} icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip> */}
									{/* {checkMarkLater==="false"&&<Tooltip title="Question Index"><Button type="text" onClick={showDrawer} icon={<ProfileOutlined/>}></Button></Tooltip>} */}
									{listQuestions.length > 0
										? listQuestions[questionIndex].notes !== null
											&& listQuestions[questionIndex].notes !== undefined
											&& listQuestions[questionIndex].notes !== "" ?
											<Tooltip title="Notes"><Button className="rounded-lg bg-blue-700 !text-white" icon={<Badge dot color={"blue"}><MessageOutlined /></Badge>} onClick={showModal}></Button></Tooltip>
											:
											<Tooltip title="Notes"><Button className="rounded-lg bg-blue-700 !text-white" icon={<MessageOutlined />} onClick={showModal}></Button></Tooltip>
										: null}
								</Space> : null}
							</Space>
							<div className='mt-2 bg-white p-3 rounded-lg shadow-lg'>
								<Progress className='!mb-0' percent={calculatePercentage(questionIndex + 1, listQuestions && listQuestions.length)} showInfo={false} />
							</div>
						</div>
						</Spin>
					</Card>
				</div>
				</>:
				<div>
					<Spin spinning={nextAndPreviousLoading} size="large" >
					<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
						<div class="grid grid-cols-1 lg:grid-cols-2 divide-x">
							<div>
									<div className='text-green-700 text-xl font-semibold'>You scored {correctAnswers}/{totalPoints} </div>
									<div className="font-semibold">{"Date: " + date}</div>
									<Chart
										width="100%"
										height="300px"
										chartType="PieChart"
										// loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
										data={[
												["Answered", "Total"],
	  										["Correct", Number(correctAnswers)],
												["Incorrect", Number(totalPoints) - Number(Number(correctAnswers))],
											]}
											options={graphOption}
										/>
										</div>
										<div className="grid place-content-center place-items-center">
											<div className="grid gap-3 lg:w-56">
												<Button
													className='rounded-lg bg-blue-700 text-white text-left' block
													size="large" icon={<FileSearchOutlined />}	
													onClick={()=>{
														setShowResult(!showResullt)
													}}
												>{constants.ReviewTest}</Button>
											<Button onClick={() => {
												const state = {
													chapter_id: location?.state?.chapter_id
												};
												navigate('/chapter-resources', { state, replace: true });
											}}
												className='rounded-lg bg-blue-700 text-white text-left' block
												size="large" icon={<ArrowLeftOutlined />}	>{constants.BackToChapter}</Button>
											<Button onClick={() => navigate('/dashboard', { replace: true })} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white text-left' block
												size="large">{constants.Dashboard}</Button>
										</div>
									</div>
								</div>
					</Card>
					</Spin>
				</div>
				}
			</div>
			{displaySearch === "false" ? <Row>
				{listQuestions && listQuestions.length > 0 ?
					displayQuestionDetails(listQuestions, questionIndex)
					: ""}
			</Row> : ""}
			<Row>
				<Col span={24} style={{ visibility: "hidden" }}>
					<b>Server time:</b> {serverTime ? serverTime : ""}{"           "}
					<b>Browser time:</b> {browserTime ? browserTime : ""}
				</Col>
			</Row>
			{testModalPopup(
				pauseTest,
				completeTest,
				categoryDetails,
				topicDetails,
				viewTimer,
				timerDisplay,
				toggleTimer,
				attemptedQuestions,
				pauseTestAnswer,
				saveNotes,
				pauseTestLoading,
				setPauseTest,
				{},
				checkAnswers,
				completeTestApi,
				completeTestLoading,
				lastTenMinutes,
				setLastTenMinutes,
				markedLaterVisible,
				handleOkMarkForLaterModal,
				visibleTimeOut,
				handleTimeOutOk,
				null,
				null,
				location?.state?.attempt
			)}
		</div>
	);
};
export default ReviewTest;
