import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Table,
  Form,
  DatePicker,
  Empty,
  Select,
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Chart } from "react-google-charts";
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
import dayjs from 'dayjs';

const RegistrationsByUSstates = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [stateregisterationdata, setStateregisterationData] = useState([]);
  const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
  const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
  const [filteredstates, setFilteredstates] = useState([]);
  const [registrationcount, setRegistrationcount] = useState();
  const [period, setPeriod] = useState(0);
  const dateFormat = 'MM/DD/YYYY';

  const newCrumb = [<Link key={1} to="/registration-in-us-states" className="!text-black font-semibold">Registrations by US States</Link>]

  useEffect(() => {
    getHorizontalBarchartdata();
  }, [startdate, enddate])

  const options = {
    title: `${constants.top10UsStates} ${startdate} to ${enddate}`,
    titleTextStyle: {
      fontSize: 20,
      bold: 'true',
      color: '#8a1253',
    },
    vAxis: {
      title: 'States',
      textStyle: {
        fontSize: 14,
        color: '#000',
        bold: 'true'
      },
      titleTextStyle: {
        fontSize: 16,
        color: '#8a1253',
        bold: 'true',
      },
    },
    bar: { groupWidth: "30%" },
    bars: "horizontal",
    is3D: true,
    animation: {
      duration: 1000,
      easing: 'linear',
      startup: true
    },
    legend: { position: 'top' },
    'hAxis': {
      title: 'Registrations', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
        fontSize: 16,
        color: '#8a1253',
        bold: 'true',
      },

    },
    colors: ['#beb000'],
    //chartArea: { left: 140, top: 70, width: "90%", height: "80%" },
    tooltip: { isHtml: true },
  };

  const submitDates = (values) => {
  }

  const getInfoAndRedirect = (record, index) => {
    if (startdate && enddate) {
      let stateRegistrationData = {};
      stateRegistrationData.startdate = startdate;
      stateRegistrationData.enddate = enddate;
      stateRegistrationData.country = record.country_id;
      stateRegistrationData.state = record.region_id;
      stateRegistrationData.stateCode = record.region_code;
      stateRegistrationData.countryCode = record.country_code;
      stateRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;
      navigate('/registered-students', { state: { stateRegData: stateRegistrationData } });
    }
  }

  const stateTabColumns = [
    {
      title: 'States',
      key: 'States',
      align: 'left',
      render: (record) => {
        return (
          <div className='linkColor text-sm font-semibold cursor-pointer'
            onClick={() => getInfoAndRedirect(record)}
          > {record.region_name} ({record.region_code})
          </div>
        )
      }
    },
    {
      title: 'Registrations',
      key: 'Registerations',
      align: "right",
      render: (record) => {
        return (
          <div className='linkColor text-sm font-semibold cursor-pointer'
            onClick={() => getInfoAndRedirect(record)}
          > {record.registration_count} 
          </div>
        )
      }
    }
  ]

  const stateRegistrationData = [
    {
      state_id: "3893",
      registration_count: "298",
      state: "TX",
      country_code: "US",
      country_id: "238",
      region_name: "Texas",
      barColor: '#c50d66'
    },
    {
      state_id: "3883",
      registration_count: "272",
      state: "NY",
      country_code: "US",
      country_id: "238",
      region_name: "New York",
      barColor: '#5f6caf'
    },
    {
      state_id: "3854",
      registration_count: "234",
      state: "FL",
      country_code: "US",
      country_id: "238",
      region_name: "Florida",
      barColor: '#77628c'
    },
    {
      state_id: "3887",
      registration_count: "158",
      state: "PA",
      country_code: "US",
      country_id: "238",
      region_name: "Pennsylvania",
      barColor: '#c06c84'
    },
    {
      state_id: "3849",
      registration_count: "25",
      state: "CA",
      country_code: "US",
      country_id: "238",
      region_name: "California",
      barColor: '#fd5959'
    },
    {
      state_id: "3866",
      registration_count: "105",
      state: "MA",
      country_code: "US",
      country_id: "238",
      region_name: "Massachusetts",
    },
    {
      state_id: "3880",
      registration_count: "74",
      state: "NJ",
      country_code: "US",
      country_id: "238",
      region_name: "New Jersey"
    },
    {
      state_id: "3861",
      registration_count: "55",
      state: "IL",
      country_code: "US",
      country_id: "238",
      region_name: "Illinois"
    },
    {
      state_id: "3870",
      registration_count: "50",
      state: "MI",
      country_code: "US",
      country_id: "238",
      region_name: "Michigan"
    },
    {
      state_id: "3872",
      registration_count: "49",
      state: "MO",
      country_code: "US",
      country_id: "238",
      region_name: "Missouri"
    },
    {
      state_id: "3848",
      registration_count: "43",
      state: "AZ",
      country_code: "US",
      country_id: "238",
      region_name: "Arizona"
    },
    {
      state_id: "3899",
      registration_count: "39",
      state: "WI",
      country_code: "US",
      country_id: "238",
      region_name: "Wisconsin"
    },
    {
      state_id: "3884",
      registration_count: "33",
      state: "OH",
      country_code: "US",
      country_id: "238",
      region_name: "Ohio"
    },
    {
      state_id: "3895",
      registration_count: "33",
      state: "VA",
      country_code: "US",
      country_id: "238",
      region_name: "Virginia"
    },
    {
      state_id: "3844",
      registration_count: "32",
      state: "AL",
      country_code: "US",
      country_id: "238",
      region_name: "Alabama"
    },
    {
      state_id: "3865",
      registration_count: "32",
      state: "LA",
      country_code: "US",
      country_id: "238",
      region_name: "Louisiana"
    },
    {
      state_id: "3874",
      registration_count: "32",
      state: "MS",
      country_code: "US",
      country_id: "238",
      region_name: "Mississippi"
    },
    {
      state_id: "3856",
      registration_count: "30",
      state: "GA",
      country_code: "US",
      country_id: "238",
      region_name: "Georgia"
    },
    {
      state_id: "3885",
      registration_count: "28",
      state: "OK",
      country_code: "US",
      country_id: "238",
      region_name: "Oklahoma"
    },
    {
      state_id: "3867",
      registration_count: "26",
      state: "MD",
      country_code: "US",
      country_id: "238",
      region_name: "Maryland"
    },
    {
      state_id: "3876",
      registration_count: "25",
      state: "NC",
      country_code: "US",
      country_id: "238",
      region_name: "North Carolina"
    },
    {
      state_id: "3890",
      registration_count: "24",
      state: "SC",
      country_code: "US",
      country_id: "238",
      region_name: "South Carolina"
    },
    {
      state_id: "3846",
      registration_count: "23",
      state: "AR",
      country_code: "US",
      country_id: "238",
      region_name: "Arkansas"
    },
    {
      state_id: "3859",
      registration_count: "21",
      state: "IA",
      country_code: "US",
      country_id: "238",
      region_name: "Iowa"
    },
    {
      state_id: "3871",
      registration_count: "21",
      state: "MN",
      country_code: "US",
      country_id: "238",
      region_name: "Minnesota"
    },
    {
      state_id: "3862",
      registration_count: "17",
      state: "IN",
      country_code: "US",
      country_id: "238",
      region_name: "Indiana"
    },
    {
      state_id: "3892",
      registration_count: "17",
      state: "TN",
      country_code: "US",
      country_id: "238",
      region_name: "Tennessee"
    },
    {
      state_id: "3863",
      registration_count: "16",
      state: "KS",
      country_code: "US",
      country_id: "238",
      region_name: "Kansas"
    },
    {
      state_id: "3864",
      registration_count: "16",
      state: "KY",
      country_code: "US",
      country_id: "238",
      region_name: "Kentucky"
    },
    {
      state_id: "3851",
      registration_count: "12",
      state: "CT",
      country_code: "US",
      country_id: "238",
      region_name: "Connecticut"
    },
    {
      state_id: "3853",
      registration_count: "11",
      state: "DE",
      country_code: "US",
      country_id: "238",
      region_name: "Delaware"
    },
    {
      state_id: "3881",
      registration_count: "11",
      state: "NM",
      country_code: "US",
      country_id: "238",
      region_name: "New Mexico"
    },
    {
      state_id: "3898",
      registration_count: "11",
      state: "WA",
      country_code: "US",
      country_id: "238",
      region_name: "Washington"
    },
    {
      state_id: "3877",
      registration_count: "9",
      state: "ND",
      country_code: "US",
      country_id: "238",
      region_name: "North Dakota"
    },
    {
      state_id: "3891",
      registration_count: "9",
      state: "SD",
      country_code: "US",
      country_id: "238",
      region_name: "South Dakota"
    },
    {
      state_id: "3900",
      registration_count: "7",
      state: "WV",
      country_code: "US",
      country_id: "238",
      region_name: "West Virginia"
    },
    {
      state_id: "3868",
      registration_count: "6",
      state: "ME",
      country_code: "US",
      country_id: "238",
      region_name: "Maine"
    },
    {
      state_id: "3875",
      registration_count: "6",
      state: "MT",
      country_code: "US",
      country_id: "238",
      region_name: "Montana"
    },
    {
      state_id: "3878",
      registration_count: "6",
      state: "NE",
      country_code: "US",
      country_id: "238",
      region_name: "Nebraska"
    },
    {
      state_id: "3879",
      registration_count: "6",
      state: "NH",
      country_code: "US",
      country_id: "238",
      region_name: "New Hampshire"
    },
    {
      state_id: "3886",
      registration_count: "6",
      state: "OR",
      country_code: "US",
      country_id: "238",
      region_name: "Oregon"
    },
    {
      state_id: "3850",
      registration_count: "5",
      state: "CO",
      country_code: "US",
      country_id: "238",
      region_name: "Colorado"
    },
    {
      state_id: "3860",
      registration_count: "5",
      state: "ID",
      country_code: "US",
      country_id: "238",
      region_name: "Idaho"
    },
    {
      state_id: "3843",
      registration_count: "3",
      state: "AK",
      country_code: "US",
      country_id: "238",
      region_name: "Alaska"
    },
    {
      state_id: "3894",
      registration_count: "2",
      state: "UT",
      country_code: "US",
      country_id: "238",
      region_name: "Utah"
    },
    {
      state_id: "3889",
      registration_count: "1",
      state: "RI",
      country_code: "US",
      country_id: "238",
      region_name: "Rhode Island"
    },
    {
      state_id: "3901",
      registration_count: "1",
      state: "WY",
      country_code: "US",
      country_id: "238",
      region_name: "Wyoming"
    },
    {
      state_id: "3858",
      registration_count: "0",
      state: "HI",
      country_code: "US",
      country_id: "238",
      region_name: "Hawaii"
    },
    {
      state_id: "3882",
      registration_count: "0",
      state: "NV",
      country_code: "US",
      country_id: "238",
      region_name: "Nevada"
    },
    {
      state_id: "3897",
      registration_count: "0",
      state: "VT",
      country_code: "US",
      country_id: "238",
      region_name: "Vermont"
    }
  ]

  // const getHorizontalBarchartdata = (arr) => {
  //   if (arr.length > 0) {
  //     let chartHeader = [['States', 'Registrations', { role: 'style' }, { role: 'annotation' }]];
  //     for (let index = 0; index < arr.length; index++) {
  //       if (arr[index].registration_count) {
  //         let all = [];
  //         all.push(arr[index].region_name, Number(arr[index].registration_count), arr[index].barColor, arr[index].state);
  //         console.log(all);
  //         chartHeader.push(all);
  //         var firstFive = chartHeader.slice(0,6);
  //         console.log(firstFive);
  //         console.log(chartHeader);
  //       }
  //     }
  //     setStateregisterationData(firstFive);
  //   }
  // }
 

  const getStartandEnddate = (value, dateString) => {
    setPeriod(null);
    setStartdate(dateString[0]);
    setEnddate(dateString[1]);
  }

  const getHorizontalBarchartdata = () => {
    fetchAdminApi(`/total-registrations-US?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
      (res) => {
        if (res?.data) {
          setStateregisterationData(res.data);
          setFilteredstates(res.data);
          let chartHeader = [['States', 'Registrations', { role: 'annotation' }]];
          let totalRegPoints = 0;
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].registration_count) {
              let all = [];
              all.push(res.data[index].region_name, Number(res.data[index].registration_count), res.data[index].registration_count);
              chartHeader.push(all);
              totalRegPoints += Number(res.data[index].registration_count)
              setRegistrationcount(totalRegPoints);
              // var firstTen = chartHeader.slice(0, 11);
              // console.log(firstTen);
              // let onlyStates = firstTen.slice(1);
              // console.log(onlyStates);
              // var onlyTen = [];
              // for (var i = 0; i < onlyStates.length; i++) {
              //   onlyTen.push(onlyStates[i][1]);
              // }
              // console.log(onlyTen);
              // let sum = 0;
              // for (let i = 0; i < onlyTen.length; i++) {
              //   sum += onlyTen[i];
              // }
              // console.log(sum);
              // setFirstfivestateregistrationcount(sum);

              if (chartHeader.length > 10) {
               
                var firstTen = chartHeader.slice(0, 11);
                chartHeader = firstTen;
              }
            }
          }
          setStateregisterationData(chartHeader);
          setRegistrationcount(totalRegPoints);
        } else {
          setRegistrationcount(0);
          setFilteredstates([]);
        }
      })
  }
  
  const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value)
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

  return (
    <div className='cursorChange'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className='p-4 min-h-screen bg-gray-100'>
            <div>
              <Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
                className='bg-neutral-100 shadow-md p-3 mb-3' />
            </div>
            <Card title="REGISTRATIONS BY US STATES" className="addQcard" hoverable>
              <Row>
                <Col xs={24} md={8} lg={8}>
                  <Table
                    bordered
                    dataSource={filteredstates}
                    columns={stateTabColumns}
                    className='registrationTable'
                    rowKey={(record) => record.region_name}
                    title={() => { return <div className="font-bold text-base text-white">{`REGISTRATIONS`}</div> }}
                    size="small"
                    summary={(pageData) => {
                      console.log(pageData);
                      // let totalPoints = 0;
                      // pageData.forEach(({ registration_count }) => {
                      //   totalPoints += Number(registration_count)
                      //   //setRegistrationcount(totalPoints);
                      // });
                      return (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1} className="font-bold text-base text-right">{registrationcount}</Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      )
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Card hoverable className=" mb-2 ml-3">
                    <Form form={form} onFinish={submitDates} className='countriesByregistrationFilters'>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item label="From">
                            <RangePicker
                              defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
                              format={dateFormat}
                              className='w-full'
                              onChange={getStartandEnddate}
                              allowClear={false}
                              value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Period">
                            <Select className='w-full rounded-md'
                              placeholder={constants.Period}
															onChange={onChangePeiod}
                              value={period}
                            >
                              {constants.periodOptions.map((option, index) => (
                                <Select.Option key={option} value={index}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                  <div>
                    <Card className='ml-5' hoverable>
                      {registrationcount != 0 ?
                        <Chart
                          chartType="BarChart"
                          width={'100%'}
                          height={'600px'}
                          loader={<div>Loading Chart</div>}
                          data={stateregisterationdata}
                          options={options}
                        />
                        :
                        <div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
                      }
                    </Card>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RegistrationsByUSstates
