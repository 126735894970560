import React,{useState,useEffect} from 'react';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Chart } from "react-google-charts";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Empty,
	Button
} from 'antd';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';

const RegisterationsByCountries = (props) => {
	const [form] = Form.useForm();
	const { RangePicker } = DatePicker;
	const navigate = useNavigate();
	const [year, setYear] = useState(dayjs().year());
	const [countryregisterationdata, setCountryregisterationData] = useState([]);
	const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [filteredcountries, setFilteredCountries] = useState([]);
	const [registrationcount, setRegistrationcount] = useState();
	const [period,setPeriod] = useState(0);
	const dateFormat = 'MM/DD/YYYY';
	const newCrumb = [<Link key={1} to="/registration-by-country" className="!text-black font-semibold">Registrations by Countries</Link>]
	

	useEffect(()=>{
		getHorizontalBarchartdata();
		if (startdate&&enddate){
			const initialYear = startdate.slice(0,4);
			const endYear = enddate.slice(0, 4);
			setYear(initialYear || endYear);
		}
	}, [startdate, enddate]);

	const countryTabColumns = [
		{
			title: 'Countries',
			key: 'Country',
			align:'left',
			render: (record) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer'
						onClick={()=>getInfoAndRedirect(record)}
					> {record.country_name} ({record.country_code})
					</div>
				)
			}
		},
		{
			title: 'Registrations',
			key: 'Registerations',
			align: "right",
			render: (record) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer'
						onClick={() => getInfoAndRedirect(record)}
					> {record.registration_count}
					</div>
				)
			}
		}
	]

	const countryRegistrationData = [
		{
			country_id: "238",
			country_code: "US",
			country_name: "United States",
			registration_count: "2080",
			barColor:'#7b99fa',
		},
		{
			country_id: "203",
			country_code: "SG",
			country_name: "Singapore",
			registration_count: "500",
			barColor: '#22b2da',
		},
		{
			country_id: "198",
			country_code: "SA",
			country_name: "Saudi Arabia",
			registration_count: "200",
			barColor: '#f1d18a',
		},
		{
			country_id: "187",
			country_code: "PR",
			country_name: "Puerto Rico",
			registration_count: "230",
			barColor: '#efd510',
		},
		{
			country_id: "182",
			country_code: "PH",
			country_name: "Philippines",
			registration_count: "600",
			barColor: 'teal',
		},
		{
			country_id: "110",
			country_code: "IN",
			country_name: "India",
			registration_count: "580",
			barColor: '#906387',
		},
		{
			country_id: "108",
			country_code: "IL",
			country_name: "Israel",
			registration_count: "190",
			barColor: '#7dd87d',
		},
		{
			country_id: "107",
			country_code: "IE",
			country_name: "Ireland",
			registration_count: "700",
			barColor: '#ff8364',
		},
		{
			country_id: "100",
			country_code: "HK",
			country_name: "Hong Kong",
			registration_count: "450",
			barColor: 'blue',
		},
		{
			country_id: "61",
			country_code: "DE",
			country_name: "Germany",
			registration_count: "345",
			barColor: '#9d53c3',
		},
		{
			country_id: "53",
			country_code: "CO",
			country_name: "Colombia",
			registration_count: "100",
			barColor: 'orange',
		},
		{
			country_id: "50",
			country_code: "CL",
			country_name: "Chile",
			registration_count: "78",
			barColor: '#cf7979',
		},
		{
			country_id: "42",
			country_code: "CA",
			country_name: "Canada",
			registration_count: "290",
			barColor: 'green',
		},
		{
			country_id: "14",
			country_code: "AR",
			country_name: "Argentina",
			registration_count: "876",
			barColor: '#9f1e49',
		},
		{
			country_id: "5",
			country_code: "AE",
			country_name: "United Arab Emirates",
			registration_count: "1500",
			barColor: '#506f86',
		}
	]

	const options = {
		title:`Country Registrations for the period ${startdate} - ${enddate}`,
		titleTextStyle: {
			fontSize: 20,
			bold: 'true',
			color:'#8a1253',
		},
		vAxis: {
			title: 'Countries', 
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "10%" },
		bars: "horizontal",
		is3D: true,
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		legend: { position: 'top' },
		'hAxis': {
			title: 'Registrations', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
			minValue: 3,
			viewWindow: {
				min: 0
			},
},
		colors: ['#a7b99e'],

	//	chartArea: { left: 140, top: 70, width: "90%", height: "80%" },
		tooltip: { isHtml: true },
	};

	const getHorizontalBarchartdata = () => {
		fetchAdminApi(`/students-by-country?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
			(res) => {
				if (res?.data) {
					setCountryregisterationData(res.data);
					setFilteredCountries(res.data);
					let chartHeader = [['Countries', 'Registrations', { role: 'annotation' }]];
					let totalRegPoints = 0;
					for (let index = 0; index < res.data.length; index++) {
						if (res.data[index].registration_count) {
							let all = [];
							all.push(res.data[index].country_name, Number(res.data[index].registration_count), res.data[index].registration_count);
							chartHeader.push(all);
							totalRegPoints += Number(res.data[index].registration_count)
							setRegistrationcount(totalRegPoints);
							if(chartHeader.length > 10){
								 let firstTen = chartHeader.slice(0, 11);
								chartHeader = firstTen;
							}
						}
					}
					setCountryregisterationData(chartHeader);
					setRegistrationcount(totalRegPoints);
					setFilteredCountries(res?.data);
				}else{
					setRegistrationcount(0);
					setFilteredCountries([]);
				}
			})
	}

	const submitDates = (values)=>{
	}

	const getInfoAndRedirect = (record,index) => {
		if (startdate&&enddate) {
			let countryRegistrationData = {};
			countryRegistrationData.startdate = startdate;
			countryRegistrationData.enddate = enddate;
			countryRegistrationData.country = record.country_id;
			countryRegistrationData.countryCode = record.country_code;
			countryRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;
			navigate('/registered-students', { state: { countryRegData: countryRegistrationData }});
		}
	}

	const getStartandEnddate = (value,dateString) => {
		setPeriod(null);
		setStartdate(dateString[0]);
		setEnddate(dateString[1]);
	}
	
	const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value)
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

	return (
		<div className='cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title="REGISTRATIONS BY COUNTRY" className="addQcard" hoverable>
							<Row>
								<Col xs={24} md={8} lg={8}>
									<Table
										bordered
										pagination={{ pageSize: 10 }}
										dataSource={filteredcountries}
										columns={countryTabColumns}
										className='registrationTable'
										rowKey={(record) => record.country_id}
										title={() => { return <div className="font-bold text-base text-white">{`REGISTRATIONS`}</div> }}
										size="small"
										summary={() => {
											return(
											<Table.Summary fixed>
												<Table.Summary.Row>
													<Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
														<Table.Summary.Cell index={1} className="font-bold text-base text-right">{registrationcount}</Table.Summary.Cell>
												</Table.Summary.Row>
											</Table.Summary>
											)
										}}
									/>
								</Col>
								<Col xs={24} sm={24} md={16} lg={16}>
									<Card hoverable className=" mb-2 ml-3">
										<Form form={form} onFinish={submitDates} className='countriesByregistrationFilters'>
											<Row gutter={24}>
												<Col span={12}>
													<Form.Item label="From">
														<RangePicker
														defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														format={dateFormat} 
														className='w-full' 
														onChange={getStartandEnddate}
														value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														allowClear={false}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label="Period">
														<Select className='w-full rounded-md' 
															placeholder={constants.Period}
															onChange={onChangePeiod}
															value={period}
														>
															{constants.periodOptions.map((option, index) => (
																<Select.Option key={option} value={index}>
																	{option}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Card>
									<div>
										<Card className='ml-5' hoverable>
										{registrationcount != 0 
										?
										<Chart
											chartType="BarChart"
											className='w-full'
											width={'100%'}
											height={'600px'}
											loader={<div>Loading Chart</div>}
											data={countryregisterationdata}
											options={options}
										/>
										:
											<div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
										}
										</Card>
									</div>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default RegisterationsByCountries
