import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import constants from '../constants/constants';
//Admin pages
import MainAdminLayout from '../Views/Admin/Common/MainAdminLayout';
import Login from '../Views/Admin/Common/Cred/Login';
import QuestionList from '../Views/Admin/Questions/QuestionList';
import Getchapterwisequestions from '../Views/Admin/Questions/Getchapterwisequestions';
import AddQuestion from '../Views/Admin/Questions/AddQuestion';
import EditQuestion from '../Views/Admin/Questions/EditQuestion';
import FlashcardList from '../Views/Admin/Flashcards/FlashcardList';
import GetchapterwiseFlashcard from '../Views/Admin/Flashcards/GetchapterwiseFlashcard';
import AddFlashcard from '../Views/Admin/Flashcards/AddFlashcard';
import EditFlashcard from '../Views/Admin/Flashcards/EditFlashcard';
import ImageList from '../Views/Admin/Images/ImageList';
import GetchapterwiseImage from '../Views/Admin/Images/GetchapterwiseImage';
import AddImage from '../Views/Admin/Images/AddImage';
import EditImage from '../Views/Admin/Images/EditImage';
import StudyGuideList from '../Views/Admin/StudyGuide/StudyGuideList';
import StudyGuideDownloads from '../Views/Admin/StudyGuide/StudyGuideDownloads';
import MasterMenus from '../Views/Admin/Dashboard/Masters/MasterMenus';
import AnalysisMenus from '../Views/Admin/Dashboard/Analysis/AnalysisMenus';
import FeedbackMenus from '../Views/Admin/Dashboard/Feedback/FeedbackMenus';
import RegisterationsByPeriod from '../Views/Admin/Registerations/RegisterationsByPeriod';
import RegisterationsByCountries from '../Views/Admin/Registerations/RegisterationsByCountries';
import RegistrationsByUSstates from '../Views/Admin/Registerations/RegistrationsByUSstates';
import RegistrationsByRoles from '../Views/Admin/Registerations/RegistrationsByRoles';
import RegistrationsByUniversities from '../Views/Admin/Registerations/RegistrationsByUniversities';
import RegistrationsByTopTenUniversities from '../Views/Admin/Registerations/RegistrationsByTopTenUniversities';
import RegisteredStudents from '../Views/Admin/Registerations/RegisteredStudents';
import FeedbackAnalysis from '../Views/Admin/FeedbackAnalysis/FeedbackAnalysis';
import IssueFeedback from '../Views/Admin/FeedbackAnalysis/IssueFeedback';
import AdminDashboard from '../Views/Admin/Dashboard/Home/AdminDashboard';
import ResetPassword from '../Views/Admin/Common/Cred/ResetPassword';
import EditStudent from '../Views/Admin/ManageStudent/EditStudent';
import ResourcesTaken from '../Views/Admin/Resources/ResourcesTaken/ResourcesTaken';
import TotalResourcesTaken from '../Views/Admin/Resources/TotalResourcesTaken/TotalResourcesTaken';
import ResourceResult from '../Views/Admin/Resources/Results/ResourceResult';

//student pages
import StudentLayout from "../Views/Student/Common/StudentLayout";
import ChapterResources from '../Views/Student/Resources/ChapterResources';
import ImageResources from '../Views/Student/Resources/ImageResources';
import Dashboard from '../Views/Student/Common/Dashboard';
import Flashcards from '../Views/Student/Flashcards/Flashcards';
import StartTutorial from '../Views/Student/Tutorial/StartTutorial';
import ReviewTutorial from '../Views/Student/Tutorial/ReviewTutorial';
import TakeTest from '../Views/Student/Test/TakeTest';
import ReviewTest from '../Views/Student/Test/ReviewTest';
import Notes from '../Views/Student/Notes/Notes';
import CompareScore from '../Views/Student/CompareScore/CompareScore';
import MarkedForLaterTutorial from '../Views/Student/Tutorial/MarkedForLaterTutorial';
import Logout from '../Views/Student/Common/Logout';
import TestResults from '../Views/Student/Test/TestResult';
import Instructions from '../Views/Student/Test/Instructions';
import TutorialInstructions from '../Views/Student/Tutorial/TutorialInstructions';
import MarkedIncorrectInstructions from '../Views/Student/Tutorial/MarkedIncorrectInstructions';
import FlashcardInstructions from '../Views/Student/Flashcards/FlashcardInstructions';
import PageNotFound from '../Views/Student/Common/PageNotFound';
import MarkedForLaterImageTutorial from '../Views/Student/Tutorial/MarkedForLaterImageTutorial';
import VideoResources from '../Views/Student/Resources/VideoResources';
import VideoComponent from '../Views/Student/Resources/VideoComponent';
import UniversityListing from '../Views/Admin/Universities/UniversityListing';
import AddEditUniversity from '../Views/Admin/Universities/AddEditUniversity';
import TotalMediaResourcesTaken from '../Views/Admin/Resources/TotalMediaResourcesTaken/TotalMediaResourcesTaken';

  const routerBrowser = createBrowserRouter(
    createRoutesFromElements(
      <>
      <Route path="/" element={<MainAdminLayout  />}>
        <Route exact path="/master-menus" element={<MasterMenus />} />
        <Route exact path="/change-password" element={<ResetPassword />} />
        <Route index path="/question-list" element={<QuestionList />}/>
        <Route index path="/question-list" element={<QuestionList />}/>
        <Route exact path="/get-chapter-questions/:chapterId" element={<Getchapterwisequestions />} />
        <Route exact path="/addQuestion" element={<AddQuestion />} />
        <Route exact path="/editQuestion/:qId" element={<EditQuestion />} />
        <Route exact path="/flashcard-list" element={<FlashcardList />} />
        <Route exact path="/get-chapter-flashcards/:chapterId" element={<GetchapterwiseFlashcard />}/>
        <Route exact path="/addFlashcard" element={<AddFlashcard />} />
        <Route exact path="/edit-flashcard/:flashId" element={<EditFlashcard />} />
        <Route exact path="/image-list" element={<ImageList />} />
        <Route exact path="/get-chapter-images/:chapterId" element={<GetchapterwiseImage />} />
        <Route exact path="/add-image/:chapterId" element={<AddImage />} />
        <Route exact path="/editImage/:chapterid" element={<EditImage />} />
        <Route exact path="/studyGuide-list" element={<StudyGuideList/>} />
        <Route exact path="/analysis-menus" element={<AnalysisMenus />} />
        <Route exact path="/feedback-menus" element={<FeedbackMenus />} />
        <Route exact path="/total-registrations" element={<RegisterationsByPeriod />} />
        <Route exact path="/registration-by-country" element={<RegisterationsByCountries />} />
        <Route exact path="/registration-in-us-states" element={<RegistrationsByUSstates />} />
        <Route exact path="/registration-by-roles" element={<RegistrationsByRoles />} />
        <Route exact path="/registration-by-university" element={<RegistrationsByTopTenUniversities />} />
        <Route exact path="/registration-by-all-universities" element={<RegistrationsByUniversities />} />
        <Route exact path="/chapter-feedback" element={<FeedbackAnalysis />} />
        <Route exact path="/issue-feedback" element={<IssueFeedback />} />
        <Route exact path="/" element={<AdminDashboard />} />
        <Route exact path="/registered-students" element={<RegisteredStudents />} />
        <Route exact path="/view-student" element={<EditStudent />} />
        <Route exact path="/edit-student" element={<EditStudent />} />
        <Route exact path="/total-tests-taken" element={<ResourcesTaken />} />
        <Route exact path="/total-tutorials-taken" element={<ResourcesTaken />} />
        <Route exact path="/total-flashcards-taken" element={<ResourcesTaken />} />
        <Route exact path="/test-scores" element={<TotalResourcesTaken />} />
        <Route exact path="/tutorial-scores" element={<TotalResourcesTaken />} />
        <Route exact path="/test-result" element={<ResourceResult />} />
        <Route exact path="/video-result" element={<ResourceResult />} />
        <Route exact path="/image-result" element={<ResourceResult />} />
        <Route exact path="/tutorial-result" element={<ResourceResult />} />
        <Route exact path="/flashcard-result" element={<ResourceResult />} />
        <Route exact path="/study-guide-result" element={<ResourceResult />} />
        <Route exact path="/total-study-guide-downloads" element={<StudyGuideDownloads />} />
        <Route exact path="/study-guide-downloads" element={<StudyGuideDownloads />} />
        <Route exact path="/university-list" element={<UniversityListing />} />
        <Route exact path="/add-university" element={<AddEditUniversity />} />
        <Route exact path="/edit-university" element={<AddEditUniversity />} />
        <Route exact path="/total-video-views" element={<TotalMediaResourcesTaken />} />
        <Route exact path="/total-image-views" element={<TotalMediaResourcesTaken />} />
    </Route>
      <Route exact path="/admin-login" element={<Login/>} />
      <Route exact path="/logout" element={<Logout/>} />
      <Route path="/dashboard" index element={<Dashboard />} />
      <Route exact element={<StudentLayout />}>
        <Route path="/chapter-resources" index element={<ChapterResources />} />
				<Route path="/video-resources" index element={<VideoResources />} />
				<Route path="/video" index element={<VideoComponent />} />
        <Route path="/image-resources" index element={<ImageResources />} />
        <Route path="/flashcards" index element={<Flashcards />} />
        <Route path="/start-tutorial" index element={<StartTutorial />} />
        <Route path="/tutorial-review" index element={<ReviewTutorial />} />
        <Route path="/take-test" index element={<TakeTest />} />
        <Route path="/review-test" index element={<ReviewTest />} />
        <Route path="/flashcard-test" index element={<Flashcards />} />
        <Route path="/notes" index element={<Notes />} />
        <Route path="/compare-score" index element={<CompareScore/>} />
        <Route path="/marked-incorrect-tutorial" index element={<MarkedForLaterTutorial/>} />
        <Route path="/test-results" index element={<TestResults/>} />
        <Route path="/test-instructions" index element={<Instructions/>} />
        <Route path="/tutorial-instructions" index element={<TutorialInstructions/>} />
        <Route path="/marked-incorrect-instructions" index element={<MarkedIncorrectInstructions/>} />
        <Route path="/flashcard-instructions" index element={<FlashcardInstructions/>} />
        <Route path="/image-tutorial" index element={<MarkedForLaterImageTutorial/>} />
      </Route>
        <Route exact path="*" element={<PageNotFound/>}/>
      </>
    ),
    {
      basename: constants.BaseUrl,
    }
  );


export default routerBrowser;
