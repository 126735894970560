import React,{useState,useEffect} from 'react';
import {MinusOutlined, CloseOutlined} from "@ant-design/icons";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Badge,
	Form,
	DatePicker,
	Select,
	Tooltip,
	Radio
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const FeedbackAnalysis = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [feedbackdata, setFeedbackdata] = useState([]);
	const [chapterfeedbackdata, setChapterFeedbackdata] = useState([]);
	const [positivefeedback,setPositivefeedback]= useState();
	const [negativefeedback, setNegativefeedback]= useState();
	const [positivefeedbackchapter, setPositivefeedbackChapter] = useState();
	const [negativefeedbackchapter, setNegativefeedbackChapter] = useState();
	const [startdateoverall, setStartdateOverall] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddateoverall, setEnddateOverall] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [feedbacktype, setFeedbacktype] = useState(1);
	const [chapters,setChapters] = useState([]);
	const [period,setPeriod] = useState(0);
	const [chapterFeedback,setChapterFeedback] = useState(false);
	const [chapterid,setChapterid] = useState('');
	const [startdatechapterfd, setStartdateChapterfd] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatechapterfd, setEnddateChapterfd] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [usertype, setUserType] = useState();
	const newCrumb = [<Link key={1} to="/chapter-feedback" className="!text-black font-semibold">Chapter Feedback </Link>]

	const dateFormat = 'MM/DD/YYYY';
	const getChapterIds = (value)=>{
		setChapterid(value);
		if(value && value != ''){
			getchapterwiseFeedbackdata(value);
		}else{
			getallchaptersFeedbackdata();
		}
	}
	useEffect(()=>{
		if (localStorage.getItem('userType')) {
			const user = localStorage.getItem('userType');
			setUserType(user);
		}
	},[])
	useEffect(()=>{
		getoverallFeedbackdata();
	}, [startdateoverall, enddateoverall])

	useEffect(()=>{
		if (feedbacktype == 2){
			getallchaptersFeedbackdata();
		}
	}, [startdatechapterfd, enddatechapterfd])

	
	useEffect(() => {
		if (feedbacktype == 2){
			setChapterFeedback(true);
			getAllTheChapters();
			setPeriod(0);
		}else{
			setChapterFeedback(false);
			setPeriod(0);
		}
	}, [feedbacktype])

	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				const chaptertypeOps = res?.data.map((each, index) => {
					return {
						value: index + 1,
						label: each.chapter
					}
				})
				let stringifiedChapters = JSON.stringify(chaptertypeOps);
				localStorage.setItem('chapterDetails', stringifiedChapters);
				const chapters = JSON.parse(localStorage.getItem('chapterDetails'));
				if (chapters && chapters != '') {
					setChapters(chapters);
				}
			} else {
				setChapters([]);
			}
		})
	}

	const getoverallFeedbackdata = () => {
		fetchAdminApi(`/feedback-list?start_date=${changeDateFormat(startdateoverall)}&end_date=${changeDateFormat(enddateoverall)}`, 'get').then((res) => {
			if (res?.data?.feedback_data && res?.data?.feedback_data.length > 0) {
				setFeedbackdata(res?.data?.feedback_data);
				setPositivefeedback(res?.data?.positive);
				setNegativefeedback(res?.data?.negative);
			} else {
				setFeedbackdata([]);
				setPositivefeedback(0);
				setNegativefeedback(0);
			}
		})
	}

	const getallchaptersFeedbackdata = () => {
		fetchAdminApi(`/feedback-list?all=${1}&start_date=${changeDateFormat(startdatechapterfd)}&end_date=${changeDateFormat(enddatechapterfd)}`, 'get').then((res) => {
			if (res?.data?.feedback_data && res?.data?.feedback_data.length > 0) {
				setChapterFeedbackdata(res?.data?.feedback_data);
				setPositivefeedbackChapter(res?.data?.positive);
				setNegativefeedbackChapter(res?.data?.negative)
			} else {
				setChapterFeedbackdata([]);
				setPositivefeedbackChapter(0);
				setNegativefeedbackChapter(0)
			}
		})
	}

	const getchapterwiseFeedbackdata = (chapterid) => {
		fetchAdminApi(`/feedback-list?chapter_id=${chapterid}`, 'get').then((res) => {
			if (res?.data?.feedback_data && res?.data?.feedback_data.length > 0) {
				setChapterFeedbackdata(res?.data?.feedback_data);
				setPositivefeedbackChapter(res?.data?.positive);
				setNegativefeedbackChapter(res?.data?.negative)
			} else {
				setChapterFeedbackdata([]);
				setPositivefeedbackChapter(0);
				setNegativefeedbackChapter(0)
			}
		})
	}

	const getInfoAndRedirect = (record,index)=>{
		let studentfeedbackDetails = {};
		studentfeedbackDetails.studentId = record.stud_id;
		sessionStorage.setItem("studentFromOverallFeedbackpage", JSON.stringify(studentfeedbackDetails));
		window.open('/view-student', '_blank');
	}

	const overallfeedbackTabColumns = [
		{
			title: 'Date',
			key: 'Date',
			dataIndex: 'dt_created',
			align: "center",
			width: 40,
		},
		{
			title: 'Name',
			key: 'Name',
			width: 80,
			render:(text,record,index)=>{
				return (
					usertype == 1 ?
						<>
							<span>{record.stud_firstname.charAt(0).toUpperCase() + '.'}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getInfoAndRedirect(record, index)}><span>{record.stud_firstname.charAt(0).toUpperCase() + record.stud_firstname.slice(1)}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></div>
				)
			}
		},
		{
			title: 'Country',
			key: 'Country',
			dataIndex: 'country',
			align: "center",
			width: 20,
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'state',
			align: "center",
			width: 20,
		},
		{
			title: 'University',
			key: 'University',
			dataIndex: 'university',
			width: 210,
		},
		{
			title: 'How did you hear about this book?',
			key: 'How did you hear about this book?',
			width:120,
			render: (record)=>{
				switch (Number(record.product_source)) {
					case 0:
						return <span>Through a friend/colleague</span> 
					case 1:
						return <span>Social media</span> 
					case 2:
						return <span>ASHA conference</span> 
					case 3:
						return <span>State conference</span>
					case 4:
						return <span>Amazon</span>
					case 5:
						return <span>Internet</span>
					case 6:
						return <span>Other</span>
				}
			}
		},
		{
			title: 'The tests were challenging',
			key: 'The tests were challenging',
			width: 60,
			render: (record) => {
				switch (Number(record.test_rating)) {
					case 0:
						return <span className='positiveColor'>Agree</span>
					case 1:
						return <span className='negativeColor'>Undecided</span>
					case 2:
						return <span className='negativeColor'>Disagree</span>
				}
			}
		},
		{
			title: 'Overall rating of the product',
			key: 'Overall rating of the product',
			width: 60,
			render: (record) => {
				switch (Number(record.product_rating)) {
					case 0:
						return <span className='positiveColor'>Excellent</span>
					case 1:
						return <span className='positiveColor'>Very Good</span>
					case 2:
						return <span className='positiveColor'>Good</span>
					case 3:
						return <span className='negativeColor'>Fair</span>
				}
			}
		},
		{
			title: 'Comments',
			key: 'Comments',
			width: 10,
			render:(record)=>{
				if (record.overall_comments != null){
					return <Tooltip title={record.overall_comments}><div className="cursor-default"><Badge.Ribbon text="Comment" color="#a75265" /></div></Tooltip>
				}else{
					return <div className="cursor-text"></div>
				}
			}
		}
	]

	const getfeedbackInfoAndRedirect = (record, index) => {
		let studentchapterfeedbackDetails = {};
		studentchapterfeedbackDetails.studentId = record.stud_id;
		console.log(studentchapterfeedbackDetails);
		sessionStorage.setItem("studentFromChapterFeedbackpage", JSON.stringify(studentchapterfeedbackDetails));
		window.open('/view-student', '_blank');
		//navigate('/view-student', { state: { studentDatafromChapterFeedback: studentchapterfeedbackDetails } });
	}

	const chapterfeedbackTabColumns = [
		{
			title: 'Date',
			key: 'Date',
			dataIndex: 'dt_created',
			align: "center",
		},
		{
			title: 'Name',
			key: 'Name',
			render: (text,record,index) => {
				// return <span>{record.stud_firstname.charAt(0).toUpperCase() + record.stud_firstname.slice(1)}, {record.stud_lastname.charAt(0).toUpperCase()}</span>
				return (
					usertype == 1 ?
						<>
							<span>{record.stud_firstname.charAt(0).toUpperCase() + '.'}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getfeedbackInfoAndRedirect(record, index)}><span>{record.stud_firstname.charAt(0).toUpperCase() + record.stud_firstname.slice(1)}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></div>
				)
			}
		},
		{
			title: 'Country',
			key: 'Country',
			dataIndex: 'country',
			align: "center",
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'state',
			align: "center",
		},
		{
			title: 'University',
			key: 'University',
			dataIndex: 'university',
		},
		{
			title: 'Ch #',
			key: 'Chapter',
			dataIndex: 'chapter_id',
		},
		{
			title: <Tooltip title='Study Guides'><span>SG</span></Tooltip>,
			key: 'SG',
			align: "center",
			render: (record) => {
				switch (Number(record.studyguide_rating)) {
					case 0:
						return (<Tooltip title='Excellent'><span className='positiveColor'>E</span></Tooltip>)
					case 1:
						return (<Tooltip title='Good'><span className='positiveColor'>G</span></Tooltip>)
					case 2:
						return (<Tooltip title='Helpful'><span className='positiveColor'>H</span></Tooltip>)
					case 3:
						return (<Tooltip title='Unhelpful'><span className='negativeColor'><CloseOutlined /></span></Tooltip>)
					case 4:
						return (<Tooltip title='Undecided'><span className='negativeColor font-bold'><MinusOutlined/></span></Tooltip>)
				}
			}
		},
		{
			title: <Tooltip title='Images'><span>I</span></Tooltip>,
			key: 'Image',
			align: "center",
			render: (record) => {
				switch (Number(record.images_rating)) {
					case 0:
						return (<Tooltip title='Excellent'><span className='positiveColor'>E</span></Tooltip>)
					case 1:
						return (<Tooltip title='Good'><span className='positiveColor'>G</span></Tooltip>)
					case 2:
						return (<Tooltip title='Helpful'><span className='positiveColor'>H</span></Tooltip>)
					case 3:
						return (<Tooltip title='Unhelpful'><span className='negativeColor'><CloseOutlined /></span></Tooltip>)
					case 4:
						return (<Tooltip title='Undecided'><span className='negativeColor'><MinusOutlined/></span></Tooltip>)
				}
			}
		},

		{
			title: <Tooltip title='Flashcards'><span>F</span></Tooltip>,
			key: 'Flashcard',
			align: "center",
			render: (record) => {
				switch (Number(record.flashcards_rating)) {
					case 0:
						return (<Tooltip title='Excellent'><span className='positiveColor'>E</span></Tooltip>)
					case 1:
						return (<Tooltip title='Good'><span className='positiveColor'>G</span></Tooltip>)
					case 2:
						return (<Tooltip title='Helpful'><span className='positiveColor'>H</span></Tooltip>)
					case 3:
						return (<Tooltip title='Unhelpful'><span className='negativeColor'><CloseOutlined /></span></Tooltip>)
					case 4:
						return (<Tooltip title='Undecided'><span className='negativeColor'><MinusOutlined/></span></Tooltip>)
				}
			}
		},

		{
			title: <Tooltip title='Tutorials'><span>Tu</span></Tooltip>,
			key: 'Tutorial',
			align: "center",
			render: (record) => {
				switch (Number(record.tutorials_rating)) {
					case 0:
						return (<Tooltip title='Excellent'><span className='positiveColor'>E</span></Tooltip>)
					case 1:
						return (<Tooltip title='Good'><span className='positiveColor'>G</span></Tooltip>)
					case 2:
						return (<Tooltip title='Helpful'><span className='positiveColor'>H</span></Tooltip>)
					case 3:
						return (<Tooltip title='Unhelpful'><span className='negativeColor'><CloseOutlined /></span></Tooltip>)
					case 4:
						return (<Tooltip title='Undecided'><span className='negativeColor'><MinusOutlined/></span></Tooltip>)
				}
			}
		},
		{
			title: <Tooltip title='Tests'><span>T</span></Tooltip>,
			key: 'Tests',
			align: "center",
			render: (record) => {
				switch (Number(record.tests_rating)) {
					case 0:
						return (<Tooltip title='Excellent'><span className="positiveColor">E</span></Tooltip>)
					case 1:
						return (<Tooltip title='Good'><span className='positiveColor'>G</span></Tooltip>)
					case 2:
						return (<Tooltip title='Helpful'><span className='positiveColor'>H</span></Tooltip>)
					case 3:
						return (<Tooltip title='Unhelpful'><span className='negativeColor'><CloseOutlined /></span></Tooltip>)
					case 4:
						return (<Tooltip title='Undecided'><span className='negativeColor'><MinusOutlined/></span></Tooltip>)
				}
			}
		},
		{
			title: 'Comments',
			key: 'Comments',
			width: 10,
			render: (record) => {
				if (record.overall_comments != null) {
					return <Tooltip title={record.overall_comments}><div className="cursor-default"><Badge.Ribbon text="Comment" color="#a75265" /></div></Tooltip>
				} else {
					return <div className="cursor-text"></div>
				}
			}
		}
	]

	const submitData = (values)=>{
	}

	const changeFeedbackType = (e) => {
		setFeedbacktype(e.target.value);
		if(e.target.value == 2){
			getallchaptersFeedbackdata();
		}else{
			getoverallFeedbackdata();
		}
	};

	const getStartandEnddate = (value, dateString) => {
		setPeriod(null);
		setStartdateOverall(dateString[0]);
		setEnddateOverall(dateString[1]);
	}

	const getStartandEnddateforchapterfd = (value, dateString)=>{
		setPeriod(null);
		setStartdateChapterfd(dateString[0]);
		setEnddateChapterfd(dateString[1]);
	}

	const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value)
		if (!chapterFeedback) {
			setStartdateOverall(dayjs(sted?.startDate).format(constants.DateFormat));
			setEnddateOverall(dayjs(sted?.endDate).format(constants.DateFormat));
		} else {
			setStartdateChapterfd(dayjs(sted?.startDate).format(constants.DateFormat));
			setEnddateChapterfd(dayjs(sted?.endDate).format(constants.DateFormat));
		}
	};
	
	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.feedbackPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card hoverable className="addQcard feedbacktypeselect mb-2" title="FEEDBACK DATA"
						extra={
							<div className='flex items-center justify-center'>
								<Radio.Group onChange={changeFeedbackType} value={feedbacktype}>
									<Radio value={1} className='text-lg font-bold text-violet-950'>Overall feedback</Radio>
									<Radio value={2} className='text-lg font-bold text-violet-950'>Chapter feedback</Radio>
								</Radio.Group>
							</div>
						}
						>
						</Card>
						<Card hoverable className="mb-2">
							<Form form={form} onFinish={submitData} className='countriesByregistrationFilters'>
								<Row gutter={24}>
									<Col span={8}>
										<Form.Item label="From">
											{!chapterFeedback ?
												<RangePicker
													defaultValue={[dayjs(startdateoverall, dateFormat), dayjs(enddateoverall, dateFormat)]}
													format={dateFormat}
													className='w-full'
													onChange={getStartandEnddate}
													value={[dayjs(startdateoverall, dateFormat), dayjs(enddateoverall, dateFormat)]}
													allowClear={false}
												/>
												:
												<RangePicker
													defaultValue={[dayjs(startdatechapterfd, dateFormat), dayjs(enddatechapterfd, dateFormat)]}
													format={dateFormat}
													className='w-full'
													onChange={getStartandEnddateforchapterfd}
													value={[dayjs(startdatechapterfd, dateFormat), dayjs(enddatechapterfd, dateFormat)]}
													allowClear={false}
												/>
											}											
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Period">
											<Select
												className='w-full rounded-md'
												placeholder={constants.Period}
												onChange={onChangePeiod}
												value={period}
											>
												{constants.periodOptions.map((option, index) => (
													<Select.Option key={option} value={index}>
														{option}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									{chapterFeedback ?
										<Col span={8}>
											<Form.Item label="Chapter">
												<Select className='w-full rounded-md' value={chapterid} onChange={getChapterIds}>
													<Select.Option value=""> --All Chapters-- </Select.Option> 
													{chapters.map((option, index) => {
														return <Select.Option key={option.value} value={
															option.value.toString()
														}> {option.label} </Select.Option>
													})}
												</Select>
											</Form.Item>
										</Col>
										:
										<div></div>
									}
								</Row>
							</Form>
						</Card>
						<Card title= {chapterFeedback ?'CHAPTER FEEDBACK' : 'OVERALL FEEDBACK'}
						className="addQcard" 
						extra={
							<div className="flex">
								<div className='text-base font-semibold text-emerald-600 mr-3'>Positive feedback : <span>{!chapterFeedback ? positivefeedback : positivefeedbackchapter}</span></div>
								<div className='text-base font-semibold text-rose-600'>Negative feedback : <span>{!chapterFeedback ? negativefeedback : negativefeedbackchapter}</span></div>
							</div>
									} 
						hoverable
						>
							{!chapterFeedback && 
								<Table
									className='registeredStudsTable'
									bordered
									scroll={{ x: 768 }}
									pagination={false}
									columns={overallfeedbackTabColumns}
									dataSource={feedbackdata}
									rowKey={(record) => record.id}
									locale={{ emptyText: constants.emptyTableText }}
								/>
							}
							{chapterFeedback &&
								<Table
									className='registeredStudsTable'
									bordered
									scroll={{ x: 768 }}
									columns={chapterfeedbackTabColumns}
									dataSource={chapterfeedbackdata}
									rowKey={(record) => record.id}
									locale={{ emptyText: constants.emptyTableText }}
								/>
							}
							
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default FeedbackAnalysis
