import { StarFilled, TrophyOutlined, ZoomOutOutlined, ZoomInOutlined, CloseCircleOutlined, HourglassOutlined,EyeOutlined,EyeInvisibleOutlined,PauseCircleOutlined,CheckOutlined,ClockCircleOutlined,FlagOutlined,ReadOutlined } from "@ant-design/icons";
import { Button,Tooltip,Space,notification, Divider } from 'antd';
// function used to trim a string (to browsers that doesn't suppot trim() function)
import { Modal, Table,Col,Image } from 'antd';
import constants from "../constants/constants";
export const featuredImageUrl=(featuredImg,originalImage,visiblePreview,setVisiblePreview,question_text)=>{
	return(<span>
		<Image 
			width="auto" 
			src={featuredImg} 
			alt="originalImage"
			rootClassName="img-prev-2"
			preview={{
				src:originalImage,
				visible:visiblePreview,
				onVisibleChange: (value) => {
					setVisiblePreview(value);
				},
				mask: (
					<Space direction="vertical" align="center">
					Click to zoom
					</Space>
				),
				closeIcon: <CloseCircleOutlined className='hidden' />,
				toolbarRender: (
					_,
					{ transform: { scale }, actions: { onZoomOut, onZoomIn } }
				) => (
						<div className="w-full lg:w-3/5 toolbar-wrapper text-base flex items-center justify-between top-20 translate-x-[-50%] left-1/2 px-6 text-black rounded-full bg-white">
							<div className='my-1.5'>
								<p className="mb-0 q-preview" dangerouslySetInnerHTML={{ __html: question_text }}></p>
							</div>
							<Space size={12} className='my-2'>
								<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
								<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
								<CloseCircleOutlined className='text-red-600' onClick={() => { setVisiblePreview(false) }} />
							</Space>
						</div>
				)
			}}
		/>
	</span>);
};
export const testModalPopup = (
	pauseTest,
	completeTest,
	categoryDetails,
	topicDetails,
	viewTimer,
	timerDisplay,
	toggleTimer,
	attemptedQuestions,
	pauseTestAnswer,
	saveNotes,
	pauseTestLoading,
	setPauseTest,
	fullLengthDetails,
	checkAnswers,
	completeTestApi,
	completeTestLoading,
	lastTenMinutes,
	setLastTenMinutes,
	markedLaterVisible,
	handleOkMarkForLaterModal,
	timeOutVisible,
	handleTimeOutOk,
	caseStudyVisible,
	handleCaseStudyOk,
	testName,
	stopBlockerCall
)=>{
	let listQuestions=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
	return(
		<div>
			<Modal
				open={pauseTest?true:completeTest?true:lastTenMinutes?true:markedLaterVisible?true:timeOutVisible?true:caseStudyVisible?true:false}
				maskClosable={false}
				title={
					// Header of the Popup starts here.
					<>
						<div>Test - Attempt #{testName}{/* {categoryDetails&&categoryDetails!=={}&&categoryDetails!==null?
							categoryDetails.catType?
								categoryDetails.catType:""
							:""
						} */}
						{topicDetails&&topicDetails!=={}&&topicDetails!==null?
							topicDetails.topicTitle?
								topicDetails.topicTitle:""
							:""}
						{(topicDetails&&topicDetails!=={}&&topicDetails!==null&&topicDetails.topicTitle&&topicDetails.topicTitle.length>=constants.mimimumCharactersPopup)?
							<div className="pausetime-wrapper">
								{topicDetails&&topicDetails!=={}&&topicDetails!==null?
									topicDetails.topicTitle?
										topicDetails.isRetake===true?" (2nd Attempt) 25 questions":" (1st Attempt) 25 questions":""
									:""}
							</div>:
							<>
								{topicDetails&&topicDetails!=={}&&topicDetails!==null?
									topicDetails.topicTitle?
										topicDetails.isRetake===true?" (2nd Attempt) 25 questions":" (1st Attempt) 25 questions":""
									:""}
							</>
						}
						{categoryDetails&&categoryDetails.attempt!==null?categoryDetails.count===25?categoryDetails.attempt===1?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #1 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
							categoryDetails.attempt===2?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #2 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
								categoryDetails.attempt===3?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #3 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
									categoryDetails.attempt===4?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #4 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
										categoryDetails.attempt===5?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #5 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
											categoryDetails.attempt===6?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #6 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):""
							:categoryDetails.count===50?
								categoryDetails.attempt===1?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #3 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):
									categoryDetails.attempt===2?categoryDetails.isRetake===true?categoryDetails.test?categoryDetails.test+" (2nd Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.isResume===true?categoryDetails.test?categoryDetails.test+" (1st Attempt) "+(categoryDetails.count?categoryDetails.count+" questions":""):"":categoryDetails.catType+" #4 (1st Attempt)"+" "+(categoryDetails.count?categoryDetails.count+" questions":""):""
								:""
							:""}
						{fullLengthDetails&&fullLengthDetails.test_id?"Full Length #"+fullLengthDetails.test_id+ "  132 questions":""}
						</div>
						<div className="pausetime-wrapper">
							{viewTimer ? "Time Remaining: "+timerDisplay : ""}
							{categoryDetails&&categoryDetails.isTimed!==null&&
                        categoryDetails.isTimed===true ?
								<Button size="small" className="timerIcon"
									onClick={toggleTimer} 
									icon={viewTimer ?
										<Tooltip title={constants.HideTimer}>
											<EyeOutlined/>
										</Tooltip> : 
										<Tooltip title={constants.ShowTimer}>
											<EyeInvisibleOutlined/>
										</Tooltip>}>
								</Button>:""
							}
							{
								topicDetails&&
                        topicDetails.isTimed!==null&&
                        topicDetails.isTimed===true ?
									<Button size="small" className="timerIcon"
										onClick={toggleTimer} 
										icon={viewTimer ?
											<Tooltip title={constants.HideTimer}>
												<EyeOutlined/>
											</Tooltip> : 
											<Tooltip title={constants.ShowTimer}>
												<EyeInvisibleOutlined/>
											</Tooltip>}>
									</Button>:""
							}
							{
								fullLengthDetails&&
								fullLengthDetails.isTimed!==null&&
								fullLengthDetails.isTimed===true ?
									<Button size="small" className="timerIcon"
										onClick={toggleTimer} 
										icon={viewTimer ?
											<Tooltip title={constants.HideTimer}>
												<EyeOutlined/>
											</Tooltip> : 
											<Tooltip title={constants.ShowTimer}>
												<EyeInvisibleOutlined/>
											</Tooltip>}>
									</Button>:""
							}
						</div>
					</>
					//Modal title ends here.
				}
				footer={false}
				closable={false}
				className="completed-modal"
			>
				{/* Pause test popup contents start here */}
				{pauseTest?<div className="status-container text-center">
					<PauseCircleOutlined className="text-4xl text-gray-400" />
					<h1 className="status-title">Pause Test</h1>
					<div className="status-content">
						<p>You have attempted <span className="navy-2"> {attemptedQuestions}/{listQuestions&&listQuestions.length} </span>questions.<br/>
							{constants.PauseTestText3}</p>
						<p><span>Click here to </span>
							<Button size="small" className="bg-green-700 hover:bg-green-600 !text-white"
								onClick={()=>{
									pauseTestAnswer();
									saveNotes();
									stopBlockerCall();
								}
								} 
								loading={pauseTestLoading}>
                Pause
							</Button> 
						</p>
						<div><Divider className="my-3">OR</Divider></div>
						<p><Button size="small" className="bg-blue-700 text-white" onClick={()=>{setPauseTest(false);}}> Resume </Button>{constants.PauseTestText2}</p>
					</div>
					{/* Pause test popup contents ends here */}
				</div>:
					completeTest?
						<div className="status-container text-center">
							{/* Complete test popup contents start here */}
							<CheckOutlined className="text-4xl text-green-600" />
							<h1 className="status-title">You Have Completed The Test</h1>
							<div className="status-content status-container">
								<p>{constants.CompleteTestText}<Button size="small" className="bg-blue-700 text-white" onClick={()=>{checkAnswers();}}> Check </Button>{constants.CompleteTestText3}</p>
								<div><Divider className="my-3">OR</Divider></div>
								<p><Button size="small" className="bg-green-700 hover:bg-green-600 !text-white" onClick={()=>{stopBlockerCall();saveNotes();completeTestApi();}} loading={completeTestLoading}>Finish </Button>{constants.CompleteTestText2}</p>
							</div>
							{/* Complete test popup contents ends here */}
						</div>
						:lastTenMinutes?
							<div className="status-container text-center">
								{/* Last 5 minutes for short length test and 10 minutes left for test popup starts here */}
								<ClockCircleOutlined className="text-4xl text-gray-400" />
								<h1 className="status-title">{categoryDetails&&categoryDetails.type==="normal"||topicDetails&&topicDetails.type==="normal"?constants.FiveMinutesTitle:constants.TenMinutesTitle}</h1>
								<div className="status-content">
									<p>{categoryDetails&&categoryDetails.type==="normal"||topicDetails&&topicDetails.type==="normal"?constants.LastFiveMinutes:constants.LastTenMinutes}</p>
									<p> Click <Button size="small" className="bg-blue-700 text-white" onClick={()=>{setLastTenMinutes(false);}}>OK</Button> to continue.</p>
								</div>
								{/* Last 5 minutes for short length test and 10 minutes left for test popup ends here */}
							</div>:markedLaterVisible?
								<div className="status-container text-center">
									{/* Marked for later questions popup content starts here*/}
									<FlagOutlined className="text-4xl text-gray-400" />
									<h1 className="status-title">{constants.PopupText}</h1>
									<div className="status-content">
										<p>{constants.PopupTextDescription}</p>
										<p><span>Click </span>
											<Button size="small" className="bg-blue-700 text-white" onClick={()=>{handleOkMarkForLaterModal();}}>
                                                            OK
											</Button>
											<span> to answer these question(s),</span>
										</p>
										<div><Divider className="my-3">OR</Divider></div>
										<p><Button size="small" className="bg-green-700 hover:bg-green-600 !text-white" onClick={()=>{fullLengthDetails&&fullLengthDetails.test_id?null:saveNotes();completeTestApi();stopBlockerCall();}}> Submit </Button>
											<span> the test and display your results.</span></p>
									</div>
									{/* Marked for later questions popup content ends here*/}
								</div>:timeOutVisible?
									<div className="status-container text-center">
										{/* Test ended popup content starts here */}
										<HourglassOutlined className="text-4xl text-gray-400" />
										<h1 className="status-title">{constants.TimeOutHeader}</h1>
										<div className="status-content">
											<p>{constants.TimeOutText}</p>
											<p> Click <Button size="small" onClick={() => { handleTimeOutOk(); }} className="bg-blue-700 text-white">OK</Button> to submit.</p>
										</div>
										{/* Test ended popup content ends here */}
									</div>
									:caseStudyVisible?
										<div className="status-container text-center">
											{/* Case study popup content starts here */}
											<ReadOutlined className="text-4xl text-gray-400" />
											<h1 className="status-title">{constants.CaseStudyPopupHeader}</h1>
											<div className="status-content">
												{constants.CaseStudyPopupText}
												<p> Click <Button size="small" onClick={() => { handleCaseStudyOk(); }} className="bg-blue-700 text-white">OK</Button> to continue.</p>
											</div>
											{/* Case study popup content ends here */}
										</div>:""}
			</Modal>
		</div>
	);
};
export const searchQuestionTable=(columnsQuestions,searchQuestionData)=>{
	return (<div>
		<Table
			showHeader={false}
			columns={columnsQuestions} 
			dataSource={searchQuestionData} 
			bordered
			pagination={false}      
		/>
	</div>);
};
export const displayQuestionDetails=(listQuestions,questionIndex)=>{
	let correctOptionToDisplay=[];
	if(listQuestions&&listQuestions.length>0){
		if(listQuestions[questionIndex].q_type==="5"){
			for (let j = 0; j < listQuestions[questionIndex].correct_option.length; j++) {
				// if(res.data[reviewType].question_list[i].correct_option[j].includes("/")){
				// 	correct_option=res.data[reviewType].question_list[i].correct_option[res.data[reviewType].question_list[i].correct_option.length-1];
				// }
				let splitted=listQuestions[questionIndex].correct_option[j].split("");
				let convertedToAlpha=[];
				for (let k = 0; k < splitted.length; k++) {
					convertedToAlpha.push(constants.OptionstoDisplayValues[splitted[k]]);
				}
				correctOptionToDisplay.push(convertedToAlpha.toString().replaceAll(",",""));
			}
		}else{
			// for (let j = 0; j < listQuestions[questionIndex].correct_option.length; j++) {
			// 	let converted=Number(listQuestions[questionIndex].correct_option[j]);
			// 	// correctOptionToDisplay.push(constants.OptionstoDisplayValues[converted]);
			// }
		}
	}
	return(
		<Col span={24} style={{ visibility:"hidden" }}>
			<b>Question Id:</b> {listQuestions[questionIndex].question_id?listQuestions[questionIndex].question_id:""}{"      "}
			{listQuestions[questionIndex].case_study_id?(<b>CS*</b>):null}{"     "}
			{/* <b>Category:</b> {listQuestions[questionIndex].category_abbreviation?listQuestions[questionIndex].category_abbreviation:""}{"      "} */}
			{/* <b>Topic:</b>{listQuestions[questionIndex].topic_title?listQuestions[questionIndex].topic_title:""}{"      "} */}
			<b>Correct Answer:</b> {
				correctOptionToDisplay&&
				correctOptionToDisplay.length>0?correctOptionToDisplay[0].includes("/")?correctOptionToDisplay[0].replaceAll("/",", "):
						correctOptionToDisplay.join(", ")
					:listQuestions&&
                    listQuestions.length>0&&
                    correctOptionToDisplay?
						correctOptionToDisplay:""}
		</Col>
	);
};
