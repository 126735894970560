/*
File Name: TakeTest.js
Author: Mevito Gonsalves
Modified On: 28/08/2023
Description: This page includes the code to start a test.
API's used:
1)
2) 
*/
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space, Tooltip, Divider, Input, Drawer, Table, Modal, message, Checkbox, Spin, Form, Badge, AutoComplete, Select, Steps, Progress, FloatButton, Image } from 'antd';
import { /* HighlightOutlined */ StrikethroughOutlined, ZoomInOutlined, ZoomOutOutlined, CloseCircleOutlined, ArrowLeftOutlined, FlagOutlined, FileSearchOutlined, LeftOutlined, RightOutlined, CommentOutlined, EyeOutlined, EyeInvisibleOutlined, SaveOutlined, DragOutlined, FlagFilled, ProfileOutlined, MessageOutlined, ArrowRightOutlined,PictureOutlined  } from '@ant-design/icons';
import $ from "jquery";
// import useUnsavedChangesWarning from "./Instructions/UnsavedChanges";
import { searchQuestionTable, displayQuestionDetails, featuredImageUrl, testModalPopup } from "../../../globalFunctions/GlobalFunctions";
import { cloneDeep } from "lodash";
// import Loader from "../Common/Cred/Loader";
import constants from "../../../constants/constants";
import { COOKIE, getCookie } from "../../../services/cookie";
import { useLocation, useNavigate } from "react-router-dom";
// import useUnsavedChangesWarning from "./UnsavedChanges";
import { getColorClass } from "../../../functions/functions";
import { fetchApi } from "../../../services/api";
import Draggables from 'react-draggable';
import { Chart } from "react-google-charts";
import ReactRouterPrompt from "react-router-prompt";
// const Comment = (props) => (<Icon component={CustomIcons.getsvg("Comment")} {...props}/>);
const { Option } = Select;
const { TextArea } = Input;
const TakeTest = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	// const history = useHistory();
	// eye icon toggle
	const [viewTimer, setViewTimer] = useState(false);
	const toggleTimer = () => {
		setViewTimer(!viewTimer);
	};
	// notes modal
	const [notesVisible, setnotesVisible] = useState(false);
	const [correctAnswers, setCorrectAnswers] = useState(0);
	const [totalPoints,setTotalPoints]=useState(0);
	const showModal = () => {
		setnotesVisible(true);
		let itemTemp = listQuestions[questionIndex];
		if (itemTemp.notes !== undefined && itemTemp.notes !== null) {
			setNoteValue(itemTemp.notes);
		}
	};
	const hideModal = () => {
		noteForm.resetFields();
		setnotesVisible(false);
	};
	//To set note form
	const setNoteValue = (valueText) => {
		noteForm.setFieldsValue({ note: valueText });
	};
	const [disabled, setIsDisabled] = React.useState(true);
	const [bounds, setBounds] = React.useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0
	});
	//Columns to display the drag and drop type questions
	let columnsOfDragAndDrop = {
		[1]: { items: [] },
		[2]: { items: [] },
		[3]: { items: [] },
		[4]: { items: [] },
		[5]: { items: [] },
		[6]: { items: [] },
		[7]: { items: [] },
		[8]: { items: [] },
		[9]: { items: [] },
	};
	//end of drap and drop code
	const [listQuestions, setQuestions] = useState([]);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [sortitems, setSortItems] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [timerDisplay, setTimerDisplay] = useState(null);
	const [time, setTime] = useState(null);
	const [completeTest, setCompleteTest] = useState(false);
	const [pauseTest, setPauseTest] = useState(false);
	const [scoreCardId, setScoreCardId] = useState(null);
	const [nextAndPreviousLoading, setNextAndPreviousLoading] = useState(false);
	const [completeTestLoading, setCompleteTestLoading] = useState(false);
	const [pauseTestLoading, setPauseTestLoading] = useState(false);
	const [selectedSequenceOption, setSelectedSequenceOption] = useState("");
	const [selectedDragOption, setSelectedDragOption] = useState(null);
	const [attemptedQuestions, setAttemptedQuestions] = useState(0);
	const draggleRef = React.createRef();
	const [noteForm] = Form.useForm();
	const [notelist,] = useState([]);
	const [categoryDetails, setCategoryDetails] = useState({});
	const [topicDetails, setTopicDetails] = useState({});
	const [lastTenMinutes, setLastTenMinutes] = useState(false);
	const [checkAnswer, setCheckAnswer] = useState("false");
	const [columns, setColumns] = useState(columnsOfDragAndDrop);
	const [markedLaterVisible, setMarkedLaterVisible] = useState(false);
	const [checkMarkLater, setCheckMarkLater] = useState("false");
	const [markedForLaterQuestions, setMarkedForLaterQuestions] = useState([]);
	const [mouseUp, setMouseUp] = useState(0);
	const [mouseDown, setMouseDown] = useState(0);
	const [selectedId, setSelectedId] = useState(null);
	const [visibleTimeOut, setVisibileTimeOut] = useState(false);
	const [searchList, setSearchList] = useState([]);
	let [searchValue, setSearchValue] = useState(null);
	const [isSearch, setIsSearch] = useState("false");
	const [displaySearch, setDisplaySearch] = useState("false");
	const [questionIndexList, setQuestionIndexList] = useState([]);//Used for Question Index drawer.
	const [isQuestionIndexOpened, setIsQuestionIndexOpened] = useState("false");
	// const [Prompt,setDirty,setPristine]=useUnsavedChangesWarning();
	const [onMouseDownId, setonMouseDownId] = useState(null);
	const [browserTime, setbrowserTime] = useState("");
	const [serverTime, setServerTime] = useState("");
	const [showResullt, setShowResult] = useState(false);
	const [isDirty,setIsDirty]=useState(false);
	const [visible, setImgPreviewVisible] = useState(false);
	const [globalPreview,setGlobalPreview]=useState(false);
	const setVisiblePreview=(val)=>{
		setGlobalPreview(val);
	}

	const current = new Date();
	const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
	const graphOption = {
		colors: ["#2F8548", "#B91919"],
		backgroundColor: { fill: 'transparent' },
		chartArea: { width: "70%" },
	};

	var myTimer;
	var timerUp;
	var totalSeconds = 0;
	const clock = (timer) => {
		myTimer = setInterval(myClock, 1000);
		var c = timer; //Initially set to 1 hour
		timerUp = setInterval(countTimer, 1000);
		function myClock() {
			sessionStorage.setItem("clock", --c);
			var seconds = c % 60; // Seconds that cannot be written in minutes
			var minutes = (c - seconds) / 60; // Gives the seconds that COULD be given in minutes
			var minutesLeft = minutes % 60; // Minutes that cannot be written in hours
			var hours = (minutes - minutesLeft) / 60;
			if (hours < 10)
				hours = "0" + hours;
			if (minutesLeft < 10) {
				minutesLeft = "0" + minutesLeft;
			}
			if (seconds < 10)
				seconds = "0" + seconds;
			// Now in hours, minutes and seconds, you have the time you need.
			sessionStorage.setItem("timerDisplay", hours + ":" + minutesLeft + ":" + seconds);
			setTimerDisplay(hours + ":" + minutesLeft + ":" + seconds);
			if (c == 300) {
				setLastTenMinutes(true);
			}
			if (c <= 0) {
				saveNotes();
				clearInterval(myTimer);
				clearInterval(timerUp);
				timeOut();
			}
		}
		function countTimer() {
			sessionStorage.setItem("totalSeconds", ++totalSeconds);
			var hour = Math.floor(totalSeconds / 3600);
			var minute = Math.floor((totalSeconds - hour * 3600) / 60);
			var seconds = totalSeconds - (hour * 3600 + minute * 60);
			if (hour < 10)
				hour = "0" + hour;
			if (minute < 10)
				minute = "0" + minute;
			if (seconds < 10)
				seconds = "0" + seconds;
			sessionStorage.setItem("timeTaken", hour + ":" + minute + ":" + seconds);
			setTime(hour + ":" + minute + ":" + seconds);
		}
	};
	useEffect(() => {
		// setDirty();
		setIsDirty(true);
		sessionStorage.setItem("quitTest", "false");
		sessionStorage.setItem("isFullLengthTestvalue", "false");
		$(document).keydown(function (event) {
			if (event.keyCode === 123 || event.ctrlKey && event.shiftKey && event.keyCode === 73 || event.ctrlKey && event.keyCode === 67) {
				return false;
			}
		});
		document.addEventListener('copy', disableCopy);
		document.addEventListener('contextmenu', disableContextMenu);
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0) {
			fetchFromLocalStorage();
		} else {
			if (location?.state?.score_card_id) {
				fetctResumeTestData();
			} else {
				fetchStartTestData();
			}
		}
	}, []);
	useEffect(() => {
		return () => {
			setIsDirty(false);
			clearInterval(myTimer);// Anything in here is fired on component unmount.
			clearInterval(timerUp);
			document.removeEventListener('contextmenu', disableContextMenu);
			document.removeEventListener('copy', disableCopy);
			$(document).off('keydown');
			let testCount = sessionStorage.getItem("totalTestsTaken");
			let viewedQod = sessionStorage.getItem("viewedQOD");
			let crashTest = sessionStorage.getItem("crash");
			let feedbackpopupshow = sessionStorage.getItem("feedbackShown");
			sessionStorage.clear();
			sessionStorage.setItem("UserIdSession",getCookie(COOKIE.UserId));
			sessionStorage.setItem("crash", crashTest);
			sessionStorage.setItem("totalTestsTaken", testCount);
			sessionStorage.setItem("viewedQOD", viewedQod);
			sessionStorage.setItem("feedbackShown", feedbackpopupshow);
		};
	}, []);
	function disableCopy(e) {
		e.preventDefault();
	}
	function disableContextMenu(e) {
		e.preventDefault();
	}
	//notes modal
	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement;
		const targetRect = draggleRef?.current?.getBoundingClientRect();
		let boundsForNotesModal = {
			left: -targetRect?.left + uiData?.x,
			right: clientWidth - (targetRect?.right - uiData?.x),
			top: -targetRect?.top + uiData?.y,
			bottom: clientHeight - (targetRect?.bottom - uiData?.y),
		};
		setBounds(boundsForNotesModal);
	};
	//To fetch the Questions to answer the test of the category type.
	const fetchStartTestData = () => {
		// {"stud_id":"2","chapter_id":"4","attempt":1}
		setShowResult(false)
		if (!location?.state?.chapter_id) {
			navigate('/dashboard', { replace: true });
		} else {
			setNextAndPreviousLoading(true);
			let userId = getCookie(COOKIE.UserId);
			let payload = {
				stud_id: userId,
				chapter_id: location?.state?.chapter_id,
				attempt: location?.state?.attempt
			};
			fetchApi(`/start-test`, "post", payload).then((res) => {
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					if (res.data && res.data.questions && res.data.questions.length > 0) {
						for (let i = 0; i < res.data.questions.length; i++) {
							res.data.questions[i].selectedAnswer = [];
							res.data.questions[i].ratings = null;
							res.data.questions[i].matchAns = false;
							let matchAns = true;
							res.data.questions[i].checkAnswer = "false";
							let correct_option = [];
							let correctOptionConverted = [];
							if (res.data.questions[i].q_type === "5") {
								if (res.data.questions[i].option_first) {
									res.data.questions[i].option_one = res.data.questions[i].option_first.substring(res.data.questions[i].option_first.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_second) {
									res.data.questions[i].option_two = res.data.questions[i].option_second.substring(res.data.questions[i].option_second.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_third) {
									res.data.questions[i].option_three = res.data.questions[i].option_third.substring(res.data.questions[i].option_third.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_fourth) {
									res.data.questions[i].option_four = res.data.questions[i].option_fourth.substring(res.data.questions[i].option_fourth.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_fifth) {
									res.data.questions[i].option_five = res.data.questions[i].option_fifth.substring(res.data.questions[i].option_fifth.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_sixth) {
									res.data.questions[i].option_six = res.data.questions[i].option_sixth.substring(res.data.questions[i].option_sixth.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_seventh) {
									res.data.questions[i].option_seven = res.data.questions[i].option_seventh.substring(res.data.questions[i].option_seventh.indexOf("|") + 1);
								}
								if (res.data.questions[i].option_eighth) {
									res.data.questions[i].option_eight = res.data.questions[i].option_eighth.substring(res.data.questions[i].option_eighth.indexOf("|") + 1);
								}
								correct_option = res.data.questions[i].correct_option;
								for (let j = 0; j < res.data.questions[i].correct_option.length; j++) {
									let splitted = res.data.questions[i].correct_option[j].split("");
									let convertedToAlpha = [];
									for (let k = 0; k < splitted.length; k++) {
										convertedToAlpha.push(constants.OptionstoDisplay[splitted[k]]);
									}
									correctOptionConverted.push(convertedToAlpha.toString().replaceAll(",", ""));
								}
							} else {
								for (let j = 0; j < res.data.questions[i].correct_option?.length; j++) {
									let converted = Number(res.data.questions[i].correct_option[j]);
									correct_option.push(converted);
									correctOptionConverted.push(constants.OptionstoDisplay[converted]);
								}
							}
							res.data.questions[i].correct_option = correct_option;
							res.data.questions[i].correct_option_converted = correctOptionConverted;
						}
						if (res.data && res.data.scorecard_id) {
							sessionStorage.setItem('scoreCardId', res.data.scorecard_id);
							setScoreCardId(res.data.scorecard_id);
						}
						sessionStorage.setItem("listQuestions", JSON.stringify(res.data.questions));
						sessionStorage.setItem("questionIndex", 0);
						sessionStorage.setItem("testType", "test");
						sessionStorage.setItem("checkAnswer", "false");
						sessionStorage.setItem("checkMarkLater", "false");
						sessionStorage.setItem("displaySearch", "false");
						sessionStorage.setItem("isSearch", "false");
						sessionStorage.setItem("isQuestionIndexOpened", "false");
						let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
						setQuestions(listQuestionsFromSession);
					}
				} else {
					message.error(res.message);
					// setPristine();
					const state = {
						chapter_id: location?.state?.chapter_id
					};
					navigate('/chapter-resources', { state , replace: true});;
				}
			});
		}
	};
	//TO fetch on resume test
	const fetctResumeTestData = () => {
		setShowResult(false)
		if (!location?.state?.chapter_id) {
			navigate('/dashboard', { replace: true });
		} else {
			setNextAndPreviousLoading(true);
			let payload = {
				scorecard_id: location?.state?.score_card_id,
			};
			fetchApi(`/resume-test  `, "post", payload).then((res) => {
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					if (res.data && res.data.questions && res.data.questions.length > 0) {
						let highlight_strike_data = null;
						if (res.data.scorecard.highlight_strike_data) {
							highlight_strike_data = JSON.parse(res.data.scorecard.highlight_strike_data);
						}
						let markedForLaterQuestionsList = [];
						var attemptedQuestionsCount = 0;
						for (let i = 0; i < res.data.questions.length; i++) {
							res.data.questions[i].selectedAnswer = [];
							if (res.data.questions[i].is_marked && res.data.questions[i].is_marked === 1) {
								markedForLaterQuestionsList.push(res.data.questions[i].question_id);
								res.data.questions[i].markedForLater = true;
							} else {
								res.data.questions[i].markedForLater = false;
							}
							if (res.data.questions[i].q_type === "2" && res.data.questions[i]?.user_ans?.length > 0) {
								res.data.questions[i].answers.map((item, index) => {
									let ans = [];
									ans.push(res.data.questions[i].user_ans[index]);
									item.selectedOption = ans;
								})
							}
							// let correct_option=[];
							// 	for (let j = 0; j < res.data.questions[i].correct_option.length; j++) {
							// 		correct_option.push(Number(res.data.questions[i].correct_option[j]));
							// 	}
							// res.data.questions[i].correct_option=correct_option;
							if (res.data.questions[i].user_ans && res.data.questions[i].user_ans.length > 0) {
								res.data.questions[i].selectedAnswer = res.data.questions[i].user_ans;
							}
							if (res.data.questions[i].user_ans && res.data.questions[i].user_ans.length > 0 || res.data.questions[i].markedForLater === true) {
								res.data.questions[i].isUpdatedToApi = true;
								setQuestionIndexList(questionIndexList);
								sessionStorage.setItem("questionIndexList", JSON.stringify(questionIndexList));
							} else {
								res.data.questions[i].isUpdatedToApi = false;
							}
							if (res.data.questions[i].q_type === "1" && res.data.questions[i]?.strike?.length > 0) {
								for (let ans = 0; ans < res.data.questions[i]?.answers?.length; ans++) {
									if (res.data.questions[i]?.strike.includes(res.data.questions[i]?.answers?.[ans].answer_option_id)) {
										res.data.questions[i].answers[ans].option_text = `<strike>${res.data.questions[i].answers[ans].option_text}</strike>`
									}
								}
							}
							// sortArrayOfObjects(searchList,"value");
							// setSearchList(searchList);
							sessionStorage.setItem("searchList", JSON.stringify(searchList));
							if (res.data.questions[i].selectedAnswer && res.data.questions[i].selectedAnswer.length > 0 || res.data.questions[i].markedForLater === true) {
								attemptedQuestionsCount = attemptedQuestionsCount + 1;
							}
						}
						for (let index = 0; index < res.data.questions.length; index++) {
							if (!res.data.questions[index].is_answered && !res.data.questions[index].is_marked) {
								setQuestionIndex(index);
								sessionStorage.setItem("questionIndex", index);
								sessionStorage.setItem("questionIndexCount", index);
								break;
							}
						}
						if (res.data && res.data.scorecard) {
							sessionStorage.setItem('scoreCardId', res.data.scorecard.score_card_id);
							setScoreCardId(res.data.scorecard.score_card_id);
						}
						sessionStorage.setItem("listQuestions", JSON.stringify(res.data.questions));
						let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
						sessionStorage.setItem("checkAnswer", "false");
						sessionStorage.setItem("checkMarkLater", "false");
						sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
						sessionStorage.setItem("displaySearch", "false");
						sessionStorage.setItem("isSearch", "false");
						sessionStorage.setItem("isQuestionIndexOpened", "false");
						sessionStorage.setItem("testType", "shortLength");
						setMarkedForLaterQuestions(markedForLaterQuestions);
						setCheckAnswer("false");
						setQuestions(listQuestionsFromSession);
						sessionStorage.setItem("attemptedQuestions", attemptedQuestionsCount);
						setAttemptedQuestions(attemptedQuestionsCount);
						setRefresh(!refresh);
					}
				} else {
					message.error(res.message);
					// setPristine();
					const state = {
						chapter_id: location?.state?.chapter_id
					};
					navigate('/chapter-resources', { state, replace: true });;
				}
			});
		}
	}
	const fetchFromLocalStorage = () => {
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession = sessionStorage.getItem("questionIndex");
		let categoryDetailsFromSession = JSON.parse(sessionStorage.getItem("categoryDetails") || "null");
		let topicDetailsFromSession = JSON.parse(sessionStorage.getItem("topicDetails") || "null");
		let checkAnswerFromSession = sessionStorage.getItem("checkAnswer");
		let checkMarkLaterFromSession = sessionStorage.getItem("checkMarkLater");
		let markedForLaterQuestionsFromSession = JSON.parse(sessionStorage.getItem("markedForLaterQuestions") || "[]");
		let isSearchFromSession = sessionStorage.getItem("isSearch");
		let searchValueFromSession = sessionStorage.getItem("searchValue");
		let searchListFromSession = JSON.parse(sessionStorage.getItem("searchList") || "[]");
		let questionIndexListFromSession = JSON.parse(sessionStorage.getItem("questionIndexList") || "[]");
		let isQuestionIndexOpenedFromSession = sessionStorage.getItem("isQuestionIndexOpened");
		let displaySearchFromSession = sessionStorage.getItem("displaySearch");
		let attemptedQuestionsFromSession = sessionStorage.getItem("attemptedQuestions");
		let CorrectCountValue = Number(sessionStorage.getItem("correctCount"))
		setCorrectAnswers(CorrectCountValue);
		//totalPointCount
		let totalPointcount = Number(sessionStorage.getItem("totalPointCount"))
		setTotalPoints(totalPointcount);
		sessionStorage.setItem("correctCount", CorrectCountValue);
		setAttemptedQuestions(Number(attemptedQuestionsFromSession));
		setShowResult(sessionStorage.getItem("showResult") === "t" ? true : false);
		setDisplaySearch(displaySearchFromSession);
		setIsQuestionIndexOpened(isQuestionIndexOpenedFromSession);
		setQuestionIndexList(questionIndexListFromSession);
		setSearchList(searchListFromSession);
		setSearchValue(searchValueFromSession);
		setIsSearch(isSearchFromSession);
		setMarkedForLaterQuestions(markedForLaterQuestionsFromSession);
		setCheckMarkLater(checkMarkLaterFromSession);
		setCheckAnswer(checkAnswerFromSession);
		setCategoryDetails(categoryDetailsFromSession);
		setTopicDetails(topicDetailsFromSession);
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0) {
			if (categoryDetailsFromSession) {
				if (categoryDetailsFromSession.isTimed === true) {
					setViewTimer(true);
					let clocks = sessionStorage.getItem("clock");
					clock(Number(clocks));
					totalSeconds = sessionStorage.getItem("totalSeconds");
					setTime(sessionStorage.getItem("timeTaken"));
					if (Number(clocks) === 0) {
						clearInterval(myTimer);
						clearInterval(timerUp);
						setVisibileTimeOut(true);
						sessionStorage.setItem("quitTest", "true");
						// setPristine();
						let timerDisplayFromSession = sessionStorage.getItem("timerDisplay");
						setTimerDisplay(timerDisplayFromSession);
					}
				} else if (categoryDetailsFromSession.isTimed === false) {
					setViewTimer(false);
				}
			}
			else if (topicDetailsFromSession) {
				if (topicDetailsFromSession.isTimed === true) {
					setViewTimer(true);
					let clocks = sessionStorage.getItem("clock");
					clock(Number(clocks));
					totalSeconds = sessionStorage.getItem("totalSeconds");
					setTime(sessionStorage.getItem("timeTaken"));
					if (Number(clocks) === 0) {
						clearInterval(myTimer);
						clearInterval(timerUp);
						setVisibileTimeOut(true);
						sessionStorage.setItem("quitTest", "true");
						// setPristine();
						let timerDisplayFromSession = sessionStorage.getItem("timerDisplay");
						setTimerDisplay(timerDisplayFromSession);
					}
				} else if (topicDetailsFromSession.isTimed === false) {
					setViewTimer(false);
				}
			}
			setQuestions(listQuestionsFromSession);
			setScoreCardId(Number(sessionStorage.getItem("scoreCardId")));
			setQuestionIndex(Number(questionIndexFromSession));
			// checkQuestionType(listQuestionsFromSession);
		} else {
			sessionStorage.setItem("quitTest", "true");
			// setPristine();
			// history.push("/my-tests");
		}
	};
	//Executes on click of Next button.
	const handleNextQuestion = (btnClickType) => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 && listQuestions[questionIndex].q_type === "1"
			|| listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length === listQuestions[questionIndex].answers.length && listQuestions[questionIndex].q_type === "2"
			|| listQuestions[questionIndex].markedForLater === true
		) {
			var match = true;
			if (match === true) {
				let payload = {};
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-' + mm + '-' + dd;
				if (listQuestions[questionIndex].q_type === "1" || listQuestions[questionIndex].q_type === "2") {
					var sequence = questionIndex + 1;
					payload.test_data = {
						seq: sequence,
						q_id: Number(listQuestions[questionIndex].question_id),
						user_ans: listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [],
						corr_ans: listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [],
						marked_for_later: listQuestions[questionIndex].markedForLater ? true : false,
					};
				}
				payload.test_status = 2;
				if (listQuestions[questionIndex].markedForLater === true) {
					payload.marked_for_later = {
						q_id: Number(listQuestions[questionIndex].question_id),
						marked_on: today
					}
				}
				let strikedOptions = [];
				for (let index = 0; index < listQuestions[questionIndex]?.answers.length; index++) {
					if (listQuestions[questionIndex].answers[index].option_text.includes(`<strike>`)) {
						strikedOptions.push(listQuestions[questionIndex].answers[index].answer_option_id);
					}
				}
				payload.strike_data ={
					q_id: Number(listQuestions[questionIndex].question_id),
					strike:strikedOptions,
				} ;
				let url = ``;
				url += `/update-test/${scoreCardId}`;
				nextQuestionApi(payload, url, btnClickType);
			} else {
				if (listQuestions[questionIndex].q_type === "1") {
					message.error({
						content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
						style:{ marginTop : '30vh' }
					});
				} else {
					message.error({
						content:checkMarkLater === "false"?constants.SelectAllOptionsTest:constants.SelectAllOptions,
						style:{ marginTop : '30vh' }
					});
				}
			}
		} else {
			if (listQuestions[questionIndex].q_type === "1") {
				message.error({
					content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
					style:{ marginTop : '30vh' }
				});
			} else {
				message.error({
					content:checkMarkLater === "false"?constants.SelectAllOptionsTest:constants.SelectAllOptions,
					style:{ marginTop : '30vh' }
				});
			}
		}
	};
	const nextQuestionApi = (payload, url, btnClickType) => {
		// console.log("payload", JSON.stringify(payload));
		// console.log("condition check1",btnClickType==="PREVIOUS");
		// console.log("button type",btnClickType);
		if(btnClickType!=="final"){
		if (btnClickType === "PREVIOUS") {
			sessionStorage.setItem("questionIndex", questionIndex - 1);
			setQuestionIndex(questionIndex - 1);
		} else {
			sessionStorage.setItem("questionIndex", questionIndex + 1);
			setQuestionIndex(questionIndex + 1);
		}
	}
		// if(btnClickType!=="SAVE"){
		// 	if(btnClickType==="PREVIOUS"){
		// 		setColumns(constants.columnsOfDragAndDrop);
		// 		sessionStorage.setItem("questionIndex",questionIndex-1);
		// 		setQuestionIndex(questionIndex-1);
		// 	}else if(btnClickType==="QUESTION"){
		// 		sessionStorage.setItem("questionIndex",questionIndex);
		// 		setQuestionIndex(questionIndex);
		// 	}else{
		// 		setColumns(constants.columnsOfDragAndDrop);
		// 		sessionStorage.setItem("questionIndex",questionIndex+1);
		// 		sessionStorage.setItem("questionIndexCount",questionIndex+1);
		// 		setQuestionIndex(questionIndex+1);
		// 	}
		// }
		setNextAndPreviousLoading(true);
		fetchApi(`/update-test/${scoreCardId}`, "put", payload)
			.then((res) => {
				// setNextAndPreviousLoading(false);
				// if (btnClickType === "PREVIOUS") {
				// 	sessionStorage.setItem("questionIndex", questionIndex - 1);
				// 	setQuestionIndex(questionIndex - 1);
				// } else {
				// 	sessionStorage.setItem("questionIndex", questionIndex + 1);
				// 	setQuestionIndex(questionIndex + 1);
				// }
				setServerTime(res.current_date ? res.current_date : "");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
				if (res && res.code && res.code === 200) {
					listQuestions[questionIndex].isUpdatedToApi = true;
					calculateAttemptedQuestions();
					if (questionIndex < listQuestions.length - 1) {
						if (checkAnswer === "true" && btnClickType === "SUBMIT") {
							setCompleteTest(true);
						} else {
							if (checkMarkLater === "true") {
								var match = false;
								for (let index = questionIndex; index < listQuestions.length; index++) {
									if (listQuestions[index].markedForLater === true) {
										// setColumns(constants.columnsOfDragAndDrop);
										setQuestionIndex(index);
										sessionStorage.setItem("questionIndex", index);
										sessionStorage.setItem("questionIndexCount", index);
										listQuestions[index].markedForLater = false;
										sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
										setQuestions(listQuestions);
										match = true;
										break;
									}
								}
								if (match === false) {
									if (btnClickType && btnClickType === "PREVIOUS") {
										sessionStorage.setItem("questionIndex", questionIndex - 1);
										setQuestionIndex(questionIndex - 1);
									} else if (btnClickType && btnClickType === "QUESTION") {
										sessionStorage.setItem("questionIndex", questionIndex);
										setQuestionIndex(questionIndex);
									} else {
										setCompleteTest(true);
									}
								}
							} else {
								if (btnClickType !== "SAVE") {
									if (btnClickType === "PREVIOUS") {
										setColumns(constants.columnsOfDragAndDrop);
										sessionStorage.setItem("questionIndex", questionIndex - 1);
										setQuestionIndex(questionIndex - 1);
									} else if (btnClickType === "QUESTION") {
										sessionStorage.setItem("questionIndex", questionIndex);
										setQuestionIndex(questionIndex);
									} else {
										setColumns(constants.columnsOfDragAndDrop);
										sessionStorage.setItem("questionIndex", questionIndex + 1);
										sessionStorage.setItem("questionIndexCount", questionIndex + 1);
										setQuestionIndex(questionIndex + 1);
									}
								} else {
									listQuestions[questionIndex].oldSelectedAnswer = listQuestions[questionIndex].selectedAnswer;
									sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
									setQuestions(listQuestions);
								}
							}
						}
					} else {
						let markedForLaterQuestionsList = [];
						for (let index = 0; index < listQuestions.length; index++) {
							if (listQuestions && listQuestions[index].markedForLater === true) {
								markedForLaterQuestionsList.push(listQuestions[index].question_id);
							}
						}
						sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
						setMarkedForLaterQuestions(markedForLaterQuestionsList);
						var matched = false;
						for (let index = 0; index < listQuestions.length; index++) {
							if (listQuestions[index].markedForLater === true) {
								matched = true;
								break;
							}
						}
						if (matched === true) {
							if (btnClickType === "SUBMIT") {
								setCompleteTest(true);
							} else {
								if (btnClickType && btnClickType === "PREVIOUS") {
									sessionStorage.setItem("questionIndex", questionIndex - 1);
									setQuestionIndex(questionIndex - 1);
								} else if (btnClickType && btnClickType === "QUESTION") {
									sessionStorage.setItem("questionIndex", questionIndex);
									setQuestionIndex(questionIndex);
								} else {
									setMarkedLaterVisible(true);
								}
							}
						} else {
							if (btnClickType && btnClickType === "PREVIOUS") {
								sessionStorage.setItem("questionIndex", questionIndex - 1);
								setQuestionIndex(questionIndex - 1);
							} else if (btnClickType && btnClickType === "QUESTION") {
								sessionStorage.setItem("questionIndex", questionIndex);
								setQuestionIndex(questionIndex);
							} else {
								if(btnClickType!=="final"){
								setCompleteTest(true);
								}
							}
						}
					}
					setNextAndPreviousLoading(false);
					setQuestions(listQuestions);
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				} else {
					setNextAndPreviousLoading(false);
					sessionStorage.setItem("quitTest", "true");
					// setPristine();
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
			});
	};
	//Function to calculate the count of attempted questions to show on Pause test popup
	const calculateAttemptedQuestions = () => {
		if (listQuestions && listQuestions.length > 0) {
			var attemptedQuestionsCount = 0;
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions[index].selectedAnswer && listQuestions[index].selectedAnswer.length > 0 || listQuestions[index].markedForLater === true) {
					attemptedQuestionsCount = attemptedQuestionsCount + 1;
				}
			}
			setAttemptedQuestions(attemptedQuestionsCount);
			sessionStorage.setItem("attemptedQuestions", attemptedQuestionsCount);
			setRefresh(!refresh);
		}
	};
	const calculatePercentage = (count, total) => {
		return (count / total) * 100;
	}
	//Executes on click of Previous button.
	const handlePreviousQuestion = () => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 || listQuestions[questionIndex].markedForLater === true) {
			handleNextQuestion("PREVIOUS");
		} else {
			// if(listQuestions[questionIndex].isUpdatedToApi===true){
			// 	if(checkAnswer==="true"){
			// 		message.error(constants.SelectOptionOnlyTest);
			// 	}else{
			// 		message.error(constants.SelectOption);
			// 	}
			// }else{
			setNextAndPreviousLoading(true);
			sessionStorage.setItem("questionIndex", questionIndex - 1);
			setQuestionIndex(questionIndex - 1);
			setNextAndPreviousLoading(false);
		}
	};
	const getTestResults=()=>{
		setCompleteTestLoading(true);
		fetchApi(`/test-score/${scoreCardId}`, "get",)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					let correctTotal = res?.data?.q_correct  ? res?.data?.q_correct  : 0;
					setCorrectAnswers(correctTotal);
					sessionStorage.setItem("correctCount", correctTotal);
					let totalPoints=res?.data?.points  ? res?.data?.points  : 0;
					setTotalPoints(totalPoints);
					sessionStorage.setItem("totalPointCount", totalPoints);
					setCompleteTestLoading(false);
					setShowResult(true);
					sessionStorage.setItem("showResult", "t");
					// const state = { 
					// 	chapter_id: location?.state?.chapter_id
					// };
					// navigate('/chapter-resources', { state });
				} else {
					setShowResult(false);
					sessionStorage.setItem("showResult", "f");
					// setPristine();
					setNextAndPreviousLoading(false);
				}
				setCompleteTestLoading(false);
			});
	}
	const completeTestApi = () => {
		handleNextQuestion("final");
		setTimeout(() => {
			let payload = {};
			payload.test_status = 4;
			const date = new Date();
			payload.dt_completed = date;
			if (markedForLaterQuestions && markedForLaterQuestions.length > 0) {
				payload.marked_for_later = markedForLaterQuestions;
			} else {
				payload.marked_for_later = [];
			}
			// console.log("payload", payload);
			// 
			setCompleteTestLoading(true);
			fetchApi(`/update-test/${scoreCardId}`, "put", payload)
				.then((res) => {
					if (res && res.code && res.code === 200) {
						setServerTime(res.current_date ? res.current_date : "");
						let browerDateTime = new Date();
						setbrowserTime(browerDateTime.toString());
						// let testCount = sessionStorage.getItem("totalTestsTaken");
						// let totalTestCount = parseInt(testCount) + 1;
						// sessionStorage.setItem("totalTestsTaken", totalTestCount);
						listQuestions[questionIndex].isUpdatedToApi = true;
						// sessionStorage.setItem("quitTest", "true");
						// let correctTotal = res.q_correct  ? res.q_correct  : 0;
						// setCorrectAnswers(correctTotal);
						// sessionStorage.setItem("correctCount", correctTotal);
						// let totalPoints=res.points  ? res.points  : 0;
						// setTotalPoints(totalPoints);
						// sessionStorage.setItem("totalPointCount", totalPoints);
						// setPristine();
						setCompleteTest(false);
						setMarkedLaterVisible(false);
						clearInterval(myTimer);
						clearInterval(timerUp);
						setColumns(constants.columnsOfDragAndDrop);
						calculateAttemptedQuestions();
						setCompleteTestLoading(false);
						// stopBlockerCall();
						let state={
							chapter_id:location?.state?.chapter_id,
							attempt:location?.state?.attempt,
							chapter_name:location?.state?.chapter_name,
							scoreCardId:scoreCardId
						}
						navigate('/test-results',{ state , replace: true});
						// getTestResults();
						// setShowResult(true);
						// sessionStorage.setItem("showResult", "t");
						// const state = { 
						// 	chapter_id: location?.state?.chapter_id
						// };
						// navigate('/chapter-resources', { state });
					} else {
						setCompleteTestLoading(false);
						// sessionStorage.setItem("quitTest", "true");
						// setShowResult(false);
						// sessionStorage.setItem("showResult", "f");
						// // setPristine();
						setNextAndPreviousLoading(false);
						// if (res.error_message === "Invalid token") {
						// 	message.error(constants.LoggedInAnotherBrowser);
						// } else {
						// 	if (res.code === 600) {
						// 		message.error(res.message);
						// 	} else {
						// 		message.error("Failed");
						// 	}
						// }
						stopBlockerCall();
					}
					setCompleteTestLoading(false);
				});
		}, 1000);
	};
	//Executes score_card PUT method API to pause the test
	const pauseTestAnswer = () => {
		setPauseTestLoading(true);
		let payload = {};
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + '-' + mm + '-' + dd;
		if (listQuestions[questionIndex].q_type === "1"  ) {
			var sequence = questionIndex + 1;
			payload.test_data = {
				seq: sequence,
				q_id: Number(listQuestions[questionIndex].question_id),
				user_ans: listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [],
				corr_ans: listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [],
				marked_for_later: listQuestions[questionIndex].markedForLater ? true : false,
				// answered_on:today,
			};
		}else if(listQuestions[questionIndex].q_type === "2"){
			var sequence = questionIndex + 1;
			payload.test_data = {
				seq: sequence,
				q_id: Number(listQuestions[questionIndex].question_id),
				marked_for_later: listQuestions[questionIndex].markedForLater ? true : false,
				// answered_on:today,
			};
			if(listQuestions[questionIndex].selectedAnswer 
				&& listQuestions[questionIndex].selectedAnswer.length > 0
				&&listQuestions[questionIndex].correct_option 
				&& listQuestions[questionIndex].correct_option.length > 0
				&& listQuestions[questionIndex].selectedAnswer.length===listQuestions[questionIndex].correct_option.length
				){
					payload.test_data.user_ans=listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [];
					payload.test_data.corr_ans=listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [];
			}else{
				payload.test_data.user_ans=[];
				payload.test_data.corr_ans=[];
			}
		}
		payload.test_status = 3;
		if (markedForLaterQuestions && markedForLaterQuestions.length > 0) {
			payload.marked_for_later = markedForLaterQuestions;
		} else {
			payload.marked_for_later = [];
		}
		fetchApi(`/update-test/${scoreCardId}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					listQuestions[questionIndex].isUpdatedToApi = true;
					sessionStorage.setItem("quitTest", "true");
					setCompleteTest(false);
					clearInterval(myTimer);
					clearInterval(timerUp);
					setColumns(constants.columnsOfDragAndDrop);
					setPauseTestLoading(false);
					// setPristine();
					// history.push("/my-tests");
					const state = {
						chapter_id: location?.state?.chapter_id
					};
					navigate('/chapter-resources', { state, replace: true });
				} else {
					setPauseTestLoading(false);
					sessionStorage.setItem("quitTest", "true");
					// setPristine();
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
				setPauseTestLoading(false);
			});
	};
	const stopBlockerCall=()=>{
		setIsDirty(false);
	}
	//Executes score_card PUT method API when time runs out
	const timeOut = () => {
		let scoreCardIdFromSession = Number(sessionStorage.getItem("scoreCardId"));
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession = Number(sessionStorage.getItem("questionIndex"));
		let categoryDetailsFromSession = JSON.parse(sessionStorage.getItem("categoryDetails") || "null");
		let topicDetailsFromSession = JSON.parse(sessionStorage.getItem("topicDetails") || "null");
		setCompleteTestLoading(true);
		let payload = {};
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		today = yyyy + '-' + mm + '-' + dd;
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0 && listQuestionsFromSession[questionIndexFromSession].selectedAnswer && listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length > 0 ||
			listQuestionsFromSession[questionIndexFromSession].markedForLater === true ||
			listQuestionsFromSession[questionIndexFromSession].markedForLater === false) {
			if (listQuestionsFromSession[questionIndexFromSession].q_type === "1" || listQuestionsFromSession[questionIndexFromSession].q_type === "2") {
				var sequence = questionIndexFromSession + 1;
				payload.test_data = {
					seq: sequence,
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans: listQuestionsFromSession[questionIndexFromSession].selectedAnswer && listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length > 0 ? listQuestionsFromSession[questionIndexFromSession].selectedAnswer : [],
					corr_ans: listQuestionsFromSession[questionIndexFromSession].correct_option && listQuestionsFromSession[questionIndexFromSession].correct_option.length > 0 ? listQuestionsFromSession[questionIndexFromSession].correct_option : [],
					marked_for_later: listQuestionsFromSession[questionIndexFromSession].markedForLater ? true : false,
					// answered_on:today,
				};
			} else if (listQuestionsFromSession[questionIndexFromSession].q_type === "4") {
				let answer = [];
				for (let i = 0; i < listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length; i++) {
					answer.push(listQuestionsFromSession[questionIndexFromSession].selectedAnswer[i].index);
				}
				var seq = questionIndexFromSession + 1;
				payload.test_data = {
					seq: seq,
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans: answer && answer.length > 0 ? answer : [],
					corr_ans: listQuestionsFromSession[questionIndexFromSession].correct_option && listQuestionsFromSession[questionIndexFromSession].correct_option.length > 0 ? listQuestionsFromSession[questionIndexFromSession].correct_option : [],
					marked_for_later: listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			} else if (listQuestionsFromSession[questionIndexFromSession].q_type === "5") {
				let answer = [];
				for (let i = 0; i < listQuestionsFromSession[questionIndexFromSession].selectedAnswer.length; i++) {
					answer.push(Number(listQuestionsFromSession[questionIndexFromSession].selectedAnswer[i].id));
				}
				var sequ = questionIndexFromSession + 1;
				payload.test_data = {
					seq: sequ,
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					user_ans: answer && answer.length > 0 ? answer : [],
					corr_ans: listQuestionsFromSession[questionIndexFromSession].correct_option && listQuestionsFromSession[questionIndexFromSession].correct_option.length > 0 ? listQuestionsFromSession[questionIndexFromSession].correct_option : [],
					marked_for_later: listQuestionsFromSession[questionIndexFromSession].markedForLater,
					// answered_on:today,
				};
			}
			if ((categoryDetailsFromSession && categoryDetailsFromSession.isTimed !== null && categoryDetailsFromSession.isTimed === true) ||
				(topicDetailsFromSession && topicDetailsFromSession.isTimed !== null && topicDetailsFromSession.isTimed === true)) {
				payload.total_time_taken = sessionStorage.getItem("timeTaken");
			}
			let totalOptions = null;
			let optionNumver = {};
			if (listQuestionsFromSession[questionIndexFromSession].q_type === "1" || listQuestionsFromSession[questionIndexFromSession].q_type === "2" || listQuestionsFromSession[questionIndexFromSession].q_type === "4") {
				totalOptions = listQuestionsFromSession[questionIndexFromSession].opt_cnt ? Number(listQuestionsFromSession[questionIndexFromSession].opt_cnt) : 0;
				optionNumver = constants.OptionNumbers;
			} else if (listQuestionsFromSession[questionIndexFromSession].q_type === "5") {
				optionNumver = constants.OptionNumberDragAndDrop;
				totalOptions = listQuestionsFromSession[questionIndexFromSession].opt_cnt ? Number(listQuestionsFromSession[questionIndexFromSession].opt_cnt.charAt(2)) : 0;
			}
			let strikedOptions = [];
			let highlightedOptions = [];
			for (let j = 0; j < totalOptions; j++) {
				let op = optionNumver[j + 1];
				if (listQuestionsFromSession[questionIndexFromSession][op].includes("<strike>")) {
					strikedOptions.push(j + 1);
				}
				if (listQuestionsFromSession[questionIndexFromSession][op].includes("highlighted")) {
					highlightedOptions.push({
						opt: j + 1,
						data: listQuestionsFromSession[questionIndexFromSession][op]
					});
				}
			}
			if (listQuestionsFromSession[questionIndexFromSession].question_text && listQuestionsFromSession[questionIndexFromSession].question_text.includes("highlighted")) {
				highlightedOptions.push({
					opt: 99,
					data: listQuestionsFromSession[questionIndexFromSession].question_text,
				});
			}
			if (strikedOptions && strikedOptions.length > 0 || highlightedOptions && highlightedOptions.length > 0) {
				payload.highlight_strike_data = {
					q_id: Number(listQuestionsFromSession[questionIndexFromSession].question_id),
					strike: strikedOptions,
				};
			}
		} else {
			if ((categoryDetailsFromSession && categoryDetailsFromSession.isTimed !== null && categoryDetailsFromSession.isTimed === true) ||
				(topicDetailsFromSession && topicDetailsFromSession.isTimed !== null && topicDetailsFromSession.isTimed === true)) {
				payload.total_time_taken = sessionStorage.getItem("timeTaken");
			}
		}
		const date = new Date();
		payload.dt_completed = date;
		payload.test_status = 1;
		fetchApi(`/score_card/${scoreCardIdFromSession}`, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					listQuestionsFromSession[questionIndexFromSession].isUpdatedToApi = true;
					sessionStorage.setItem("quitTest", "true");
					// setPristine();
					setCompleteTest(false);
					// clearInterval(myTimer);
					// clearInterval(timerUp);
					setCompleteTestLoading(false);
					setVisibileTimeOut(true);
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest", "true");
					// setPristine();
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	const handleTimeOutOk = () => {
		let testCategory = null;
		let resultTypeFull = null;
		let isTopic = null;
		sessionStorage.setItem("quitTest", "true");
		// setPristine();
		if (categoryDetails && categoryDetails.type === "normal") {
			if (categoryDetails.catType && categoryDetails.catType === "MIXED") {
				resultTypeFull = false;
				testCategory = categoryDetails.catType;
				isTopic = false;
			} else {
				resultTypeFull = true;
				testCategory = categoryDetails.catType;
				isTopic = false;
			}
		}
		if (topicDetails && topicDetails.type === "normal") {
			resultTypeFull = false;
			testCategory = null;
			isTopic = true;
		}
		// history.push({
		// 	pathname: "/result-page",
		// 	state: { 
		// 		scoreCardId:scoreCardId,
		// 		resultTypeFull:resultTypeFull,
		// 		testCategory:testCategory,
		// 		isTopic:isTopic,
		// 	}
		// });
	};
	//Executes on state change of questionIndex state.
	// useEffect(() => {
	// 	if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
	// 		let sortItems=[];
	// 		if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
	// 			sortItems=listQuestions[questionIndex].selectedAnswer;
	// 		}else{
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
	// 				sortItems.push({
	// 					index:1,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
	// 				sortItems.push({
	// 					index:2,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
	// 				sortItems.push({
	// 					index:3,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
	// 				sortItems.push({
	// 					index:4,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
	// 				sortItems.push({
	// 					index:5,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
	// 				sortItems.push({
	// 					index:6,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
	// 				sortItems.push({
	// 					index:7,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh 
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
	// 				sortItems.push({
	// 					index:8,
	// 					value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth 
	// 				});
	// 			}
	// 		}
	// 		setSortItems(sortItems);
	// 		setRefresh(!refresh);
	// 	}else if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="5"){
	// 		if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
	// 			setColumns(listQuestions[questionIndex].columns);
	// 		}else{
	// 			let Items=[];
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
	// 				Items.push({
	// 					id:`${1}`,
	// 					content:`${listQuestions[questionIndex].option_one }`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
	// 				Items.push({
	// 					id:`${2}`,
	// 					content:`${listQuestions[questionIndex].option_two }`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
	// 				Items.push({
	// 					id:`${3}`,
	// 					content:`${listQuestions[questionIndex].option_three }`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
	// 				Items.push({
	// 					id:`${4}`,
	// 					content:`${listQuestions[questionIndex].option_four }`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
	// 				Items.push({
	// 					id:`${5}`,
	// 					content:`${listQuestions[questionIndex].option_five}`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
	// 				Items.push({
	// 					id:`${6}`,
	// 					content:`${listQuestions[questionIndex].option_six}`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
	// 				Items.push({
	// 					id:`${7}`,
	// 					content:`${listQuestions[questionIndex].option_seven }`
	// 				});
	// 			}
	// 			if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
	// 				Items.push({
	// 					id:`${8}`,
	// 					content:`${listQuestions[questionIndex].option_eight}`
	// 				});
	// 			}
	// 			let columnsObject={
	// 				[1]: { items: [] },
	// 				[2]: { items: [] },
	// 				[3]: { items: [] },
	// 				[4]: { items: [] },
	// 				[5]: { items: [] },
	// 				[6]: { items: [] },
	// 				[7]: { items: [] },
	// 				[8]: { items: [] },
	// 				[9]: { items: [] },
	// 			};
	// 			columnsObject[1].items=Items;
	// 			setColumns(columnsObject);
	// 			setRefresh(!refresh);
	// 		}
	// 	}
	// }, [questionIndex]);
	//On check function for single select question options.
	const handleSingleSelectAnswer = e => {
		if (e.target.value) {
			if (listQuestions && listQuestions.length > 0) {
				if (isSearch === "true" || isQuestionIndexOpened === "true") {
					listQuestions[questionIndex].selectedAnswer = [e.target.value];
				} else {
					listQuestions[questionIndex].selectedAnswer = [e.target.value];
					listQuestions[questionIndex].oldSelectedAnswer = [e.target.value];
				}
				let tempCorrectOption = listQuestions[questionIndex].answers.filter((item) => { return item.corr_opt === "t" });
				listQuestions[questionIndex].correct_option = [tempCorrectOption[0]?.answer_option_id];
				let getUserAnswerIndex = listQuestions[questionIndex]?.answers.findIndex((item) => item.answer_option_id === e.target.value);
				if (listQuestions[questionIndex].answers[getUserAnswerIndex].option_text.includes("<strike>")) {
					listQuestions[questionIndex].answers[getUserAnswerIndex].option_text = listQuestions[questionIndex].answers[getUserAnswerIndex].option_text.replace("<strike>", "");
					listQuestions[questionIndex].answers[getUserAnswerIndex].option_text = listQuestions[questionIndex].answers[getUserAnswerIndex].option_text.replace("</strike>", "");
				}
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	//Function to check answers on click of check button on complete test popup
	const checkAnswers = () => {
		sessionStorage.setItem("checkAnswer", "true");
		sessionStorage.setItem("checkMarkLater", "false");
		setCheckMarkLater("false");
		setCheckAnswer("true");
		setCompleteTest(false);
		setQuestionIndex(0);
		sessionStorage.setItem("questionIndex", 0);
	};
	//Function to mark the question as mark for later.
	const handleMarkForLater = () => {
		if (listQuestions && listQuestions.length > 0) {
			let marked = listQuestions[questionIndex].markedForLater;
			listQuestions[questionIndex].markedForLater = !marked;
			let markedForLaterQuestionsList = [];
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions && listQuestions[index].markedForLater === true) {
					markedForLaterQuestionsList.push(listQuestions[index].question_id);
				}
			}
			sessionStorage.setItem("markedForLaterQuestions", JSON.stringify(markedForLaterQuestionsList));
			setMarkedForLaterQuestions(markedForLaterQuestionsList);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
			questionIndexData();
		}
	};
	//Function to hide popup of Mark for later on click of Ok button.
	const handleOkMarkForLaterModal = () => {
		sessionStorage.setItem("checkMarkLater", "true");
		//Display marked for later questions on clic of OK.
		if (listQuestions && listQuestions.length > 0) {
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions[index].markedForLater === true) {
					setQuestionIndex(index);
					listQuestions[index].markedForLater = false;
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
					setQuestions(listQuestions);
					sessionStorage.setItem("questionIndex", index);
					break;
				}
			}
		}
		setCheckMarkLater("true");
		setMarkedLaterVisible(false);
	};
	//Function to handle Strike through options of all types of questions.
	const handleStrikeThrough = () => {
		if (listQuestions && listQuestions.length > 0) {
			let optionNumver = constants.OptionNumbers;
			if (listQuestions[questionIndex].q_type === "1" && listQuestions[questionIndex]?.selectedAnswer.length > 0) {
				let selectedId = listQuestions[questionIndex].selectedAnswer[0];
				let indexSelected = listQuestions[questionIndex]?.answers.findIndex((item) => item.answer_option_id === selectedId);
				if (listQuestions[questionIndex].answers[indexSelected].option_text.includes("<strike>")) {
					listQuestions[questionIndex].answers[indexSelected].option_text = listQuestions[questionIndex].answers[indexSelected].option_text.replace("<strike>", "");
					listQuestions[questionIndex].answers[indexSelected].option_text = listQuestions[questionIndex].answers[indexSelected].option_text.replace("</strike>", "");
				} else {
					listQuestions[questionIndex].answers[indexSelected].option_text = `<strike>${listQuestions[questionIndex].answers[indexSelected].option_text}</strike>`;
					listQuestions[questionIndex].selectedAnswer = null;
				}
			}
			setQuestions(listQuestions);
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setRefresh(!refresh);
		}
		setRefresh(!refresh);
	};
	//Function to set the coordinate of selected text on Mouse down for highlighting the text
	const handleMouseDown = (e, id) => {
		setonMouseDownId(Number(id));
		setSelectedSequenceOption("");
		if (e.changedTouches && e.changedTouches[0] && e.changedTouches[0].pageX) {
			setMouseDown(e.changedTouches[0].pageX);
		} else {
			setMouseDown(e.pageX);
		}
		setRefresh(!refresh);
	};
	//Function to set the coordinate of selected text on Mouse up for highlighting the text
	const handleMouseUp = (e, id) => {
		setSelectedId(Number(id));
		setSelectedSequenceOption("");
		if (e.changedTouches && e.changedTouches[0] && e.changedTouches[0].pageX) {
			setMouseUp(e.changedTouches[0].pageX);
		} else {
			setMouseUp(e.pageX);
		}
		setRefresh(!refresh);
	};
	//Function to handle Highlight text to options.
	// eslint-disable-next-line no-unused-vars
	const handleHighlightText = () => {
		if (selectedId === onMouseDownId && selectedId !== null && onMouseDownId !== null) {
			if (selectedId === 1 || selectedId === 2 || selectedId === 3 || selectedId === 4 || selectedId === 5 || selectedId === 6 || selectedId === 7 || selectedId === 8 || selectedId === 99) {
				var highlighted = false;
				var selection = window.getSelection();
				var selectedText = selection.toString();
				var startPoint = window.getSelection().getRangeAt(0).startOffset;
				var endPoint = window.getSelection().getRangeAt(0).endOffset;
				var anchorTag = selection.anchorNode.parentNode;
				var focusTag = selection.focusNode.parentNode;
				if ((mouseUp - mouseDown) < 0) {
					focusTag = selection.anchorNode.parentNode;
					anchorTag = selection.focusNode.parentNode;
				}
				if (selectedText.length === (endPoint - startPoint)) {
					highlighted = true;
					if (anchorTag.className !== "highlighted") {
						highlightSelection();
					} else {
						var afterText = selectedText + "<span class = 'highlighted'>" + anchorTag.innerHTML.substr(endPoint) + "</span>";
						anchorTag.innerHTML = anchorTag.innerHTML.substr(0, startPoint);
						anchorTag.insertAdjacentHTML('afterend', afterText);
					}
				} else {
					if (anchorTag.className !== "highlighted" && focusTag.className !== "highlighted") {
						highlightSelection();
						highlighted = true;
					}
				}
				if (anchorTag.className === "highlighted" && focusTag.className === 'highlighted' && !highlighted) {
					highlighted = true;
					var afterHtml = anchorTag.innerHTML.substr(startPoint);
					var outerHtml = selectedText.substr(afterHtml.length, selectedText.length - endPoint - afterHtml.length);
					var anchorInnerhtml = anchorTag.innerHTML.substr(0, startPoint);
					var focusInnerHtml = focusTag.innerHTML.substr(endPoint);
					var focusBeforeHtml = focusTag.innerHTML.substr(0, endPoint);
					selection.deleteFromDocument();
					anchorTag.innerHTML = anchorInnerhtml;
					focusTag.innerHTml = focusInnerHtml;
					var anchorafterHtml = afterHtml + outerHtml + focusBeforeHtml;
					anchorTag.insertAdjacentHTML('afterend', anchorafterHtml);
				}
				if (anchorTag.className === "highlighted" && !highlighted) {
					highlighted = true;
					if (startPoint !== 0 || endPoint !== 0) {
						var Innerhtml = anchorTag.innerHTML.substr(0, startPoint);
						var afterHtmll = anchorTag.innerHTML.substr(startPoint);
						var outerHtmll = selectedText.substr(afterHtmll.length, selectedText.length);
						selection.deleteFromDocument();
						if (afterHtml) {
							anchorTag.innerHTML = Innerhtml;
							anchorTag.insertAdjacentHTML('afterend', afterHtmll + outerHtmll);
						} else {
							anchorTag.insertAdjacentHTML('afterend', selectedText);
						}
					}
				}
				if (focusTag.className === 'highlighted' && !highlighted) {
					var pointValue = 0;
					if (startPoint > endPoint) {
						pointValue = startPoint;
					} else {
						pointValue = endPoint;
					}
					highlighted = true;
					var beforeHtml = focusTag.innerHTML.substr(0, endPoint);
					var outerHtmls = selectedText.substr(0, selectedText.length - beforeHtml.length);
					selection.deleteFromDocument();
					focusTag.innerHTml = focusTag.innerHTML.substr(pointValue);
					outerHtmls += beforeHtml;
					focusTag.insertAdjacentHTML('beforebegin', outerHtmls);
				}
				if (!highlighted) {
					highlightSelection();
				}
				$('.highlighted').each(function () {
					if ($(this).html() == '' || $(this).html() == '&nbsp;') {
						$(this).remove();
					}
				});
				selection.removeAllRanges();
				// eslint-disable-next-line no-inner-declarations
				function highlightSelection() {
					var selectionText;
					//Get the selected stuff
					if (window.getSelection) {
						selectionText = window.getSelection();
					}
					else if (typeof document.selection != "undefined") {
						selectionText = document.selection;
					}
					//Get a the selected content, in a range object
					var range = selectionText.rangeCount >= 1 && selectionText.getRangeAt(0);
					//If the range spans some text, and inside a tag, set its css class.
					if (range && !selectionText.isCollapsed) {
						if (selectionText.anchorNode.parentNode == selectionText.focusNode.parentNode) {
							var span = document.createElement('span');
							span.className = 'highlighted';
							span.textContent = selectionText.toString();
							selectionText.deleteFromDocument();
							range.insertNode(span);
							//                        range.surroundContents(span);
						}
					}
				}
				if (listQuestions && listQuestions.length > 0) {
					if (listQuestions[questionIndex].q_type && listQuestions[questionIndex].q_type === "1" ||
						listQuestions[questionIndex].q_type && listQuestions[questionIndex].q_type === "2") {
						let optionNumver = constants.OptionNumbers;
						let ddd = optionNumver[selectedId];
						let m = document.getElementById(selectedId).innerHTML;
						listQuestions[questionIndex][ddd] = m;
					} else if (listQuestions[questionIndex].q_type === "4") {
						let optionNumver = constants.OptionNumbers;
						if (selectedId === 99) {
							let ddd = optionNumver[selectedId];
							let m = document.getElementById(selectedId).innerHTML;
							listQuestions[questionIndex][ddd] = m;
						} else {
							for (let i = 0; i < sortitems.length; i++) {
								if (sortitems[i].index === selectedId) {
									let ddd = optionNumver[sortitems[i].index];
									let m = document.getElementById(selectedId).innerHTML;
									sortitems[i].value = m;
									listQuestions[questionIndex][ddd] = m;
								}
							}
						}
					} else if (listQuestions[questionIndex].q_type === "5") {
						let optionNumver = constants.OptionNumberDragAndDrop;
						if (selectedId === 99) {
							let ddd = optionNumver[selectedId];
							let m = document.getElementById(selectedId).innerHTML;
							listQuestions[questionIndex][ddd] = m;
						} else {
							if (columns && columns[1].items && columns[1].items.length > 0) {
								for (let j = 0; j < columns[1].items.length; j++) {
									if (Number(columns[1].items[j].id) === selectedId) {
										let ddd = optionNumver[Number(columns[1].items[j].id)];
										let m = document.getElementById(selectedId).innerHTML;
										columns[1].items[j].content = m;
										listQuestions[questionIndex][ddd] = m;
									}
								}
							}
						}
						setColumns(columns);
					}
					setQuestions(listQuestions);
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				}
			}
		} else {
			if (window.getSelection) {
				if (window.getSelection().empty) {  // Chrome
					window.getSelection().empty();
				} else if (window.getSelection().removeAllRanges) {  // Firefox
					window.getSelection().removeAllRanges();
				}
			} else if (document.selection) {  // IE?
				document.selection.empty();
			}
		}
		setSelectedId(null);
		setRefresh(!refresh);
	};
	//Not required
	const onSearch = (value) => {
		setIsQuestionIndexOpened("false");
		sessionStorage.setItem("isQuestionIndexOpened", "false");
		if (value) {
			searchValue = value;
		}
		if (searchValue) {
			if (listQuestions && listQuestions.length > 0) {
				let questionIndexCount = Number(sessionStorage.getItem("questionIndexCount"));
				let questionIndexFromSession = Number(sessionStorage.getItem("questionIndex"));
				if (listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 || listQuestions[questionIndex].markedForLater === true) {
					if (listQuestions[questionIndex].q_type === "5" && listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length !== listQuestions[questionIndex].selectedAnswer.length && listQuestions[questionIndex].markedForLater === false) {
						if (checkAnswer === "true") {
							message.error(constants.SelectOptionDragAndDropTutorials);
						} else {
							message.error(constants.SelectOptionDragAndDrop);
						}
					} else {
						onSearchUpdate(searchValue);
					}
				} else {
					// message.error(constants.SelectOption);
					if (questionIndexFromSession < questionIndexCount) {
						if (listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length === 0 || listQuestions[questionIndex].markedForLater === false) {
							if (checkAnswer === "true") {
								message.error({
									content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
									style:{ marginTop : '30vh' }
								});
							} else {
								message.error({
									content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
									style:{ marginTop : '30vh' }
								});
							}
						} else {
							onSearchUpdate(searchValue);
						}
					} else {
						onSearchUpdate(searchValue);
					}
				}
			}
		}
	};
	const onSearchUpdate = (searchValue) => {
		setIsSearch("true");
		sessionStorage.setItem("isSearch", "true");
		setDisplaySearch("true");
		sessionStorage.setItem("displaySearch", "true");
		if (listQuestions && listQuestions.length > 0) {
			let searchQuestions = [];
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions[index].topic_title && searchValue === listQuestions[index].topic_title) {
					if (listQuestions[index].selectedAnswer && listQuestions[index].selectedAnswer.length > 0 || listQuestions[index].markedForLater === true) {
						let questionText = "";
						let questionTextOfDragAndDrop = "";
						if (listQuestions[index].q_type && listQuestions[index].q_type === "5") {
							questionText += listQuestions[index].question_text;
							// eslint-disable-next-line no-unused-vars
							Object.entries(constants.OptionNumbers).forEach(([key, valueData]) => {
								if (listQuestions[index][valueData]) {
									if (listQuestions[index][valueData].includes("~")) {
										questionTextOfDragAndDrop += "_____________  ";
									} else {
										questionTextOfDragAndDrop += listQuestions[index][valueData].substring(0, listQuestions[index][valueData].indexOf("|"));
									}
								}
							});
						} else {
							questionText = listQuestions[index].question_text;
						}
						searchQuestions.push({
							questionId: listQuestions[index].question_id,
							questionIndex: index,
							questionText: questionText,
							questionTextOfDragAndDrop: questionTextOfDragAndDrop
						});
					}
				}
			}
			sessionStorage.setItem("searchQuestions", JSON.stringify(searchQuestions));
		}
	};
	const resumeTest = () => {
		if (listQuestions && listQuestions.length > 0) {
			let displaySearchFromSession = sessionStorage.getItem("displaySearch");
			if (listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 || listQuestions[questionIndex].markedForLater === true || displaySearchFromSession === "true") {
				if (listQuestions[questionIndex].q_type === "5" && listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length !== listQuestions[questionIndex].selectedAnswer.length && listQuestions[questionIndex].markedForLater === false && displaySearchFromSession === "false") {
					if (checkAnswer === "true") {
						message.error(constants.SelectOptionDragAndDropTutorials);
					} else {
						message.error(constants.SelectOptionDragAndDrop);
					}
				} else {
					// if(listQuestions[questionIndex].oldSelectedAnswer){
					// 	if(listQuestions[questionIndex].q_type==="4"){
					// 		var match=true;
					// 		for (let index = 0; index < listQuestions[questionIndex].oldSelectedAnswer.length; index++) {
					// 			for (let j = 0; j < listQuestions[questionIndex].selectedAnswer.length; j++) {
					// 				if(listQuestions[questionIndex].oldSelectedAnswer[j].index!==listQuestions[questionIndex].selectedAnswer[j].index){
					// 					match=false;
					// 					break;
					// 				}
					// 			}
					// 		}
					// 		if(match===false){
					// 			listQuestions[questionIndex].selectedAnswer=listQuestions[questionIndex].oldSelectedAnswer;
					// 		}
					// 	}else if(listQuestions[questionIndex].q_type==="1"||listQuestions[questionIndex].q_type==="2"){
					// 		if(listQuestions[questionIndex].oldSelectedAnswer.toString()!==listQuestions[questionIndex].selectedAnswer.toString()){
					// 			listQuestions[questionIndex].selectedAnswer=listQuestions[questionIndex].oldSelectedAnswer;
					// 		}
					// 	}
					// }
					sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
					setQuestions(listQuestions);
					handleNextQuestion("SAVE");
					if (checkAnswer === "true") {
						let tempQuestionIndex = Number(sessionStorage.getItem("tempQuestionIndex"));
						setQuestionIndex(tempQuestionIndex);
						sessionStorage.setItem("questionIndex", tempQuestionIndex);
						sessionUpdateAfterResume();
					} else {
						var matchQuestion = false;
						for (let index = 0; index < listQuestions.length; index++) {
							if (listQuestions[index].isUpdatedToApi === false) {
								setQuestionIndex(index);
								sessionStorage.setItem("questionIndex", index);
								sessionStorage.setItem("questionIndexCount", index);
								sessionUpdateAfterResume();
								matchQuestion = true;
								break;
							}
						}
						if (matchQuestion === false) {
							let tempQuestionIndex = Number(sessionStorage.getItem("tempQuestionIndex"));
							setQuestionIndex(tempQuestionIndex);
							sessionStorage.setItem("questionIndex", tempQuestionIndex);
							sessionUpdateAfterResume();
						}
					}
				}
			} else {
				if (checkAnswer === "true") {
					message.error({
						content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
						style:{ marginTop : '30vh' }
					});
				} else {
					message.error({
						content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
						style:{ marginTop : '30vh' }
					});
				}
			}
		}
	};
	//Function to update the session storage variables after clicking on the Resume test button.
	const sessionUpdateAfterResume = () => {
		questionIndexData();
		setIsSearch("false");
		sessionStorage.setItem("isSearch", "false");
		setDisplaySearch("false");
		sessionStorage.setItem("displaySearch", "false");
		setIsQuestionIndexOpened("false");
		sessionStorage.setItem("isQuestionIndexOpened", "false");
		setSearchValue(null);
		sessionStorage.removeItem("searchValue");
	};
	//Function to select topic in search box
	const onSelect = (value) => {
		if (listQuestions && listQuestions.length > 0) {
			let questionIndexCount = Number(sessionStorage.getItem("questionIndexCount"));
			let questionIndexFromSession = Number(sessionStorage.getItem("questionIndex"));
			if (listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 || listQuestions[questionIndex].markedForLater === true) {
				if (listQuestions[questionIndex].q_type === "5" && listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length !== listQuestions[questionIndex].selectedAnswer.length && listQuestions[questionIndex].markedForLater === false) {
					setSearchValue(value);
					sessionStorage.setItem("searchValue", value);
					onSearch(value);
				} else {
					setSearchValue(value);
					handleNextQuestion("QUESTION");
					sessionStorage.setItem("searchValue", value);
					onSearch(value);
				}
			} else {
				if (questionIndexFromSession < questionIndexCount) {
					if (listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length === 0 || listQuestions[questionIndex].markedForLater === false) {
						if (checkAnswer === "true") {
							message.error({
								content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
								style:{ marginTop : '30vh' }
							});
						} else {
							message.error({
								content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
								style:{ marginTop : '30vh' }
							});
						}
					} else {
						setSearchValue(value);
						sessionStorage.setItem("searchValue", value);
						onSearch(value);
					}
				} else {
					setSearchValue(value);
					sessionStorage.setItem("searchValue", value);
					onSearch(value);
				}
			}
		}
	};
	const columnsQuestions = [
		{
			title: false,
			key: 'questions',
			align: "center",
			width: '80px',
			render: (record) => (
				<div className="d-flex d-flex-wrap" onClick={() => { handleClickSearchQuestion(record); }}>
					<Button type="link" className="search-link">
						<b><span className="test-question">Q{record && record.questionIndex !== null ? (record.questionIndex + 1) : ""}{". "}</span></b>
						<span>
							<span onCopy={() => { return false; }} className="test-question" dangerouslySetInnerHTML={{ __html: record && record.questionText ? record.questionText : "" }}></span>
							{record.questionTextOfDragAndDrop ? (<span onCopy={() => { return false; }} className="test-question d-flex" dangerouslySetInnerHTML={{ __html: record && record.questionTextOfDragAndDrop ? record.questionTextOfDragAndDrop : "" }}></span>) : ""}
						</span>
					</Button>
				</div>
			),
		},
	];
	const handleClickSearchQuestion = (record) => {
		sessionStorage.setItem("tempQuestionIndex", questionIndex);
		setQuestionIndex(Number(record.questionIndex));
		sessionStorage.setItem("questionIndex", Number(record.questionIndex));
		setDisplaySearch("false");
		sessionStorage.setItem("displaySearch", "false");
	};
	const questionIndexData = () => {
		if (questionIndexList && questionIndexList.length > 0) {
			var found = false;
			for (var i = 0; i < questionIndexList.length; i++) {
				if (questionIndexList[i].index === questionIndex) {
					found = true;
					questionIndexList[i].index = questionIndex;
					questionIndexList[i].category = listQuestions[questionIndex].category_abbreviation;
					questionIndexList[i].value = listQuestions[questionIndex].topic_title;
					questionIndexList[i].markedForLater = listQuestions[questionIndex].markedForLater;
					questionIndexList[i].notes = listQuestions[questionIndex].notes;
					questionIndexList[i].topic_abbreviation = listQuestions[questionIndex].topic_abbreviation;
					break;
				}
			}
			if (!found) {
				questionIndexList.push({
					index: questionIndex,
					category: listQuestions[questionIndex].category_abbreviation,
					value: listQuestions[questionIndex].topic_title,
					markedForLater: listQuestions[questionIndex].markedForLater,
					notes: listQuestions[questionIndex].notes,
					topic_abbreviation: listQuestions[questionIndex].topic_abbreviation,
				});
			}
		} else {
			questionIndexList.push({
				index: questionIndex,
				value: listQuestions[questionIndex].topic_title,
				category: listQuestions[questionIndex].category_abbreviation,
				markedForLater: listQuestions[questionIndex].markedForLater,
				notes: listQuestions[questionIndex].notes,
				topic_abbreviation: listQuestions[questionIndex].topic_abbreviation,
			});
		}
		// sortArrayOfObjects(questionIndexList,"index",null,true);
		setQuestionIndexList(questionIndexList);
		sessionStorage.setItem("questionIndexList", JSON.stringify(questionIndexList));
	};
	//End not required
	const onSelectOptionChange = (e, index) => {
		if (e) {
			if (listQuestions && listQuestions.length > 0) {
				listQuestions[questionIndex].answers[index].selectedOption = [e];
				// if(listQuestions[questionIndex].selectedAnswer){
				// 	listQuestions[questionIndex].selectedAnswer=[...listQuestions[questionIndex].selectedAnswer,...listQuestions[questionIndex].answers[index].selectedOption]
				// }else{
				// 	listQuestions[questionIndex].selectedAnswer=[e]
				// }
				let tempSelectedAnswers = [];
				let tempCorrectAnsweres = [];
				listQuestions[questionIndex].answers.map((item) => {
					if (item.selectedOption && item.selectedOption.length > 0) {
						tempSelectedAnswers.push(item.selectedOption[0])
					}
					if (item.corr_opt === "t") {
						tempCorrectAnsweres.push(item.answer_option_id)
					}
				})
				listQuestions[questionIndex].selectedAnswer = tempSelectedAnswers;
				listQuestions[questionIndex].correct_option = tempCorrectAnsweres;
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				// console.log("listquestions",listQuestions)
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	}
	// Function to append alphabets to the selected option
	const appendAlphabet = (option) => {
		const alphabet = String.fromCharCode(65 + option);
		return alphabet;
	};
	const singleSelect = (
		<div className="grid gap-2">
			<div className="font-normal text-xl">
				{listQuestions && listQuestions.length > 0 ?
					<Space>
						<span>Q: {questionIndex + 1}/{listQuestions && listQuestions.length} </span>
						<span className="text-base font-semibold">({listQuestions && listQuestions.length>0?listQuestions[questionIndex].cal_points:0} {Number(listQuestions[questionIndex].cal_points)===1?"point":"points"})</span>
				</Space>					: ""}
			</div>
			<div className="w-full lg:w-3/4 mx-auto grid gap-3">
				<div>
					<p className="text-2xl font-normal mb-2" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.question_text ? listQuestions[questionIndex].question_text : "" }}></p>
					<div className="text-center">{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].image_name && (featuredImageUrl(listQuestions[questionIndex].image_name, listQuestions[questionIndex].orig_img,globalPreview,setVisiblePreview,listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : ""))}</div>
				</div>
				<Radio.Group className="w-full radio-test"
					onChange={handleSingleSelectAnswer}
					value={listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.selectedAnswer && listQuestions[questionIndex]?.selectedAnswer.length > 0 && listQuestions[questionIndex].selectedAnswer[0]}
				>
					(<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
						{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.answers.map((option, index) => {
							return <Radio
								value={option.answer_option_id}
								id={option.answer_option_id}
								className=" bg-slate-200 p-2 rounded-lg text-black pl-10 relative items-center"
							>
								<span className="absolute left-0 font-semibold border border-transparent border-r-slate-300 w-8 text-center grid place-content-center h-full top-0">{appendAlphabet(index)}</span>
								<p id={option.option_id} dangerouslySetInnerHTML={{ __html: option.option_text }}></p>
							</Radio>
						})
						}
					</div>)
				</Radio.Group>
			</div>
		</div>
	);
	const getPreviewImageButton=(
		<>
				<FloatButton
				icon={<img
					src={listQuestions[questionIndex]?.image_name} alt="thumbnail"
				/>}
					tooltip={<div>Preview Image</div>}
					onClick={() => setImgPreviewVisible(true)}
					shape="square"
					className="preview-img right-14"
					description={<span className="uppercase">{constants.previewImg}</span>}
				/>
				<Image
					width={200}
					style={{
						display: "none"
					}}
					src={listQuestions[questionIndex]?.image_name}
					rootClassName="img-prev-2"
					preview={{
						visible: visible,
						src:listQuestions[questionIndex]?.orig_img,
						onVisibleChange: (value) => {
							setImgPreviewVisible(value);
						},
						closeIcon: <CloseCircleOutlined className='hidden' />,
						toolbarRender: (
							_,
							{ transform: { scale }, actions: { onZoomOut, onZoomIn } }
						) => (
							<div className="w-full lg:w-3/5 toolbar-wrapper text-base flex items-center justify-between top-20 translate-x-[-50%] left-1/2 px-6 text-black rounded-full bg-white">
								<div className='my-1.5'>
									<p className="mb-0 q-preview" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : "" }}></p>
								</div>
								<Space size={12} className='my-2'>
									<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
									<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
									<CloseCircleOutlined className='text-red-600' onClick={() => { setImgPreviewVisible(!visible) }} />
								</Space>
							</div>
						)
					}}
				/>
			</>
);
	const imageSelect = (
		<div className="grid gap-2">
			<div className="font-normal text-xl">{listQuestions && listQuestions.length > 0 ?
			<Space>
				<span>Q: {questionIndex + 1}/{listQuestions && listQuestions.length} </span>
				<span className="text-base font-semibold">({listQuestions && listQuestions.length>0?listQuestions[questionIndex].cal_points:0} {Number(listQuestions[questionIndex].cal_points)===1?"point":"points"})</span>
				</Space>
				: ""}</div>
			<div className="w-full lg:w-3/4 mx-auto grid gap-3">
				<div>
					<p className="text-2xl font-normal mb-2" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : "" }}></p>
					<div className="text-center">{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].image_name && (featuredImageUrl(listQuestions[questionIndex].image_name, listQuestions[questionIndex].orig_img,globalPreview,setVisiblePreview,listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : ""))}</div>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
					{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.answers?.map((option, index) => {
						return <div className="flex items-center">
							<span className="relative font-semibold border border-transparent border-r-slate-300 w-8 text-center">{appendAlphabet(index)}</span>
							<Select className="!w-full" value={option?.selectedOption ? option.selectedOption[0] : null} onChange={(e) => onSelectOptionChange(e, index)}>
								{option?.other_opt1?.map((optionInner) => {
									return <Option value={optionInner.answer_option_id}><p id={optionInner.answer_option_id} dangerouslySetInnerHTML={{ __html: optionInner.option_text }}></p></Option>
								})}
							</Select></div>
					})
					}
				</div>
				{getPreviewImageButton}
			</div>
		</div>
	);
	//Function to create a note
	const createNote = (values) => {
		if (values.note !== undefined && values.note !== null) {
			let tempValueNote = values.note.trim();
			let tempNote = tempValueNote.replace(/^\s*[\r\n]/gm, "\n");
			let tempNoteID = listQuestions[questionIndex].note_id;
			let indexTemp = notelist.findIndex((item) => item.question_id === listQuestions[questionIndex].question_id);
			if (tempNote !== "" && listQuestions[questionIndex].note_id === null) {
				listQuestions[questionIndex].notes = tempNote;
				let tempObj = {
					note: tempNote,
					question_id: listQuestions[questionIndex].question_id,
					is_review:0
				};
				if (indexTemp === -1) {
					notelist.push(tempObj);
				} else {
					notelist[indexTemp].note = tempNote;
				}
			} else {
				if (indexTemp !== -1 && tempNote === "") {
					notelist.splice(indexTemp, 1);
				}
				listQuestions[questionIndex].notes = null;
			}
			if (tempNote !== "" && listQuestions[questionIndex].note_id !== null) {
				listQuestions[questionIndex].notes = tempNote;
				let userId = getCookie(COOKIE.UserId);
				let payload = null;
				payload = {
					stud_id: userId,
					note: tempNote,
					note_id:tempNoteID,
					score_card_id: scoreCardId,
					question_id: listQuestions[questionIndex].question_id,
					is_review:0
				};
				fetchApi(`/update-note`, "put", payload).then((res) => {
					if (res && res.code === 200) {
						// message.success(res.message);
					} else {
						message.error(res.message);
					}
				});
			}
			if (tempNote === "" && listQuestions[questionIndex].note_id !== null) {
				fetchApi(`/delete-note/${tempNoteID}`, "delete").then((res) => {
					if (res && res.code === 200) {
						//	message.success(res.message);
						listQuestions[questionIndex].notes = null;
						listQuestions[questionIndex].note_id = null;
					} else {
						message.error(res.message);
					}
				});
			}
			hideModal();
		}
	};
	const saveNotes = () => {
		if (notelist.length > 0) {
			let userId = getCookie(COOKIE.UserId);
			let payload = null;
			let scoreCardIdFromSession = Number(sessionStorage.getItem("scoreCardId"));
			payload = {
				stud_id: userId,
				notes: notelist,
				score_card_id: scoreCardIdFromSession,
			};
			fetchApi(`/save-notes`, "post", payload).then((res) => {
				if (res && res.code === 200) {
					// message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
	};
	const checkComplete = () => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 && listQuestions[questionIndex].q_type === "1"
			|| listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length === listQuestions[questionIndex].answers.length && listQuestions[questionIndex].q_type === "2"
			|| listQuestions[questionIndex].markedForLater === true
		) {
			handleNextQuestion("final");
			var matched = false;
			for (let index = 0; index < listQuestions.length; index++) {
				if (listQuestions[index].markedForLater === true) {
					matched = true;
					break;
				}
			}
			if (matched === true) {
				setMarkedLaterVisible(true);
			} else {
				setCompleteTest(true);
			}
		}
		else {
			if (listQuestions[questionIndex].q_type === "1") {
				message.error({
					content:checkMarkLater === "false"?constants.SelectOptionOnlyTest:constants.SelectOptionOnly,
					style:{ marginTop : '30vh' }
				});
			} else {
				message.error({
					content:checkMarkLater === "false"?constants.SelectAllOptionsTest:constants.SelectAllOptions,
					style:{ marginTop : '30vh' }
				});
			}
		}
	}
	const getColorForTimer = (value) => {
		if (value) {
			let timeOfTest = value.toString();
			var splittedTime = timeOfTest.split(':'); // split it at the colons
			// minutes are worth 60 seconds. Hours are worth 60 minutes.
			var seconds = (+splittedTime[0]) * 60 * 60 + (+splittedTime[1]) * 60 + (+splittedTime[2]);
			if (seconds <= 300) {
				return "red-color-time";
			} else {
				return "black-color-time";
			}
		}
	};
	const handleClickQuestionIndex = (record) => {
		setIsQuestionIndexOpened("true");
		sessionStorage.setItem("tempQuestionIndex", questionIndex);
		sessionStorage.setItem("isQuestionIndexOpened", "true");
		setQuestionIndex(record.index);
		sessionStorage.setItem("questionIndex", record.index);
		setvisible(false);
	};
	//Back button popup functions
	function delayPromise(ms = 1000) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
			pauseTestAnswer();
		});
	}
	return (
		<div >
			<div
				style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
			>
				{location?.state?.chapter_id}
			</div>
			<div className='container mx-auto'>
				<div className='flex justify-between items-center'>
					<div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl w-4/5 lg:w-auto underline underline-offset-4 font-extrabold uppercase'>{location?.state?.chapter_name}</div>
				</div>
				<Divider className="my-1" />
				<div className={`flex flex-wrap justify-between items-center my-3 px-5`}>
					<div className='font-bold text-xl'>Test - Attempt #{location?.state?.attempt}</div>
					<div className="w-1/2">
						<Steps
							current={showResullt === true ? 2 : 1}
							items={[
								{
									title: 'Instructions',
								},
								{
									title: 'Take a test',
								},
								{
									title: 'Result',
								},
							]}
						/>
					</div>
				</div>
				{showResullt === false ?
					<div>
						<div>
							<Space size={'large'} className="flex-vertical-middle">
								{/* {listQuestions&&listQuestions.length>0?
								<span>{questionIndex+1}/{listQuestions&&listQuestions.length}</span>
						:""} */}
							</Space>
						</div>
						<Modal
							title=
							{
								<div
									style={{
										width: '100%',
										cursor: 'move',
									}}
									onMouseOver={() => {
										if (disabled) {
											setIsDisabled(false);
										}
									}}
									onMouseOut={() => {
										setIsDisabled(true);
									}} onFocus={() => { }}
									onBlur={() => { }}
								>
									Notes
								</div>
							}
							visible={notesVisible}
							onOk={hideModal}
							onCancel={hideModal}
							className="notes-modal"
							footer={null}
							modalRender={modal => (
								<Draggables
									disabled={disabled}
									bounds={bounds}
									onStart={(event, uiData) => onStart(event, uiData)}
								>
									<div ref={draggleRef}>{modal}</div>
								</Draggables>
							)}
						>
							<Form form={noteForm} onFinish={createNote}>
								<Form.Item name="note">
									<TextArea
										rows={4}
										placeholder=" Write a note"
										className="p-0"
										onPaste={(e) => {
											e.preventDefault();
											return false;
										}} onCopy={(e) => {
											e.preventDefault();
											return false;
										}}
									/>
								</Form.Item>
								<Form.Item className="mb-0">
									<Button htmlType="submit" icon={<SaveOutlined />} className="bg-green-700 hover:bg-green-600 !text-white">{constants.Save}</Button>
								</Form.Item>
							</Form>
						</Modal>
						<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
							<Spin spinning={nextAndPreviousLoading} size="large" >
								<>
								{
									listQuestions &&
										listQuestions.length > 0 &&
										listQuestions[questionIndex].q_type &&
										listQuestions[questionIndex].q_type === "1" ? singleSelect :
										listQuestions &&
											listQuestions.length > 0 &&
											listQuestions[questionIndex].q_type &&
											listQuestions[questionIndex].q_type === "2" ? imageSelect : ""
								}
								{/* // {listQuestions&&
						// listQuestions.length>0?
						// 		listQuestions[questionIndex].q_type&&
						// listQuestions[questionIndex].q_type==="2"?imageSelect:
						// "":""} */}
							</>
							<div className="lg:w-3/4 mx-auto mt-3">
								<Space size={'large'} wrap>
									<Space wrap>
										{questionIndex > 0 && checkMarkLater === "false" && displaySearch === "false" && isSearch === "false" && isQuestionIndexOpened === "false" &&
											<Button 
												className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" 
												onClick={() => { handlePreviousQuestion(); }} 
												disabled={nextAndPreviousLoading}
												icon={<ArrowLeftOutlined />}
											>
												{constants.Previous}
											</Button>
										}
										{listQuestions && listQuestions.length > 0 && questionIndex < listQuestions.length - 1 && displaySearch === "false" && isSearch === "false" && isQuestionIndexOpened === "false" && checkMarkLater === "false" ?
											(
											<Button 
												className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" 
												onClick={() => { handleNextQuestion(); }} 
												disabled={nextAndPreviousLoading}
												icon={<ArrowRightOutlined />}
											>
												{constants.Next}
											</Button>
											) : null}
										{displaySearch === "false" && isSearch === "true" ? (<Button className="rounded-lg bg-green-700 hover:bg-green-600 !text-white" onClick={() => { onSearch(); }}>
											{constants.BackToSearch}
										</Button>) : null}
										{displaySearch === "false" && isSearch === "true" || isQuestionIndexOpened === "true" ? (
											<Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<SaveOutlined />} onClick={() => { handleNextQuestion("SAVE"); }}>
												{constants.Save}
											</Button>
										) : ""}
										{isSearch === "true" && listQuestions && listQuestions.length > 0 && questionIndex < listQuestions.length - 1 ||
											isQuestionIndexOpened === "true" && listQuestions && listQuestions.length > 0 && questionIndex < listQuestions.length - 1 ? (
											<Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" onClick={() => { resumeTest(); }}>
												{constants.ResumeTest}
											</Button>
										) : ""}
										{listQuestions && listQuestions.length > 0 && displaySearch === "false" && isSearch === "false" ?
											checkAnswer === "false" && checkMarkLater === "false" ? listQuestions && listQuestions.length > 0 && questionIndex < listQuestions.length - 1 && (<Button
												className="bg-green-700 hover:bg-green-600 !text-white rounded-lg"
												onClick={() => {
													calculateAttemptedQuestions();
													setPauseTest(true);
												}}
												icon={<SaveOutlined />}
											>{constants.Save}</Button>) : ""
											: ""}
										{listQuestions && listQuestions.length > 0 && displaySearch === "false" ?
											checkAnswer === "false" && checkMarkLater === "false" ?
												listQuestions && questionIndex === listQuestions.length - 1 && (<Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" onClick={() => { checkComplete(); }} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
												: checkMarkLater === "true" ?
													// listQuestions&&questionIndex === listQuestions.length - 1&&(<Button className="btn-submit min-width-100" onClick={()=>{handleNextQuestion("SUBMIT");}} loading={nextAndPreviousLoading}>{constants.SubmitTest}</Button>)
													listQuestions &&
														markedForLaterQuestions && markedForLaterQuestions.length > 0 && listQuestions[questionIndex].question_id !== markedForLaterQuestions[markedForLaterQuestions.length - 1] ?
														(<Button className="bg-green-700 hover:bg-green-600 rounded-lg text-white" onClick={() => { handleNextQuestion(); }} disabled={nextAndPreviousLoading}>
															{constants.Next}<RightOutlined />
														</Button>) : (<Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" onClick={() => { checkComplete(); }} >{constants.SubmitTest}</Button>)
													: (<Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" onClick={() => { checkComplete(); }} >{constants.SubmitTest}</Button>)
											: ""
										}
									</Space>
									{listQuestions && listQuestions.length > 0 && displaySearch === "false" ? <Space>
										{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].q_type === "1" ?
											<Tooltip title="Strikethrough"><Button onClick={() => { handleStrikeThrough(); }} icon={<StrikethroughOutlined />} className="rounded-lg bg-blue-700 !text-white" /></Tooltip>
											: null}
										{listQuestions && listQuestions.length > 0 && checkAnswer === "false" && checkMarkLater === "false" && <Tooltip title="Mark for Later">
											<Button className="rounded-lg bg-blue-700 !text-white"
												onClick={() => (checkMarkLater === "false" && checkAnswer === "false" ? handleMarkForLater() : null)}
												icon={listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].markedForLater === true ?
													<FlagFilled /> : <FlagOutlined />} />
										</Tooltip>}
										{/* <Tooltip title="Highlight"><Button type="text" onTouchStart={(e)=>e.preventDefault()} onTouchEnd={()=>handleHighlightText()} onClick={()=>{handleHighlightText();}} icon={<HighlightOutlined className="highlight"/>}></Button></Tooltip> */}
										{/* {checkMarkLater==="false"&&<Tooltip title="Question Index"><Button type="text" onClick={showDrawer} icon={<ProfileOutlined/>}></Button></Tooltip>} */}
										{listQuestions.length > 0
											? listQuestions[questionIndex].notes !== null
												&& listQuestions[questionIndex].notes !== undefined
												&& listQuestions[questionIndex].notes !== "" ?
												<Tooltip title="Notes"><Button className="rounded-lg bg-blue-700 !text-white" icon={<Badge dot color={"blue"}><MessageOutlined /></Badge>} onClick={showModal}></Button></Tooltip>
												:
												<Tooltip title="Notes"><Button className="rounded-lg bg-blue-700 !text-white" icon={<MessageOutlined />} onClick={showModal}></Button></Tooltip>
											: null}
									</Space> : null}
								</Space>
								<div className='mt-2 bg-white p-3 rounded-lg shadow-lg'>
									<Progress className='!mb-0' percent={calculatePercentage(questionIndex + 1, listQuestions && listQuestions.length)} showInfo={false} />
								</div>
							</div>
							</Spin>
						</Card>
					</div> :
					<div>
						<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
							<div class="grid grid-cols-1 lg:grid-cols-2 divide-x">
								<div>
									<div className='text-green-700 text-xl font-semibold'>You scored {correctAnswers}/{totalPoints} </div>
									<div className="font-semibold">{"Date: " + date}</div>
									<Chart
										width="100%"
										height="300px"
										chartType="PieChart"
										// loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
										data={[
											["Answered", "Total"],
											["Correct", Number(correctAnswers)],
											["Incorrect", Number(totalPoints) - Number(Number(correctAnswers))],
										]}
										options={graphOption}
									/>
								</div>
								<div className="grid place-content-center place-items-center">
									<div className="grid gap-3 lg:w-56">
										<Button
											className='rounded-lg bg-blue-700 text-white text-left' block
											size="large" icon={<FileSearchOutlined />}	
											onClick={()=>{
												let state={
													chapter_id:location?.state?.chapter_id,
													attempt:location?.state?.attempt,
													chapter_name:location?.state?.chapter_name,
													score_card_id:scoreCardId,
													show_result:"f"
												}
												navigate('/review-test',{state, replace: true});
											}}
										>{constants.ReviewTest}</Button>
										<Button onClick={() => {
											const state = {
												chapter_id: location?.state?.chapter_id
											};
											navigate('/chapter-resources', { state, replace: true });
										}}
											className='rounded-lg bg-blue-700 text-white text-left' block
											size="large" icon={<ArrowLeftOutlined />}	>{constants.BackToChapter}</Button>
										<Button onClick={() => navigate('/dashboard', { replace: true })} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white text-left' block
											size="large">{constants.Dashboard}</Button>
									</div>
								</div>
							</div>
						</Card>
					</div>
				}
			</div>
			{displaySearch === "false" ? <Row>
				{listQuestions && listQuestions.length > 0 ?
					displayQuestionDetails(listQuestions, questionIndex)
					: ""}
			</Row> : ""}
			<Row>
				<Col span={24} style={{ visibility: "hidden" }}>
					<b>Server time:</b> {serverTime ? serverTime : ""}{"           "}
					<b>Browser time:</b> {browserTime ? browserTime : ""}
				</Col>
			</Row>
			{testModalPopup(
				pauseTest,
				completeTest,
				categoryDetails,
				topicDetails,
				viewTimer,
				timerDisplay,
				toggleTimer,
				attemptedQuestions,
				pauseTestAnswer,
				saveNotes,
				pauseTestLoading,
				setPauseTest,
				{},
				checkAnswers,
				completeTestApi,
				completeTestLoading,
				lastTenMinutes,
				setLastTenMinutes,
				markedLaterVisible,
				handleOkMarkForLaterModal,
				visibleTimeOut,
				handleTimeOutOk,
				null,
				null,
				location?.state?.attempt,
				stopBlockerCall
			)}
			{/* {Prompt} */}
			<ReactRouterPrompt 
				when={isDirty}
				beforeConfirm={async () => {
          await delayPromise();
        }}
        // beforeCancel={() => 
				// //	delayPromise()
				// }
			>
				{({ isActive, onConfirm, onCancel }) =>
						isActive && (
							<Modal 
								open={isActive}
								footer={null}
								closeIcon={null}
							>
								<div>
									<p>{constants.TestBackNavigationMessage}</p>
									<div className="text-right mt-3">
										<Space>
											<Button className="bg-blue-700 text-white" onClick={onCancel}>
											Cancel
										</Button>
										<Button type="submit" className="bg-green-700 hover:bg-green-600 text-white" onClick={onConfirm}>
											Ok
										</Button>
										</Space>
									</div>
								</div>
							</Modal>
						)
					}
			</ReactRouterPrompt>
		</div>
	);
};
export default TakeTest;
