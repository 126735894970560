import React, { useState, useEffect } from 'react';
import { getCustomisedBreadcrumbs } from '../../../functions/functions';
import { CheckOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
import { Link } from 'react-router-dom';
import { fetchAdminApi, fetchApi } from '../../../services/api';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
	Breadcrumb,
	Row,
	Input,
	Col,
	Card,
	Table,
	Form,
	Select,
	Tooltip,
	Space,
	Button,
	message
} from 'antd';
import dayjs from 'dayjs';

const EditStudent = () => {
	const [form] = Form.useForm();
	const [newCrumb, setNewcrumb] = useState();
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [countryid, setCountryid] = useState('');
	const [countries, setCountries] = useState([]);
	const [rolevalue, setRolevalue] = useState('');
	const [studentdetails, setStudentDetails] = useState([]);
	const [registereddate, setRegistereddate] = useState('');
	const [resourcedata, setResourceData] = useState([]);
	const [studentId, setStudentId] = useState('');
	let studentInfo;
	const roles = [
		{ role: constants.studentTitle, key: '0' },
		{ role: constants.professionalTitle, key: '1' },
		{ role: constants.facultyTitle, key: '2' },
		{ role: constants.otherTitle, key: '3' },
	]

	let queryString = window.location.href;
	const isEdit = queryString.substring(queryString.lastIndexOf('/') + 1) === 'edit-student' ? true : false;
	const [stateBlock, setStateBlock] = useState(false);
	const [uniBlock, setUniBlock] = useState(false);
	const [listStates, setListStates] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [otheruniversity, setOtherUniversity] = useState(false);

	useEffect(() => {
		getAllCountries();
		getStates();
		getSessionStorageDataAndSetBreadcumbs('studentFromRegisteredStudentPage', 1);
		getSessionStorageDataAndSetBreadcumbs('studentFromOverallFeedbackpage', 2);
		getSessionStorageDataAndSetBreadcumbs('studentFromChapterFeedbackpage', 2);
		getSessionStorageDataAndSetBreadcumbs('studentFromIssueFeedbackpage', 2);
		getSessionStorageDataAndSetBreadcumbs('studentFromTestResultPage', 1);
		getSessionStorageDataAndSetBreadcumbs('studentFromTutorialResultPage', 1);
		getSessionStorageDataAndSetBreadcumbs('studentFromFlashcardResultPage', 1);
	}, [])
	useEffect(() => {
		if (studentId) {
			getStudentInfo();
			getStudentResourceRelatedData();
		}
	}, [studentId]);

	const getSessionStorageDataAndSetBreadcumbs = (sessionKey, type) => {
		if (sessionStorage.getItem(sessionKey)) {
			studentInfo = JSON.parse(sessionStorage.getItem(sessionKey));
			setStudentId(studentInfo?.studentId);
			type == 1 ?
				setNewcrumb([<Link key={0} to="/analysis-menus" className="font-semibold">Analysis</Link>, <a key={1} className="!text-black font-semibold">{isEdit ? constants.EditStudent : constants.viewStudent}</a>])
				:
				setNewcrumb([<Link key={0} to="/feedback-menus" className="font-semibold">Feedbacks</Link>, <a key={1} className="!text-black font-semibold">{isEdit ? constants.EditStudent : constants.viewStudent}</a>])
		}
	}
	const getStudentResourceRelatedData = () => {
		fetchAdminApi(`/get-student-details/${studentId}`, 'get').then((res) => {
			if (res?.data) {
				setResourceData(res?.data);
			} else {
				setResourceData([]);
			}
		})
	}
	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}
	const getAllUniversities = (region_name) => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res?.data) {
					setUniversities(res?.data?.[region_name]?.universities);
					Object.entries(res.data).forEach(([key, value]) => {
						if (key == "Alaska") {
							for (let i = 0; i < value?.universities.length; i++) {
								if (value?.universities?.region_code === undefined) {
									value?.universities?.splice(i, 1);
								}
							}
						}
					});
				} else {
					setUniversities([]);
				}
			}
		);
	};

	const getStudentInfo = () => {
		fetchAdminApi(`/get-student?stud_id=${sessionStorage.getItem("studentFromRegisteredStudentPage") ? studentId : sessionStorage.getItem("studentFromOverallFeedbackpage") ? studentId : sessionStorage.getItem("studentFromChapterFeedbackpage") ? studentId : sessionStorage.getItem("studentFromIssueFeedbackpage") ? studentId : sessionStorage.getItem("studentFromTestResultPage") ? studentId : sessionStorage.getItem("studentFromTutorialResultPage") ? studentId : sessionStorage.getItem("studentFromFlashcardResultPage") ? studentId : ''}`, 'get').then((res) => {
			if (res?.data) {
				setStudentDetails(res?.data);
				setCountryid(res?.data[0].country_id);
				setRolevalue(res?.data[0].role);
				setRegistereddate(res?.data[0].dt_created);
				if(res?.data?.[0]?.country_code === "US"){
					getAllUniversities(res?.data[0]?.region_name);
					setStateBlock(true);
					setUniBlock(true);
				}else{
					setUniBlock(false);
					setStateBlock(false);
				}
				form.setFieldsValue({
					firstName: res?.data[0].stud_firstname,
					lastName: res?.data[0].stud_lastname,
					primaryEmail: res?.data[0].stud_email,
					alternateEmail: res?.data[0].alternate_stud_email,
					contactNumber: res?.data[0].stud_contact_no,
					role: res?.data[0].role,
					otherRole: res?.data[0]?.other_role,
					country: res?.data[0].country_id,
					state: isEdit ? res?.data[0].state_id : res?.data[0].region_name,
					university: isEdit ? res?.data[0]?.university_id : res?.data[0].university_name,
					registeredDate: res?.data[0].dt_created,
				})
			} else {
				setStudentDetails([]);
			}
		})
	}
	const studentRelatedResourceTabColumns = [
		{
			title: <Tooltip title='Chapter Id' className="cursor-default">Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex: 'chapter_id',
			align: 'center',
			width: 10,
		},
		{
			title: <div className="text-base">{constants.chapterTitle}</div>,
			key: 'Title',
			dataIndex: 'chapter_title',
			align: 'left',
			width: 200,
		},
		{
			title: <div className="text-base">{`${constants.Tests} (${constants.QuestionCount} ${constants.questionsAbbr})`}</div>,
			children: [
				{
					title: <Tooltip title={constants.attemptOne} className="cursor-default">{constants.attempt1Abbr}</Tooltip>,
					key: 'Attempt#1',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.test_attempt1 == 'P' ? 'Paused' : record.test_attempt1 == '0' ? <MinusOutlined /> : record.test_attempt1}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptTwo} className="cursor-default">{constants.attempt2Abbr}</Tooltip>,
					key: 'Attempt#2',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.test_attempt2 == 'P' ? 'Paused' : record.test_attempt2 == '0' ? <MinusOutlined /> : record.test_attempt2}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptThree} className="cursor-default">{constants.attempt3Abbr}</Tooltip>,
					key: 'Attempt#3',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.test_attempt3 == 'P' ? constants.paused : record.test_attempt3 == '0' ? <MinusOutlined /> : record.test_attempt3}</span>
						)
					}
				}
			],
		},
		{
			title: <div className="text-base">{`Tutorials (${constants.TutorialCount} Qns)`}</div>,
			children: [
				{
					title: <Tooltip title={constants.attemptOne} className="cursor-default">{constants.attempt1Abbr}</Tooltip>,
					key: 'Attempt#1',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.tutorial_attempt1 != '0' ? record.tutorial_attempt1 : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptTwo} className="cursor-default">{constants.attempt2Abbr}</Tooltip>,
					key: 'Attempt#2',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.tutorial_attempt2 != '0' ? record.tutorial_attempt2 : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.markedForLater} className="cursor-default">{constants.markedForlaterAbbr}</Tooltip>,
					key: constants.markedForlaterAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.mark_for_later_count != '0' ? record.mark_for_later_count : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.incorrectAnswers} className="cursor-default">{constants.incorrectAnswerAbbr}</Tooltip>,
					key: constants.incorrectAnswerAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.incorrect_ans_count != '0' ? record.incorrect_ans_count : <MinusOutlined />}</span>
						)
					}
				}
			],
		},
		{
			title: <div className="text-base">{constants.Flashcards}</div>,
			children: [
				{
					title: <Tooltip title={constants.pausedFlashcardCount} className="cursor-default">{constants.paused}</Tooltip>,
					key: 'Paused',
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.flashcard_paused != "0" ? record.flashcard_paused : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.flashcardCompletedDate} className="cursor-default">{constants.completed}</Tooltip>,
					key: 'Completed',
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.flashcard_completed ? record.flashcard_completed : <MinusOutlined />}</span>
						)
					}
				},
			],
		},
		{
			title: <div className="text-base">{constants.Images}</div>,
			children: [
				{
					title: <Tooltip title={constants.viewedImgCount} className="cursor-default">{constants.viewed}</Tooltip>,
					key: constants.viewed,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.images_viewed ? record.images_viewed : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.totalImgCount} className="cursor-default">{constants.total}</Tooltip>,
					dataIndex: 'total_images',
					key: 'Total',
					align: 'center',
					width: 10,
				},
			],
		},
		{
			title: <div className="text-base">{constants.studyGuide}</div>,
			key: constants.studyGuide,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					record.studyguide != null ?
						<div><Tooltip title={`${record.studyguide != null ? `Downloaded on ${record.studyguide}` : ''}`}><CheckOutlined className='text-pink-700 font-bold' /></Tooltip></div>
						:
						<div><MinusOutlined /></div>
				)
			},
		},
		{
			title: <div className="text-base">Feedback</div>,
			children: [
				{
					title: <Tooltip title={constants.Images} className="cursor-default">{constants.imageAbbr}</Tooltip>,
					key: constants.imageAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.images_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
								record.images_rating == '1' ?
									<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
									:
									record.images_rating == '2' ?
										<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
										:
										record.images_rating == '3' ?
											<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
											:
											record.images_rating == '4' ?
												<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
												:
												<MinusOutlined />
						)
					},
				},
				{
					title: <Tooltip title={constants.Flashcards} className="cursor-default">{constants.flashcardAbbr}</Tooltip>,
					key: constants.flashcardAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.flashcards_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
								record.flashcards_rating == '1' ?
									<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
									:
									record.flashcards_rating == '2' ?
										<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
										:
										record.flashcards_rating == '3' ?
											<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
											:
											record.flashcards_rating == '4' ?
												<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
												:
												<MinusOutlined />
						)
					},
				},
				{
					title: constants.Tutorials,
					key: constants.Tutorials,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.tutorials_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
								record.tutorials_rating == '1' ?
									<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
									:
									record.tutorials_rating == '2' ?
										<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
										:
										record.tutorials_rating == '3' ?
											<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
											:
											record.tutorials_rating == '4' ?
												<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
												:
												<MinusOutlined />
						)
					},
				},
				{
					title: constants.Tests,
					key: constants.Tests,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.tests_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
								record.tests_rating == '1' ?
									<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
									:
									record.tests_rating == '2' ?
										<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
										:
										record.tests_rating == '3' ?
											<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
											:
											record.tests_rating == '4' ?
												<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
												:
												<MinusOutlined />
						)
					},
				},
			],
		},
	]
	const getRolevalue = (value) => {
		setRolevalue(value);
	}
	const getFirstname = (e) => {
		setFirstname(e.target.value);
	}
	const getLastname = (e) => {
		setLastname(e.target.value);
	}
	const getCountry = (value, option) => {
		if (option?.key==="US") {
			form.setFieldsValue({
				state:"",
				university:""
			});
			setUniversities([]);
			setStateBlock(true);
			setUniBlock(true);
		}
		else {
			setStateBlock(false);
			setUniBlock(false);
		}
	}
	const filterOption = (input, option) =>
		(option?.children.toLowerCase().includes(input.toLowerCase()));

	const redirectToEdit = () => {
		let studentDetails = {};
		studentDetails.studentId = studentId;
		sessionStorage.setItem("studentFromRegisteredStudentPage", JSON.stringify(studentDetails));
		window.open(constants.BaseUrl + '/edit-student');
	};

	const checkDisableInput = () => {
		return isEdit ? false : true;
	};

	const getStates = () => {
		fetchApi(`/states?country_code=US`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setListStates(res.data);
			} else {
				setListStates([]);
			}
		});
	};

	const onChangeState = (e, option) =>{
		form.setFieldsValue({ university:null });
		getAllUniversities(option?.children);
	};

	const handleSave = (value) => {
		let payload = {
			stud_id: studentId,
			stud_firstname: value?.firstName,
			stud_lastname: value?.lastName,
			stud_email: value?.primaryEmail,
			stud_contact_no: value?.contactNumber,
			state_id: value?.state,
			country_id: value?.country,
			occupation: value?.role,
			other_occupation: value?.otherRole,
			university_id: otheruniversity ? null : value?.university,
			other_university: value?.otherUniversity,
			alternate_stud_email: value?.alternateEmail
		} 
		fetchAdminApi(`/edit-student-profile`, "put",payload).then((res) => {
			if (res?.code === 200) {
				getStudentInfo();
				message.success(res?.message);
			} else {
				message.error(res?.message);
			}
		});
	};

	const getUniversityvalue = (value, option) => {
    if (option.children === 'Other') {
			form.setFieldsValue({otherUniversity:null});
      setOtherUniversity(true);
    } else {
      setOtherUniversity(false);
    }
  };

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.homePageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title={
							<Space>
								<span className='uppercase'>{isEdit ? constants.EditStudent : constants.viewStudentUppercase}</span>
								{!isEdit ? (<Button onClick={redirectToEdit} type='primary' className='font-semibold text-sm hidden'>{constants.EditStudent}</Button>) : null}
							</Space>
						} hoverable className="addQcard">
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card>
										<Form form={form} layout='vertical' className='LoginForm viewStudentForm'
											initialValues={
												{
													country: countryid,
													role: rolevalue,
												}
											} autocomplete="off"
											onFinish={handleSave}
										>
											<Row gutter={24}>
												<Col span={8}>
													<Form.Item label={constants.firstNameUppercase} name='firstName'>
														<Input onChange={getFirstname} value={firstname} readOnly={checkDisableInput()}></Input>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item label={constants.lastNameUppercase} name='lastName'>
														<Input onChange={getLastname} value={lastname} readOnly={checkDisableInput()}></Input>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item label={constants.roleUppercase} name='role'>
														<Select onChange={getRolevalue} className={`cursor-text ${!isEdit ? "pointer-events-none" : ""}`} value={rolevalue} >
															{roles.map((role, index) => (
																<Select.Option key={role.key} title=" ">
																	{role.role}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												{rolevalue === "3" ?
													<Col span={8}>
														<Form.Item label="Occupation"
															name="otherRole"
															rules={[
																{
																	required: true,
																	message: constants.requiredOccupation,
																},
															]}
														>
															<Input></Input>
														</Form.Item>
													</Col>
													:
													null
												}
												<Col span={8}>
													<Form.Item label={constants.countryUppercase} name='country'>
														<Select onChange={getCountry} showSearch filterOption={filterOption} className={`${!isEdit ? "pointer-events-none" : ""}`}>
															{countries.map((country) => (
																<Select.Option key={country?.country_code} value={country?.country_id}>
																	{country?.country_name}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												<Col span={8}>
													{
														isEdit ?
															stateBlock
																?
																<Form.Item label="State"
																	name="state"
																	rules={[
																		{
																			required:  true ,
																			message: constants.requiredState,
																		},
																	]}
																>
																	<Select onChange={onChangeState} showSearch filterOption={filterOption}>
																		{listStates.map((state, index) => (
																			<Select.Option key={state?.region_code} value={state?.region_id}>
																				{state?.region_name}
																			</Select.Option>
																		))}
																	</Select>
																</Form.Item>
																:
																<div></div> :
															<Form.Item label={constants.stateUppercase} name='state'>
																<Input readOnly></Input>
															</Form.Item>
													}
												</Col>
												<Col span={8}>
													{
														isEdit ?
															uniBlock ?
																<Form.Item label="University"
																	name="university"
																	rules={[
																		{
																			required: true,
																			message: constants.requiredUniversity,
																		},
																	]}
																>
																	<Select
																		showSearch
																		filterOption={(input, option) => {
																			if (option.children) {
																				return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
																			} else if (option.label) {
																				return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
																			}
																		}}
																		onChange={getUniversityvalue} 
																	>
																		{listUniversities?.map((value, index) => {
																			return <Select.Option key={value?.university_id} value={String(value?.university_id)}>
																				{value.university_name + (value.city ? "," + value.city : "")}
																			</Select.Option>
																		})}
																	</Select>
																</Form.Item>
																:
																<div></div>
															:
															<Form.Item label={constants.universityUppercase} name='university'>
																<Input readOnly></Input>
															</Form.Item>
													}
												</Col>
												{otheruniversity ?
													<Col span={8}>
														<Form.Item label="University Name"
															name="otherUniversity"
															rules={[
																{
																	required: true,
																	message: constants.requiredUniversity,
																},
															]}
														>
															<Input></Input>
														</Form.Item>
													</Col>
													:
													<div></div>
												}
												<Col span={8}>
													<Form.Item label={constants.primaryEmailUppercase} name='primaryEmail'
														rules={[
															{
																type: 'email',
																message: '',
															},
														]}
													>
														<Input readOnly={checkDisableInput()}></Input>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item label={constants.alternateEmailUppercase} name='alternateEmail'
														rules={[
															{
																type: 'email',
																message: '',
															},
														]}
													>
														<Input readOnly={checkDisableInput()}></Input>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item label={constants.contactUppercase} name='contactNumber'>
														<PhoneInput country={'us'} placeholder="1(999)123-4567" className="contactInput" disabled={checkDisableInput()} />
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item label={constants.registeredOn} name='registeredDate'>
														<Input readOnly></Input>
													</Form.Item>
												</Col>
											</Row>
											{
												isEdit && (<Button htmlType='submit' type='primary' className='font-semibold text-sm'>{constants.Save}</Button>)
											}
										</Form>
									</Card>
								</Col>
							</Row>

						</Card>
						{!isEdit && (
							<Table
								bordered
								columns={studentRelatedResourceTabColumns}
								dataSource={resourcedata}
								className='registeredStudsTable viewStudentTab'
								scroll={{ x: 768 }}
								pagination={false}
							></Table>
						)}
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default EditStudent
