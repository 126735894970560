import React, { useState, useEffect } from 'react';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../../functions/functions';
import constants from '../../../../constants/constants';
import {
	Breadcrumb,
	Divider,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Empty,
	Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchAdminApi } from '../../../../services/api/index';
import { Chart } from "react-google-charts";
const { RangePicker } = DatePicker;

const TotalResourcesTaken = () => {
	const [form] = Form.useForm();
	const dateFormat = 'MM/DD/YYYY';
	const navigate = useNavigate();
	const location = useLocation();
	const { totalTestObject } = location.state || {};
	const { totalTutorialObject } = location.state || {};
	const [resourcetype, setResourcetype] = useState(sessionStorage.getItem("selectedSection"));
	const [newCrumb, setNewCrumb] = useState();
	//Test related states
	const [startdatefortest, setStartDateForTest] = useState(totalTestObject ? totalTestObject?.startdate : dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortest, setEndDateForTest] = useState(totalTestObject ? totalTestObject?.enddate : dayjs().endOf('year').format('MM/DD/YYYY'));
	const [testcount, setTotalTestCount] = useState();
	const [testdata, setTestData] = useState([]);
	const [testChartdata, setTestChartData] = useState([]);
	const [periodfortest, setPeriodForTest] = useState(0);
	//Tutorial related states
	const [startdatefortutorial, setStartDateForTutorial] = useState(totalTutorialObject ? totalTutorialObject?.startdate : dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortutorial, setEndDateForTutorial] = useState(totalTutorialObject ? totalTutorialObject?.enddate : dayjs().endOf('year').format('MM/DD/YYYY'));
	const [tutorialcount, setTotalTutorialCount] = useState();
	const [tutorialdata, setTutorialData] = useState([]);
	const [tutorialChartdata, setTutorialChartData] = useState([]);
	const [periodfortutorial, setPeriodForTutorial] = useState(0);

	useEffect(() => {
		if (totalTestObject) {
			setNewCrumb([<Link key={0} to="/total-tests-taken" className="font-semibold">
				{constants.totalTestsTakenBreadcrumbLink}</Link>, <a key={1} className="!text-black font-semibold">{constants.testsTakenBreadcrumbLink}</a>])
		} else if (totalTutorialObject) {
			setNewCrumb([<Link key={0} to="/total-tutorials-taken" className="font-semibold">
				{constants.totalTutorialsTakenBreadcrumbLink}</Link>, <a key={1} to='' className="!text-black font-semibold">{constants.tutorialsTakenBreadcrumbLink}</a>])
		} else {
			setNewCrumb([<Link className="!text-black font-semibold cursor-text">{resourcetype == 0 ? constants.testsTakenBreadcrumbLink : constants.tutorialsTakenBreadcrumbLink}</Link>])
		}

		if (sessionStorage.getItem('fromTestsTaken')) {
			setNewCrumb([<Link key={0} to="/total-tests-taken" className="font-semibold">
				{constants.totalTestsTakenBreadcrumbLink}</Link>, <a key={1} className="!text-black font-semibold">{constants.testsTakenBreadcrumbLink}</a>])
		} else if (sessionStorage.getItem('fromTutorialsTaken')){
			setNewCrumb([<Link key={0} to="/total-tutorials-taken" className="font-semibold">
				{constants.totalTutorialsTakenBreadcrumbLink}</Link>, <a key={1} to='' className="!text-black font-semibold">{constants.tutorialsTakenBreadcrumbLink}</a>])
		}
	}, [])
	useEffect(() => {
		if (resourcetype && (startdatefortest && enddatefortest) || (startdatefortutorial && enddatefortutorial)) {
			getStackedBarchartdata();
		}
	}, [resourcetype, startdatefortest, enddatefortest, startdatefortutorial, enddatefortutorial]);
	const testTableColumns = [
		{
			title: <Tooltip title='Chapter Id'>Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex: 'chapter_id',
			align: 'center',
			width: 5,
		},
		{
			title: constants.chapterTitle,
			key: constants.chapterTitle,
			width: 200,
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTestDataAndRedirect(record, index)}>
						{record.chapter_title}
					</div>
				)
			}
		},
		{
			title: 'Attempts',
			children: [
				{
					title: <Tooltip title={constants.attemptOne} className="cursor-default">{constants.attempt1Abbr}</Tooltip>,
					key: 'Attempt#1',
					align: 'center',
					width: 20,
					render: (text, record, index) => {
						return (
							<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTestAttemptDataAndRedirect(record,index,1)}>
								{record.attempt1}
							</div>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptTwo} className="cursor-default">{constants.attempt2Abbr}</Tooltip>,
					key: 'Attempt#2',
					align: 'center',
					width: 20,
					render: (text, record, index) => {
						return (
							<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTestAttemptDataAndRedirect(record, index, 2)}>
								{record.attempt2}
							</div>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptThree} className="cursor-default">{constants.attempt3Abbr}</Tooltip>,
					key: 'Attempt#3',
					align: 'center',
					width: 20,
					render: (text, record, index) => {
						return (
							<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTestAttemptDataAndRedirect(record,index,3)}>
								{record.attempt3}
							</div>
						)
					}
				}
			],
		},
		{
			title: constants.total,
			key: constants.total,
			dataIndex: 'total',
			align: 'center',
			width: 20,
		},
	]
	const tutorialTableColumns = [
		{
			title: <Tooltip title='Chapter Id'>Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex: 'chapter_id',
			align: 'center',
			width: 5,
		},
		{
			title: constants.chapterTitle,
			key: constants.chapterTitle,
			width: 200,
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTutorialDataAndRedirect(record, index)}>
						{record.chapter_title}
					</div>
				)
			}
		},
		{
			title: <Tooltip title='Attempt 1' className="cursor-default">{constants.attempt1Abbr}</Tooltip>,
			key: 'Attempt#1',
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={()=> getTutorialAttemptDataAndRedirect(record, index, 1)}>
						{record.attempt1}
					</div>
				)
			}
		},
		{
			title: <Tooltip title='Attempt 2' className="cursor-default">{constants.attempt2Abbr}</Tooltip>,
			key: 'Attempt#2',
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTutorialAttemptDataAndRedirect(record, index, 2)}>
						{record.attempt2}
					</div>
				)
			}
		},
		{
			title: <Tooltip title='Marked For Later' className="cursor-default">{constants.markedForlaterAbbr}</Tooltip>,
			key: constants.markedForlaterAbbr,
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTutorialAttemptDataAndRedirect(record, index, 4)}>
						{record.marked_for_later_count}
					</div>
				)
			}
		},
		{
			title: <Tooltip title='Incorrect Answers' className="cursor-default">{constants.incorrectAnswerAbbr}</Tooltip>,
			dataIndex: 'incorrect_count',
			key: 'IA',
			align: 'center',
			width: 10,
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getTutorialAttemptDataAndRedirect(record, index, 5)}>
						{record.incorrect_count}
					</div>
				)
			}
		},
		{
			title: constants.total,
			dataIndex: 'total',
			key: constants.total,
			align: 'center',
			width: 10,
		},
	]
	//Redirection to test/tutorial results page
	const getTestDataAndRedirect = (record, index) => {
		let recordData = {};
		recordData.chapterId = record.chapter_id;
		recordData.startdate = startdatefortest;
		recordData.enddate = enddatefortest;
		navigate('/test-result', { state: { chapterwisetestObject: recordData } });
	}
	const getTestAttemptDataAndRedirect = (record, index,attempt) => {
		let recordData = {};
		recordData.chapterId = record.chapter_id;
		recordData.startdate = startdatefortest;
		recordData.enddate = enddatefortest;
		recordData.attempt = attempt;
		navigate('/test-result', { state: { attemptwisetestObject: recordData } });
	}
	const getTutorialDataAndRedirect = (record, index) => {
		let recordData = {};
		recordData.chapterId = record.chapter_id;
		recordData.startdate = startdatefortutorial;
		recordData.enddate = enddatefortutorial;
		navigate('/tutorial-result', { state: { chapterwisetutorialObject: recordData } });
	}
	const getTutorialAttemptDataAndRedirect = (record,index,attempt) => {
		let recordData = {};
		recordData.chapterId = record.chapter_id;
		recordData.startdate = startdatefortutorial;
		recordData.enddate = enddatefortutorial;
		recordData.attempt = attempt;
		navigate('/tutorial-result', { state: { attemptwisetutorialObject: recordData } });
	}

	//Generate chart
	const getStackedBarchartdata = () => {
		fetchAdminApi(`/tests-taken-chapterwise?start_date=${changeDateFormat(resourcetype == 0 ? startdatefortest : startdatefortutorial)}&end_date=${changeDateFormat(resourcetype == 0 ? enddatefortest : enddatefortutorial)}&type=${resourcetype}`, "get").then(
			(res) => {
				if (res && res?.data.all.length > 0) {
					let chartHeader;
					resourcetype == 0 ?
						chartHeader = [['Chapters', 'Attempt1', 'Attempt2', 'Attempt3']]
						:
						chartHeader = [['Chapters', 'Attempt1', 'Attempt2', 'MFL', 'I/C']]
					for (let index = 0; index < res?.data?.all.length; index++) {
						if (res?.data.all[index].total) {
							let all = [];
							resourcetype == 0 ?
								all.push(
									`Ch#${res?.data?.all[index].chapter_id}`, Number(res?.data.all[index].attempt1
									), Number(res?.data.all[index].attempt2), Number(res?.data.all[index].attempt3)
								)
								:
								all.push(
									`Ch#${res?.data?.all[index].chapter_id}`, Number(res?.data.all[index].attempt1
									), Number(res?.data.all[index].attempt2), Number(res?.data.all[index].marked_for_later_count), Number(res?.data.all[index].incorrect_count)
								)
							chartHeader.push(all);
						}
					}
					resourcetype == 0 ? setTestChartData(chartHeader) : setTutorialChartData(chartHeader);
					resourcetype == 0 ? setTestData(res?.data?.all) : setTutorialData(res?.data?.all);
					resourcetype == 0 ? setTotalTestCount(res?.data?.all_total) : setTotalTutorialCount(res?.data?.all_total);
				} else {
					setTestChartData([]);
					setTestData([]);
					setTotalTestCount(0);
					setTotalTutorialCount(0);
				}
			})
	}
	//Chart options
	const options = {
		title: `${resourcetype == 0 ? constants.Tests : constants.Tutorials} taken for the period ${resourcetype == 0 ? startdatefortest : startdatefortutorial} - ${resourcetype == 0 ? enddatefortest : enddatefortutorial}`,
		titleTextStyle: {
			fontSize: 20,
			bold: 'true',
			color: '#000',
		},
		vAxis: {
			title: constants.chapters,
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true',
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "40%" },
		bars: "horizontal",
		colors: resourcetype == 0 ? constants.testsTakenChartColorArr : constants.tutorialsTakenChartColorArr,
		is3D: true,
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		legend: { position: 'top' },
		'hAxis': {
			title: resourcetype == 0 ? constants.numberOfTests : constants.numberOfTutorials, gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: '#000', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
			minValue: 1,
			viewWindow: {
				min: 0
			}
		},
		chartArea: { width: resourcetype == 0 ? "60%" : '65%', height: "80%" },
		isStacked: true,
		tooltip: { isHtml: true },
	};
	//Onchange set value for rangepicker(Tests)
	const getStartandEnddateForTests = (value, dateString) => {
		setPeriodForTest(null);
		setStartDateForTest(dateString[0]);
		setEndDateForTest(dateString[1]);
	}
	//Onchange set value for rangepicker(Tutorials)
	const getStartandEnddateForTutorials = (value, dateString) => {
		setPeriodForTutorial(null);
		setStartDateForTutorial(dateString[0]);
		setEndDateForTutorial(dateString[1]);
	}

	const onChangePeiod = (value, type) => {
		let sted = getStartDateAndEndDate(value);
		if (type === 1) {
			setPeriodForTest(value);
			setStartDateForTest(dayjs(sted?.startDate).format(constants.DateFormat));
			setEndDateForTest(dayjs(sted?.endDate).format(constants.DateFormat));
		} else {
			setPeriodForTutorial(value);
			setStartDateForTutorial(dayjs(sted?.startDate).format(constants.DateFormat));
			setEndDateForTutorial(dayjs(sted?.endDate).format(constants.DateFormat));
		}
	};

	return (
		<div className='cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3'/>
						</div>
						<Card title={resourcetype == 0 ? constants.testsTaken : constants.tutorialsTaken} className="addQcard" hoverable>
									<div className="flex justify-between">
								<div><span className="font-bold text-base">{constants.searchCriteriaTitle}</span> <span className='font-bold text-red-700 pl-1 text-base'>Period : </span><span className="text-base font-bold text-green-700 pl-2">{resourcetype == 0 ? startdatefortest : startdatefortutorial} - {resourcetype == 0 ? enddatefortest : enddatefortutorial}</span></div>
										<div>
												<Form form={form} className='countriesByregistrationFilters'>
													<Row gutter={24}>
														<Col>
															<Form.Item label="From">
																{resourcetype == 0 ?
																	<RangePicker
																		defaultValue={[dayjs(startdatefortest, dateFormat), dayjs(enddatefortest, dateFormat)]}
																		format={dateFormat}
																		className='w-full'
																		onChange={getStartandEnddateForTests}
																		value={[dayjs(startdatefortest, dateFormat), dayjs(enddatefortest, dateFormat)]}
																		allowClear={false}
																	/>
																	:
																	<RangePicker
																		defaultValue={[dayjs(startdatefortutorial, dateFormat), dayjs(enddatefortutorial, dateFormat)]}
																		format={dateFormat}
																		className='w-full'
																		onChange={getStartandEnddateForTutorials}
																		value={[dayjs(startdatefortutorial, dateFormat), dayjs(enddatefortutorial, dateFormat)]}
																		allowClear={false}
																	/>
																}
															</Form.Item>
														</Col>
														<Col>
												<Form.Item label="Period" className="!w-64">
																{resourcetype == 0 ?
														<Select className='rounded-md'
															placeholder={constants.Period}
															onChange={(e) => onChangePeiod(e, 1)}
															value={periodfortest}
																	>
																		{constants.periodOptions.map((option, index) => (
																			<Select.Option key={option} value={index}>
																				{option}
																			</Select.Option>
																		))}
																	</Select>
																	:
														<Select className='rounded-md'
																		onChange={(e)=>onChangePeiod(e,2)}
																		value={periodfortutorial}
																		placeholder={constants.Period}
																	>
																		{constants.periodOptions.map((option, index) => (
																			<Select.Option key={option} value={index}>
																				{option}
																			</Select.Option>
																		))}
																	</Select>
																}
															</Form.Item>
														</Col>
													</Row>
												</Form>
										</div>
									</div>
								<Divider className='!my-3'></Divider>
							<Row>
								<Col span={24}>
									<div>
											<Chart
												chartType="BarChart"
												className='w-full'
												width={'100%'}
												height={'600px'}
												loader={<div>{constants.loadingChartMessage}</div>}
												data={resourcetype == 0 ? testChartdata : tutorialChartdata}
												options={options}
											/>
									</div>
								</Col>
								<Col span={24}>
									<Table
										bordered
										dataSource={resourcetype == 0 ? testdata : tutorialdata}
										columns={resourcetype == 0 ? testTableColumns : tutorialTableColumns}
										className='registrationTable'
										rowKey={(record) => record.month_in_words}
										title={() => { return <div className="font-bold text-base text-white">{resourcetype == 0 ? 'TESTS TAKEN' : 'TUTORIALS TAKEN'}</div> }}
										size="small"
										summary={(pageData) => {
											let totalAttempts1 = 0;
											let totalAttempts2 = 0;
											let totalAttempts3 = 0;
											let markForlaterCount = 0;
											let incorrectAnswerCount = 0;
											pageData.forEach(({ attempt1, attempt2, attempt3, marked_for_later_count, incorrect_count
											}) => {
												totalAttempts1 += Number(attempt1);
												totalAttempts2 += Number(attempt2);
												totalAttempts3 += Number(attempt3);
												markForlaterCount += Number(marked_for_later_count);
												incorrectAnswerCount += Number(incorrect_count);
											});
											return (
												resourcetype == 0 ?
													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-center text-rose-800">{constants.total}</Table.Summary.Cell>
															<Table.Summary.Cell index={1} className="font-bold text-base text-center"></Table.Summary.Cell>
															<Table.Summary.Cell index={2} className="font-bold text-base text-center">{totalAttempts1}</Table.Summary.Cell>
															<Table.Summary.Cell index={3} className="font-bold text-base text-center">{totalAttempts2}</Table.Summary.Cell>
															<Table.Summary.Cell index={4} className="font-bold text-base text-center">{totalAttempts3}</Table.Summary.Cell>
															<Table.Summary.Cell index={5} className="font-bold text-base text-center text-rose-800">{testcount}</Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>
													:
													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-center text-rose-800">{constants.total}</Table.Summary.Cell>
															<Table.Summary.Cell index={1} className="font-bold text-base text-center"></Table.Summary.Cell>
															<Table.Summary.Cell index={2} className="font-bold text-base text-center">{totalAttempts1}</Table.Summary.Cell>
															<Table.Summary.Cell index={3} className="font-bold text-base text-center">{totalAttempts2}</Table.Summary.Cell>
															<Table.Summary.Cell index={4} className="font-bold text-base text-center">{markForlaterCount}</Table.Summary.Cell>
															<Table.Summary.Cell index={5} className="font-bold text-base text-center">{incorrectAnswerCount}</Table.Summary.Cell>
															<Table.Summary.Cell index={6} className="font-bold text-base text-rose-800 text-center">{tutorialcount}</Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>
											)
										}}
										pagination={false}
									/>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default TotalResourcesTaken
